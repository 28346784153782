import React from 'react';
import './TabHeader.css';
import { isMobileOnly } from 'react-device-detect';

const TabHeader = (props) => {
    let classes = 'TabHeader';
    if (props.active) {
        classes = classes + ' active';
    }
    if (isMobileOnly) {
        classes = classes + ' Mobile';
    }
    let closeIcon = <span className='TabHeaderCloseIcon' onClick={props.onTabClose}>X</span>;
    if (!props.closeable) {
        closeIcon = <span className='TabHeaderCloseIconInvisible'></span>;
    }
    return (
        <li className={classes} tabid={props.tabID} onClick={props.onActiveTabChange} title={props.title}>
            <span className='TabHeaderTitle'>{props.title}</span>
            {closeIcon}
        </li>

    );
}

export default TabHeader;