import React from 'react';
import './ControlPane.css';

interface ControlPaneProps {
    align? : string;
    padTop? : boolean | string;
    padBottom? : boolean | string;
    flex? : string;
    className? : string;
    children? : any;
}

const ControlPane = React.memo((props : ControlPaneProps) => {
    let position = 'right';
    if (props.align) {
        position = props.align;
    }

    let topPadding = '0px';
    if (props.padTop === true) {
        topPadding = '10px';
    } else if (props.padTop) {
        topPadding = props.padTop
    }

    let bottomPadding = '0px';
    if (props.padBottom === true) {
        bottomPadding = '10px';
    } else if (props.padBottom) {
        bottomPadding = props.padBottom
    }

    let display = 'block';
    if (props.flex) {
        display = 'flex'
    }

    let classes = 'ControlPane';
    if (props.className) {
        classes = classes + ' ' + props.className;
    }
    return (
        <div className={classes} style={{textAlign: position, paddingTop: topPadding, paddingBottom: bottomPadding, display: display} as any}>
            {props.children}
        </div>
    );
})

export default ControlPane;