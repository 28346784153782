import React from 'react';
import { Dropdown, DropdownMenu, DropdownItem, Menu } from 'semantic-ui-react';

const QuickFillDropdown = React.memo((props) => {
    let items = [];
    const categoryCount = props.options ? props.options.length : 0;
    if (categoryCount === 1) {
        // If there's only one category, simply display all template items at the top level.
        if (props.options[0].items.length > 0) {
            items = props.options[0].items.map(val => {
                return (
                    <DropdownItem
                        key={val.templateID + '_' + val.index}
                        text={val.description}
                        onClick={props.onItemClick}
                        checkitem={props.checkitem}
                        associatedinputname={props.associatedinputname}
                        itemcontent={val.content} 
                        title={val.content} />
                )
            });
        } else {
            items = (
                <DropdownItem
                    text='No template found'
                />
            )
        }
    } else if (categoryCount > 1) {
        items = props.options.map(category => {
            let categoryItems = [];
            if (category.items.length > 0) {
                categoryItems = category.items.map(categoryItem => {
                    return (
                        <DropdownItem
                            key={category.categoryId + '_' + categoryItem.index}
                            text={categoryItem.description}
                            onClick={props.onItemClick}
                            checkitem={props.checkitem}
                            associatedinputname={props.associatedinputname}
                            itemcontent={categoryItem.content} 
                            title={categoryItem.content} />
                    )
                });
            } else {
                categoryItems = (
                    <DropdownItem
                        text='No template found'
                    />
                );
            }

            return (
                <Dropdown text={category.name} pointing='left' scrolling as={Menu.Item} key={category.categoryId}>
                    <DropdownMenu>
                        {categoryItems}
                    </DropdownMenu>
                </Dropdown>
            );
        });
    } else {
        items = (
            <DropdownItem
                text='No template found'
            />
        )
    }

    return (
        <Dropdown text={props.label} as='label' scrolling={categoryCount === 1 ? true : false}>
            <DropdownMenu as='menu'>
                {items}
            </DropdownMenu>
        </Dropdown>
    );
})

export default QuickFillDropdown;