import React from 'react';
import { Modal, Button } from 'semantic-ui-react';
import FocusLock from 'react-focus-lock';

const ConfrimDialog = (props) => {
    const { header, content, onCancel, onConfirm, defaultOk, ...rest } = props;
    return (
        <Modal {...rest}>
            {
                header ? <Modal.Header>{header}</Modal.Header> : null
            }
            <Modal.Content>
                {content}
            </Modal.Content>
            <Modal.Actions>
                <FocusLock>
                    {
                        defaultOk ?
                            <React.Fragment>
                                <Button onClick={onCancel}>Cancel</Button>
                                <Button data-autofocus primary onClick={onConfirm}>OK</Button>
                            </React.Fragment>
                            :
                            <React.Fragment>
                                <Button data-autofocus onClick={onCancel}>Cancel</Button>
                                <Button primary onClick={onConfirm}>OK</Button>
                            </React.Fragment>
                    }
                </FocusLock>
            </Modal.Actions>
        </Modal>
    )
}

export default ConfrimDialog;