import React, { useEffect, useState } from 'react';
import { Header, Button, Grid } from 'semantic-ui-react';
import EnoteAxios from '../../../../axios';
import { AppointmentDto } from '../../../../assets/dto';
import { GetPatientFullName, FormatDate, FormatTime } from '../../../../assets/helper';
import moment from 'moment';
import ControlPane from '../../../../UI/Controls/Control/ControlPane';
import './AppointmentViewer.scss';
import CustomLoader from '../../../../UI/CustomLoader/CustomLoader';
import ConfrimDialog from '../../../../UI/ConfrimDialog/ConfirmDialog';
import FocusLock from 'react-focus-lock';
import { AppointmentReminderConfigMessage } from '../AppointmentReminderConfigMessage/AppointmentReminderConfigMessage';

interface AppointmentViewerProps {
    appointmentId: number;
    onEdit: (e: React.MouseEvent<HTMLElement>, appointmentId: number) => void;
    onCopy: (e: React.MouseEvent<HTMLElement>, appointmentId: number, startDateTime: Date, stopDateTime: Date) => void;
    onViewPatient: (e: React.MouseEvent<HTMLElement>, { patientId, patientFirstName, patientLastName }: any) => void;
    onDelete: (e: React.MouseEvent<HTMLElement>, appointmentId: number) => void;
    onClose: () => void;
}

const AppointmentViewer = (props: AppointmentViewerProps) => {
    const [appointment, setAppointment] = useState<AppointmentDto>();
    const [loading, setLoading] = useState(false);
    const [hasError, setHasError] = useState(false);
    const [showDeleteWarning, setShowDeleteWarning] = useState(false);

    useEffect(() => {
        async function loadAppointment() {
            setLoading(true);
            await EnoteAxios.get('/api/Appointment/GetAppointment', {
                params: {
                    appointmentId: props.appointmentId
                }
            }).then((response: any) => {
                if (response.data) {
                    setAppointment(response.data);
                }
            }).catch((err: any) => {
                setHasError(true);
            }).then(() => {
                setLoading(false);
            })
        }

        loadAppointment();
    }, [props.appointmentId]);

    async function deleteEventClickHandler(e: any, appointmentId: number) {
        e.preventDefault();
        if (appointmentId > 0) {
            if (props.onDelete)
                props.onDelete(e, appointmentId)
        }
    }

    let content = null;
    if (loading) {
        content = (
            <CustomLoader active={loading} cssLoader />
        )
    } else if (hasError) {
        content = (
            <p>An unexpected error occurred. Please try again.</p>
        )
    } else if (appointment) {
        let isSpanOnMultipleDays = !moment(appointment.startDateTime).isSame(appointment.stopDateTime, 'date');
        let appintmentDate = !isSpanOnMultipleDays ? FormatDate(appointment.startDateTime) : FormatDate(appointment.startDateTime) + ' - ' + FormatDate(appointment.stopDateTime);
        let appointmentTime = !isSpanOnMultipleDays ? FormatTime(appointment.startDateTime) + ' - ' + FormatTime(appointment.stopDateTime) : FormatTime(appointment.startDateTime) + ' - ' + FormatTime(appointment.stopDateTime) + ' (New Day)'
        content = (
            <React.Fragment>
                <Grid>
                    <Grid.Column computer={8} mobile={16} tablet={8}>
                        <Header size='medium'>Date</Header>
                        <div className='AppointmentDate'>{appintmentDate}</div>
                    </Grid.Column>
                    <Grid.Column computer={8} mobile={16} tablet={8}>
                        <Header size='medium'>Time</Header>
                        <div className='AppointmentTime'>{appointmentTime}</div>
                    </Grid.Column>
                    <Grid.Column computer={8} mobile={16} tablet={8}>
                        <Header size='medium'>Name</Header>
                        <div>{GetPatientFullName(appointment.patientFirstName, appointment.patientLastName)}</div>
                    </Grid.Column>
                    <Grid.Column computer={8} mobile={16} tablet={8}>
                        <Header size='medium'>Date of birth</Header>
                        <div>{FormatDate(appointment.patientDob)}</div>
                    </Grid.Column>
                    <Grid.Column computer={8} mobile={16} tablet={8}>
                        <Header size='medium'>Mobile</Header>
                        <div><a href={'tel:' + appointment.patientPhone}>{appointment.patientPhone}</a></div>
                    </Grid.Column>
                    <Grid.Column computer={8} mobile={16} tablet={8}>
                        <Header size='medium'>Email</Header>
                        <div><a href={'mailto:' + appointment.patientEmail}>{appointment.patientEmail}</a></div>
                    </Grid.Column>
                    <Grid.Column width={16}>
                        <Header className='AppointmentCommentHeader' size='medium'>Comment</Header>
                        <div>{appointment.comment}</div>
                    </Grid.Column>
                    <AppointmentReminderConfigMessage />
                </Grid>
            </React.Fragment>
        )
    }

    return (
        <div className='AppointmentViewer'>
            {content}
            {
                loading ? null : (
                    <ControlPane padTop='20px'>
                        <FocusLock>
                            {
                                appointment ? <Button className='DeleteAppointmentButton' color='red' onClick={(e: any) => setShowDeleteWarning(true)}>Delete</Button> : null
                            }
                            <Button data-autofocus onClick={props.onClose}>Close</Button>
                            {
                                appointment ? <Button primary onClick={(e: any) => props.onViewPatient(e, {
                                    patientId: appointment.patientId,
                                    patientFirstName: appointment.patientFirstName,
                                    patientLastName: appointment.patientLastName
                                })}>Patient Notes</Button> : null
                            }
                            {
                                appointment ? <Button primary onClick={(e: any) => props.onCopy(e, props.appointmentId, appointment.startDateTime, appointment.stopDateTime)}>Copy</Button> : null
                            }
                            {
                                appointment ? <Button primary onClick={(e: any) => props.onEdit(e, props.appointmentId)}>Edit</Button> : null
                            }
                        </FocusLock>
                    </ControlPane>
                )
            }
            <ConfrimDialog defaultOk content='Are you sure you want to delete this appointment?' open={showDeleteWarning} onConfirm={(e: any) => deleteEventClickHandler(e, props.appointmentId)} onCancel={() => setShowDeleteWarning(false)} />
        </div>
    )
}

export default AppointmentViewer;