import React from 'react';
import { Loader } from 'semantic-ui-react';
import './CustomLoader.css';

const CustomLoader = (props) => {
    let content = 'Loading...';
    if (props.content) {
        content = props.content
    }

    let loader = <Loader {...props} content={content} size='massive' className='CustomLoader' />

    if (props.cssLoader) {
        if (props.active) {
            loader = <div className={props.fullScreen ? 'lds-dual-ring fullScreen' : 'lds-dual-ring'}></div>
        }
        else {
            loader = null;
        }
    }

    return (
        <React.Fragment>
            {loader}
        </React.Fragment>
    );
}



export default CustomLoader;