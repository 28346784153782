import React from 'react';
import { Dropdown, Button } from 'semantic-ui-react';

const OptionsDropdown = (props) => (
    <Dropdown title='More Options' as={Button} primary icon='chevron down' direction='left'>
        <Dropdown.Menu>
            {props.children}
        </Dropdown.Menu>
    </Dropdown>
);

export default OptionsDropdown;

