export type AppointmentDto = {
    appointmentId : number;
    createdUserId : number;
    patientId : number;
    patientTitle : string;
    patientFirstName : string;
    patientLastName : string;
    patientDob : Date;
    patientEmail : string;
    patientPhone: string;
    startDateTime : Date;
    stopDateTime : Date;
    comment : string
}

export class DailyPatientInvoiceSummary {
    constructor(date: string, id: number, firstName: String, lastName : String, amount: number, injuries: number[]) {
        this.date = date;
        this.patientId = id;
        this.patientFirstName = firstName;
        this.patientLastName = lastName;
        this.invoicedAmount = amount;
        this.injuries = injuries;
    }
    date: string;
    patientId: number;
    patientFirstName: String;
    patientLastName: String;
    invoicedAmount: number;
    injuries: number[];
}