import React, { useState } from 'react';
import { Form, Header, Button, Statistic, Modal } from 'semantic-ui-react';
import { DatesRangeInput } from 'semantic-ui-calendar-react-yz';
import { FormatDate, GetStartStopDatesFromRangeString, GetInjuryTabID, GetPatientFullName, GetPatientFullNameShort } from '../../../../../assets/helper';
import CustomReactTable, { XOverflowMode } from '../../../../../UI/CustomReactTable/CustomReactTable';
import EnoteAxios from '../../../../../axios';
import './IncomeSummary.scss';
import PatientPane from '../../../PatientPane/PatientPane';
import { DailyPatientInvoiceSummary } from '../../../../../assets/dto';
import ConfrimDialog from '../../../../../UI/ConfrimDialog/ConfirmDialog';
import { useRouteMatch } from 'react-router';
import currency from 'currency.js';
import { ColumnDef, createColumnHelper, Row } from '@tanstack/react-table';

const columnHelper = createColumnHelper<DailyPatientInvoiceSummary>();
const columns: ColumnDef<DailyPatientInvoiceSummary, any>[] = [
    columnHelper.accessor(r => FormatDate(r.date), {
        id: 'date',
        header: 'Date Submitted',
        enableGrouping: true,
        cell: () => '',
    }),
    columnHelper.accessor(r => GetPatientFullName(r.patientFirstName), {
        id: 'name',
        header: 'Patient',
        aggregationFn: 'count',
    }),
    columnHelper.accessor(r => r.invoicedAmount, {
        id: 'invoicedAmount',
        header: 'Invoiced Amount ($)',
        aggregationFn: 'sum',
        cell: info => currency(info.getValue(), { symbol: '' }).toString(),
        aggregatedCell: info => currency(info.getValue(), { symbol: '' }).toString(),
    })
];

function IncomeSummary(props: any) {
    const match = useRouteMatch();
    const [dateRangeString, setDateRangeString] = useState('');
    const [startDate, setStartDate] = useState<string | null>(null)
    const [stopDate, setStopDate] = useState<string | null>(null)
    const [loading, setLoading] = useState(false);
    const [incomeDetails, setIncomeDetails] = useState(new Array<DailyPatientInvoiceSummary>());
    const [totalAmount, setTotalAmount] = useState(0);

    const [selectedPatientInvoiceSummary, setSelectedPatientInvoiceSummary] = useState<DailyPatientInvoiceSummary>();
    const [showInjuryModal, setShowInjuryModal] = useState(false);
    const [unsavedChange, setUnsavedChange] = useState(false);
    const [showUnsavedChangeWarning, setShowUnsavedChangeWarning] = useState(false);

    async function runReportHandler() {
        const [start, end] = GetStartStopDatesFromRangeString(dateRangeString);

        if (start) {
            setLoading(true);
            setStartDate(start);
            setStopDate(end);
            await EnoteAxios.get('/api/report/GetDailyIncomeSummary', {
                params: {
                    startDate: start,
                    stopDate: end,
                    groupByDate: false
                }
            }).then((response: any) => {
                let total = 0;
                if (response.data) {
                    response.data.forEach((d: DailyPatientInvoiceSummary) => {
                        total += d.invoicedAmount
                    })
                }
                setTotalAmount(total);
                setIncomeDetails(response.data);
            }).catch(() => {
                setTotalAmount(0);
                setIncomeDetails(new Array<DailyPatientInvoiceSummary>());
            }).then(() => {
                setLoading(false);
            })
        }
    }

    function onPatientRowClick(e: any, rowInfo: Row<DailyPatientInvoiceSummary>) {
        if (!rowInfo.getIsGrouped()) {
            let selectRecord = new DailyPatientInvoiceSummary(rowInfo.original.date, rowInfo.original.patientId, rowInfo.original.patientFirstName, rowInfo.original.patientLastName, rowInfo.original.invoicedAmount, rowInfo.original.injuries);
            setSelectedPatientInvoiceSummary(selectRecord);
            setShowInjuryModal(true);
        }
    }

    function modalCloseHandler(confirmed: boolean) {
        if (unsavedChange && !confirmed) {
            setShowUnsavedChangeWarning(true);
        } else {
            setShowInjuryModal(false);
            setUnsavedChange(false);
            setShowUnsavedChangeWarning(false);
        }

    }

    let modalContent = null;
    if (showInjuryModal && selectedPatientInvoiceSummary) {
        modalContent = (
            <PatientPane
                tabs={selectedPatientInvoiceSummary.injuries.map((i: number) => {
                    return {
                        tabID: GetInjuryTabID(selectedPatientInvoiceSummary.patientId, i),
                        tabTitle: GetPatientFullNameShort(selectedPatientInvoiceSummary.patientFirstName, selectedPatientInvoiceSummary.patientLastName) + ' Injury: ' + FormatDate(selectedPatientInvoiceSummary.date),
                        componentClass: 'InjuryDetail',
                        data: {
                            injuryId: i,
                            patient: {
                                patientId: selectedPatientInvoiceSummary.patientId,
                                firstName: selectedPatientInvoiceSummary.patientFirstName,
                                lastName: selectedPatientInvoiceSummary.patientLastName,
                            }
                        }
                    }
                })}
                instanceMode
                onNoTab={() => setShowInjuryModal(false)}
                inModal={true}
                hasUnsavedChange={(unsavedChange: boolean) => setUnsavedChange(unsavedChange)}
            />
        )
    }

    return (
        <div className={'IncomeSummary ' + (match ? '' : 'css-hidden')}>
            <Header>Submitted Date Range</Header>
            <Form>
                <DatesRangeInput
                    className="InlineDateInput"
                    closable
                    dateFormat='YYYY-MM-DD'
                    onChange={(e, { value }) => setDateRangeString(value)}
                    value={dateRangeString}
                    placeholder='From - To'
                    hideMobileKeyboard
                    animation={null}
                    popupPosition={'bottom left'}
                />
                <Button primary onClick={runReportHandler} disabled={!dateRangeString || loading} loading={loading}>Run Report</Button>
            </Form>
            <Header dividing>Results</Header>
            <Statistic size='tiny' className='TotalAmountStat'>
                <Statistic.Value>$ {(+totalAmount.toFixed(2)).toLocaleString('en')}</Statistic.Value>
                <Statistic.Label>
                    {
                        startDate && stopDate ? 'Total invoiced amount between ' + startDate + ' and ' + stopDate : 'Total invoiced amount'
                    }
                </Statistic.Label>
            </Statistic>
            <CustomReactTable
                columns={columns}
                data={incomeDetails}
                onRowClick={onPatientRowClick}
                noDataText='No data available'
                loading={loading}
                clickable
                xOverflowMode={XOverflowMode.Scroll}
                stickyHeader
                pagination
                defaultPageSize={15}
                showPageSizeOptions
            />
            <Modal open={showInjuryModal} size='large' closeIcon onClose={() => modalCloseHandler(false)}>
                <Modal.Content scrolling>
                    <div className='ModalContent'>
                        {modalContent}
                    </div>
                </Modal.Content>
            </Modal>
            <ConfrimDialog
                content='Are you sure you want to close the page? Any unsaved change will be lost.'
                open={showUnsavedChangeWarning}
                onConfirm={() => modalCloseHandler(true)}
                onCancel={() => setShowUnsavedChangeWarning(false)} />
        </div>
    )
}

export default IncomeSummary;