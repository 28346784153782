import React from 'react';
import { Header } from "semantic-ui-react";
import './FormHeader.css';

const FormHeader = React.memo((props) => {
    let trimTopClass = '';
    if (props.trimtop) {
        trimTopClass = 'TrimTop'
    }

    const {trimtop, ...newProps} = props;
    return (
        <Header {...newProps} className={trimTopClass}>
            {props.children}
        </Header>
    )
})

export default FormHeader;