import React, { createRef } from 'react';
import { Input, Button, Form, ButtonGroup } from 'semantic-ui-react';
import { connect } from 'react-redux';
import ControlPane from '../../../../UI/Controls/Control/ControlPane';
import EnoteAxios from '../../../../axios';
import { FormatDate, GetPatientTabID, GetPatientFullNameShort } from '../../../../assets/helper';
import CustomReactTable, { XOverflowMode } from '../../../../UI/CustomReactTable/CustomReactTable';
import './SearchPatient.css';
import { createColumnHelper } from '@tanstack/react-table';

const SEARCH_PATTERN = 'searchPattern';
const SEARCH_CRITERIA = 'searchCriteria';
const SELECTED_CRITERIA = 'selectedCriterion';

const SEARCH_NO_RESULT = 'No matching patients found';

const columnHelper = createColumnHelper();

const patientColumns = [
    columnHelper.accessor(row => row.firstName, {
        header: 'First Name',
    }),
    columnHelper.accessor(row => row.lastName, {
        header: 'Last Name'
    }),
    columnHelper.accessor(row => row.gender, {
        header: 'Gender'
    }),
    columnHelper.accessor(row => FormatDate(row.dob), {
        header: 'Date of Birth',
    }),
    columnHelper.accessor(row => FormatDate(row.firstVisitDate), {
        header: 'First Visit Date',
    }),
    columnHelper.accessor(row => row.homePhone, {
        header: 'Home Phone',
        cell: props => <a className='tel-number' href={'tel:' + props.getValue()}>{props.getValue()}</a>
    }),
    columnHelper.accessor(row => row.mobilePhone, {
        header: 'Mobile Phone',
        cell: props => <a className='tel-number' href={'tel:' + props.getValue()}>{props.getValue()}</a>
    }),
    columnHelper.accessor(row => row.email, {
        header: 'Email',
        cell: props => <a className='email-address' href={'mailto:' + props.getValue()}>{props.getValue()}</a>
    }),
];

class SearchPatient extends React.Component {
    searchInputRef = createRef();

    state = {
        [SEARCH_PATTERN]: '',
        [SELECTED_CRITERIA]: 0,
        patientList: [],
        loading: false,
    }

    async componentDidMount() {
        await this.getPatientList('', 0);
    }

    searchInputChangeHanlder = (e, { name, value }) => {
        if (name === SEARCH_CRITERIA) {
            this.setState({
                [SELECTED_CRITERIA]: value
            })
        }
        else {
            this.setState({
                [SEARCH_PATTERN]: value
            })
        }
    }

    searchPatientClickHanlder = async (e) => {
        e.preventDefault();
        await this.getPatientList(this.state.searchPattern, this.state.selectedCriterion);
    }

    searchPatientResetHanlder = async (e) => {
        e.preventDefault();

        this.setState({
            searchPattern: ''
        });

        await this.getPatientList('', this.state.selectedCriterion);
        this.searchInputRef.current.focus();
    }

    getPatientList = async (pattern, criterion) => {
        this.setState({
            loading: true
        });

        await EnoteAxios.get('/api/patient/GetPatientList', {
            params: {
                keyword: pattern,
                searchType: criterion
            }
        }).then(response => {
            if (response.data) {
                this.setState({
                    patientList: response.data,
                });
            }
        }).catch(err => {
            console.log(err);
        }).then(() => {
            this.setState({
                loading: false
            });
        })
    }

    onRowClick = (e, rowInfo) => {
        let classes = e.target.classList;
        if (classes.contains('tel-number') || classes.contains('email-address')) {
            return;
        }
        const patient = rowInfo.original;
        this.props.onTabAdd(GetPatientTabID(patient.patientId), GetPatientFullNameShort(patient.firstName, patient.lastName), 'PatientDetail', {
            patientId: patient.patientId
        });
    }

    render() {

        return (
            <div className='SearchPatient'>
                <ControlPane>
                    <Button primary onClick={() => this.props.onTabAdd(GetPatientTabID(0), 'New Patient', 'PatientDetail', { patientId: '' })}>New Patient</Button>
                </ControlPane>
                <div className='SearchArea'>
                    <Form autoComplete='off'>
                        <Input
                            className='SearchInput'
                            name={SEARCH_PATTERN}
                            placeholder='Search patients by name, phone number, ACC, or NHI number'
                            onChange={this.searchInputChangeHanlder}
                            value={this.state.searchPattern}
                            ref={this.searchInputRef}
                            action={
                                <ButtonGroup>
                                    <Button type='submit' primary onClick={this.searchPatientClickHanlder} loading={this.state.loading} disabled={this.state.loading}>Search</Button>
                                    <Button onClick={this.searchPatientResetHanlder} disabled={this.state.loading || !this.state.searchPattern}>Clear</Button>
                                </ButtonGroup>
                            }
                        />
                    </Form>
                </div>
                <div className='SearchPatientResult'>
                    <CustomReactTable
                        columns={patientColumns}
                        data={this.state.patientList}
                        onRowClick={this.onRowClick}
                        clickable
                        pageSizeOptions={[5, 10, 15, 20, 25, 50, 100]}
                        pagination
                        showPageSizeOptions
                        defaultSorting={[ { id: 'First Name' }]}
                        loading={this.state.loading}
                        noDataText={SEARCH_NO_RESULT}
                        stickyHeader
                        xOverflowMode={XOverflowMode.Scroll}
                        fixedStyle={false}
                    />
                </div>
            </div>
        )

    }
}

const mapStateToProps = state => {
    return {
        user: state.user,
        token: state.token
    }
}

export default connect(mapStateToProps)(SearchPatient);