import axios from 'axios';
import { NotificationManager } from 'react-notifications';

const EnoteAxios = axios.create();

EnoteAxios.interceptors.request.use(request => {
    let token = sessionStorage.getItem('enote-token');;
    request.headers["Authorization"] = "bearer " + token;

    return request;
}, error => {
    console.log(error);
});

EnoteAxios.interceptors.response.use((response) => {
    if (response && response.data && response.data.message) {
        NotificationManager.success(response.data.message, 'Success');
    }
    return response;
}, error => {
    try {
        if (error && error.response) {
            if (error.response.status === 401) {
                localStorage.removeItem('enote-token');
                sessionStorage.removeItem('enote-token');
                if (window.location.pathname === '/login') {
                    NotificationManager.error('Your session has expired. Please login again.', 'Session Expired');
                } else {
                    NotificationManager.error('Redirecting to Login Page in 5 seconds...', 'Session Expired');
                    setTimeout(() => {
                        window.location.reload(true);
                    }, 5000);
                    return;
                }
            }
            
            if (error.response && error.response.data && !error.response.data.confirmation && (typeof error.response.data === 'string')) {
                NotificationManager.error(error.response.data, 'Failed');
            }
        } else if (!error || !error.status) {
            NotificationManager.error("Failed to communicate with the server. Please try again later.", 'Network Failure');
        }
    } catch (uncaughtErr) {
        NotificationManager.error('An unexpcted error occurred', 'Failed');
    }

    return Promise.reject(error);
});

export default EnoteAxios;
