import React, { useCallback, useEffect, useMemo, useState } from 'react';
import TabGroup from '../../../UI/TabGroup/TabGroup';
import SearchPatient from './SearchPatient/SearchPatient';
import './PatientPane.css';
import TabHeaderSection from '../../../UI/TabGroup/TabHeaderSection/TabHeaderSection';
import TabContentSection from '../../../UI/TabGroup/TabContentSection/TabContentSection';
import TabHeader from '../../../UI/TabGroup/TabHeaderSection/TabHeader/TabHeader';
import TabContent from '../../../UI/TabGroup/TabContentSection/TabContent/TabContent';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import PatientDetail from './PatientDetail/PatientDetail';
import InjuryDetail from './InjuryDetail/InjuryDetail';
import TreatmentDetail from './TreatmentDetail/TreatmentDetail';
import ConfrimDialog from '../../../UI/ConfrimDialog/ConfirmDialog';
import { v4 as uuid } from 'uuid';
import cn from 'classnames';

// Helper Method that iterates through all tabs and change their active props accordingly
const changeActiveTab = (currTabs, newActiveTabID) => {
    let tabs = currTabs.slice();

    let activeTabSet = false;
    for (let i = 0; i < tabs.length; i++) {
        if (tabs[i].tabID === newActiveTabID) {
            tabs[i].active = true;
            activeTabSet = true;
        }
        else {
            tabs[i].active = false;
        }
    }

    if (!activeTabSet && tabs.length > 0) {
        tabs[0].active = true;
    }

    return tabs;
}

const getComponentByString = (text) => {
    switch (text) {
        case 'PatientDetail':
            return PatientDetail;
        case 'InjuryDetail':
            return InjuryDetail;
        case 'TreatmentDetail':
            return TreatmentDetail;
        case 'SearchPatient':
            return SearchPatient;
        default:
            return null;
    }
}

const createTabObject = (tabID, tabTitle, componentClass, closeable, data) => {
    return {
        key: uuid(),
        tabID: tabID,
        title: tabTitle,
        component: componentClass,
        data: data,
        reloadTimestamp: 0,
        closeable: closeable === undefined ? true : closeable,
        touched: false
    };
}

const buildTabs = (tabsFromState, tabsFromProps, tabsFromRoute, newTabs) => {
    let consolidatedTabs = tabsFromState.slice();

    let additionalTabs = [];
    let lastAddedTab = null;
    if (newTabs?.length > 0) {
        additionalTabs = newTabs;
    }
    if (tabsFromProps?.length > 0) {
        additionalTabs = additionalTabs.concat(tabsFromProps);
    }
    if (tabsFromRoute?.length > 0) {
        // tabs provided via location props, (<Redirect>), need to create appropriate component class based on the string (as class cannot be passed from location prop directly)
        additionalTabs = additionalTabs.concat(tabsFromRoute);
    }
    if (additionalTabs && additionalTabs.length > 0) {
        additionalTabs.forEach(t => {
            if (!consolidatedTabs.find(x => x.tabID === t.tabID)) {
                consolidatedTabs.push(createTabObject(t.tabID, t.tabTitle, t.componentClass, t.closeable, t.data));
                lastAddedTab = t;
            }
        });

        if (lastAddedTab) {
            consolidatedTabs = changeActiveTab(consolidatedTabs, lastAddedTab.tabID);
        } else if (tabsFromRoute?.length > 0) {
            consolidatedTabs = changeActiveTab(consolidatedTabs, tabsFromRoute[tabsFromRoute.length - 1].tabID);
        }
    }

    if (consolidatedTabs.length > 0 && !consolidatedTabs.find(x => x.active)) {
        consolidatedTabs[0].active = true;
    }

    return consolidatedTabs;
}

const getTabsFromRoute = (location) => (
    location && location.state && location.state.tabs ? location.state.tabs : []
);

const PatientPane = (props) => {
    const [tabs, setTabs] = useState([]);

    const [closingTabId, setClosingTabId] = useState('');
    const [showUnsavedChangeConfirm, setShowUnsavedChangeConfirm] = useState(false);
    const location = useLocation();
    const match = useRouteMatch();
    const history = useHistory();

    const [tabsFromPropsPopulated, setTabsFromPropsPopulated] = useState(false);

    const {
        tabs: tabsFromProps,
        instanceMode,
        inModal,
        onNoTab,
        hasUnsavedChange,
    } = props;


    useEffect(() => {
        let defaultTabs = [];
        if (!instanceMode && !tabs.find(x => x.component === 'SearchPatient')) {
            defaultTabs.push({ tabID: '1', tabTitle: 'Search', componentClass: 'SearchPatient', closeable: false })
        }

        let newTabs = buildTabs(tabs, tabsFromPropsPopulated ? [] : tabsFromProps, getTabsFromRoute(location), defaultTabs);
        if (location?.state?.tabs) {
            history.replace();
        }
        if (tabs.length !== newTabs.length) {
            setTabsFromPropsPopulated(true);
            setTabs(newTabs);
        }
    }, [tabs, tabsFromProps, location, instanceMode, history, tabsFromPropsPopulated]);


    const closeTab = useCallback((tabID) => {
        if (!tabID) {
            tabID = closingTabId;
        }

        // find the current active tab and its index
        const currTabs = tabs.slice();
        let currActiveTab = null;
        let currActiveIndex = 0;
        for (let i = 0; i < currTabs.length; i++) {
            if (currTabs[i].active) {
                currActiveTab = currTabs[i];
                currActiveIndex = i;
                break;
            }
        }
        const newTabs = currTabs.filter(tab => tab.tabID !== tabID);

        if (newTabs.length === 0 && onNoTab) {
            onNoTab();
            return;
        }
        
        if (getTabsFromRoute(location).find(x => x.tabID === tabID)) {
            history.replace();
        }

        if (currActiveTab && currActiveTab.tabID === tabID && newTabs.length > 0) {
            // if we are closing the active tab, make sure to make the following tab active (make the previous tab active if the active one is the last one)
            let nextActiveIndex = currActiveIndex + 1 === currTabs.length ? currActiveIndex - 1 : currActiveIndex;
            newTabs[nextActiveIndex].active = true;
        }

        setClosingTabId('');
        setTabs(newTabs);
        setShowUnsavedChangeConfirm(false);

        if (hasUnsavedChange) {
            hasUnsavedChange(newTabs.filter(x => x.touched).length > 0);
        }
    }, [tabs, closingTabId, onNoTab, hasUnsavedChange, setClosingTabId, setTabs, setShowUnsavedChangeConfirm, history, location]);

    const tabCloseHandler = useCallback((e, tabID) => {
        // prevent onActiveChange event being triggered
        if (e)
            e.stopPropagation();
        const currTabs = tabs.slice();
        const tabToClose = currTabs.filter(tab => tab.tabID === tabID);
        if (tabToClose && tabToClose.length === 1 && tabToClose[0].closeable) {
            if (tabToClose[0].touched && e) {
                // if e is undefined, it means a save&close is triggered, so do not check for unsaved changes
                setClosingTabId(tabID);
                setShowUnsavedChangeConfirm(true);
            } else {
                closeTab(tabID)
            }
        }
    }, [tabs, setClosingTabId, setShowUnsavedChangeConfirm, closeTab])

    const closeTabCancelHandler = () => {
        setClosingTabId('');
        setShowUnsavedChangeConfirm(false);
    }

    const activeTabChangeHandler = (e, newActiveTabID) => {
        const currentTabs = tabs.slice();
        const newTabs = changeActiveTab(currentTabs, newActiveTabID);
        setTabs(newTabs);
    }

    const tabHeaderList = tabs.map(tab => {
        return (
            <TabHeader
                key={tab.key}
                tabID={tab.tabID}
                title={tab.title}
                active={tab.active}
                closeable={tab.closeable}
                touched={tab.touched}
                onActiveTabChange={(e) => activeTabChangeHandler(e, tab.tabID)}
                onTabClose={(e) => tabCloseHandler(e, tab.tabID)} />
        );
    });

    const tabContentList = useMemo(() => {
        const tabAddHandler = (tabID, tabTitle, componentClass, data) => {
            const currentTabs = tabs.slice();
            let tabOpenedAlready = currentTabs.find((v) => v.tabID === tabID) !== undefined;

            let newTabs = [];
            if (!tabOpenedAlready) {
                newTabs.push({ tabID, tabTitle, componentClass, data })
            }
            const consolidatedTabs = buildTabs(tabs, tabsFromPropsPopulated ? [] : tabsFromProps, getTabsFromRoute(location), newTabs);
            setTabs(consolidatedTabs);
        }

        const tabMetadataChangeHandler = (oldTabID, newTabID, newTitle, updateReferenceID, data, childTabsDataOverride) => {
            console.log('Tab metadata changed', {
                oldTabID,
                newTabID,
                newTitle,
                updateReferenceID,
                data,
                childTabsDataOverride
            });

            const currentTabs = tabs.slice();
            for (let i = 0; i < currentTabs.length; i++) {
                if (currentTabs[i].tabID === oldTabID) {
                    currentTabs[i].tabID = newTabID;
                    currentTabs[i].title = newTitle;
                    currentTabs[i].data = data;
                    currentTabs[i].reloadTimestamp = currentTabs[i].reloadTimestamp + 1;
                    currentTabs[i].touched = false;
                } else if (currentTabs[i].tabID.indexOf(updateReferenceID) > -1) {
                    currentTabs[i].reloadTimestamp = currentTabs[i].reloadTimestamp + 1;
                }

                if (childTabsDataOverride && currentTabs[i].tabID.indexOf(newTabID) > -1) {
                    currentTabs[i].data = {
                        ...currentTabs[i].data,
                        ...childTabsDataOverride,
                    }
                }
            }

            setTabs(currentTabs);

            if (hasUnsavedChange) {
                hasUnsavedChange(currentTabs.filter(x => x.touched).length > 0);
            }
        }

        const tabContentTouchedHandler = (e, tabID) => {
            const currTabs = tabs.slice();
            let shouldUpdate = false;
            for (let i = 0; i < currTabs.length; i++) {
                if (currTabs[i].tabID === tabID && !currTabs[i].touched) {
                    shouldUpdate = true;
                    currTabs[i].touched = true;
                }
            }
            if (shouldUpdate) {
                setTabs(currTabs);
                if (hasUnsavedChange) {
                    hasUnsavedChange(true);
                }
            }
        }

        return tabs.map(tab => (
            tab.component &&
            <TabContent
                key={tab.tabID}
                tabID={tab.tabID}
                active={tab.active}>
                {React.createElement(getComponentByString(tab.component), {
                    onTabAdd: tabAddHandler,
                    onTabMetadataChange: tabMetadataChangeHandler,
                    onTabClose: (e) => tabCloseHandler(e, tab.tabID),
                    touched: tab.touched,
                    onTouch: (e) => tabContentTouchedHandler(e, tab.tabID),
                    tabID: tab.tabID,
                    reloadTimestamp: tab.reloadTimestamp,
                    data: tab.data,
                    inModal: inModal
                })}
            </TabContent>
        ));
    }, [tabs, hasUnsavedChange, tabCloseHandler, inModal, location, tabsFromProps, tabsFromPropsPopulated]);


    return (
        <div className={cn({
            'PatientPane ContentMainPane': true,
            'css-hidden': match === null
        })}>
            <TabGroup>
                <TabHeaderSection>
                    {tabHeaderList}
                </TabHeaderSection>
                <TabContentSection>
                    {tabContentList}
                </TabContentSection>
            </TabGroup>

            <ConfrimDialog
                content='Are you sure you want to close the page? Any unsaved change will be lost.'
                open={showUnsavedChangeConfirm}
                onConfirm={() => closeTab()}
                onCancel={closeTabCancelHandler} />
        </div>
    )
}

export default PatientPane;