import React, { useState } from 'react';
import { Modal, Accordion, Icon, List, AccordionTitleProps, Header } from 'semantic-ui-react';
import './HelpModal.css';

type HelpModalProps = {
    open: boolean,
    onClose: () => void
}

const HelpModal = React.memo((props: HelpModalProps) => {
    const [activeIndex, setActiveIndex] = useState(-1);

    const handleClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, data: AccordionTitleProps) => {
        if (data.index === activeIndex || data.index === undefined) {
            setActiveIndex(-1);
        } else {
            setActiveIndex(Number(data.index));
        }
    }

    return (
        <Modal open={props.open} closeIcon onClose={props.onClose}>
            <Modal.Header>Help</Modal.Header>
            <Modal.Content scrolling>
                <Accordion>
                    <Accordion.Title as={Header} index={0} active={activeIndex === 0} onClick={handleClick}><Icon name='dropdown' />How to invoice ACC on my device</Accordion.Title>
                    <Accordion.Content active={activeIndex === 0}>
                        <div className='FaqContent'>
                            <h4>Windows</h4>
                            <List bulleted>
                                <List.Item>Chrome, Opera, Edge</List.Item>
                                <p>No additional configuration required. A certificate selection dialog will appear when invoicing ACC, given the certificate is installed properly in your system</p>
                                <List.Item>Firefox</List.Item>
                                <p>The HealthLink certificate (.pfx file on your disk) needs to be imported into Firefox. Click <a href='https://knowledge.digicert.com/solution/SO5437.html' target='_blank' rel='noopener noreferrer'>here</a> to see instructions</p>
                                <List.Item>Internet Explorer</List.Item>
                                <p>Not supported</p>
                            </List>
                            <h4>Mac OS / Linux</h4>
                            <List bulleted>
                                <List.Item>Safari, Chrome, Opera</List.Item>
                                <p>Double click the .pfx file on your disk to install the certificate into Keychains. A certificate selection dialog will appear when invoicing ACC, given the certificate is installed properly in your system</p>
                                <List.Item>Firefox</List.Item>
                                <p>In addition to installing the .pfx file, the HealthLink certificate needs to be imported into Firefox. Click <a href='https://knowledge.digicert.com/solution/SO5437.html' target='_blank' rel='noopener noreferrer'>here</a> to see instructions</p>
                            </List>
                            <h4>iOS</h4>
                            Attach the pfx file in an email and send it to your iOS device. You can then install the certificate from the email
                            <h4>Android</h4>
                            Place the pfx file into your internal storage and install it from Settings.
                            <br />
                            <br />
                            <p>Please be aware that HealthLink does not claim their certificates work on mobile devices. Hence even though invoicing on mobile devices has been tested internally, there's no guarantee it will work on all devices.</p>
                        </div>
                    </Accordion.Content>

                    <Accordion.Title as={Header} index={1} active={activeIndex === 1} onClick={handleClick}><Icon name='dropdown' />I can't export injury information on iOS devices</Accordion.Title>
                    <Accordion.Content active={activeIndex === 1}>
                        <div className='FaqContent'>
                            <p>You will need to turn off "Block Pop-ups" from Settings - Safari</p>
                        </div>
                    </Accordion.Content>

                    <Accordion.Title as={Header} index={2} active={activeIndex === 2} onClick={handleClick}><Icon name='dropdown' />I can't log in automatically when I select "Remember me"</Accordion.Title>
                    <Accordion.Content active={activeIndex === 2}>
                        <div className='FaqContent'>
                            <List bulleted>
                                <List.Item>Do not sign out if you want to log in automatically the next time you access eNote portal. The sign out option will clear the saved credential.</List.Item>
                                <List.Item>Your saved credential will expire in 7 days since the last time you acess eNote portal. In this case, you will need to enter your account credential again.</List.Item>
                            </List>
                        </div>
                    </Accordion.Content>

                    <Accordion.Title as={Header} index={3} active={activeIndex === 3} onClick={handleClick}><Icon name='dropdown' />What's Template and how to use it?</Accordion.Title>
                    <Accordion.Content active={activeIndex === 3}>
                        <div className='FaqContent'>
                            <p>This can be found on Template Page.</p>
                        </div>
                    </Accordion.Content>

                    <Accordion.Title as={Header} index={4} active={activeIndex === 4} onClick={handleClick}><Icon name='dropdown' />How to create an appointment?</Accordion.Title>
                    <Accordion.Content active={activeIndex === 4}>
                        <div className='FaqContent'>
                            <p>Simply click on a time slot on the calendar, or use &lt;New Appointment&gt; button at the top</p>
                        </div>
                    </Accordion.Content>

                    <Accordion.Title as={Header} index={5} active={activeIndex === 5} onClick={handleClick}><Icon name='dropdown' />How to reschedule an appointment?</Accordion.Title>
                    <Accordion.Content active={activeIndex === 5}>
                        <div className='FaqContent'>
                            <List bulleted>
                                <List.Item>You can simply change the time of an appointment by dragging it to the desired time slot. Please note drag and drop is not currently supported on mobile devices.</List.Item>
                                <List.Item>You can also do it by clicking on the appointment and change its start/stop time.</List.Item>
                            </List>
                        </div>
                    </Accordion.Content>

                    <Accordion.Title as={Header} index={6} active={activeIndex === 6} onClick={handleClick}><Icon name='dropdown' />How to duplicate/copy an appointment?</Accordion.Title>
                    <Accordion.Content active={activeIndex === 6}>
                        <div className='FaqContent'>
                            <List ordered>
                                <List.Item>Click on the appointment you want to copy</List.Item>
                                <List.Item>Click &lt;Copy&gt; button at the bottom</List.Item>
                                <List.Item>You can now click (single click / touch) on a new time slot, and a new appointment will be created</List.Item>
                            </List>
                        </div>
                    </Accordion.Content>

                    <Accordion.Title as={Header} index={7} active={activeIndex === 7} onClick={handleClick}><Icon name='dropdown' />I can't create or paste an appointment by touching on a time slot on mobile devices</Accordion.Title>
                    <Accordion.Content active={activeIndex === 7}>
                        <div className='FaqContent'>
                            <p>You need to touch on a time slot and hold for half a second in order to create or paste an appointment on mobile devices. This is by design for mistouch prevention.</p>
                        </div>
                    </Accordion.Content>
                    <Accordion.Title as={Header} index={8} active={activeIndex === 8} onClick={handleClick}><Icon name='dropdown' />How to log into multiple accounts at the same time?</Accordion.Title>
                    <Accordion.Content active={activeIndex === 8}>
                        <div className='FaqContent'>
                            <p>If you need to use multiple accounts at the same time, please do not select "Remember Me" when logging into eNote Portal. If you wan to use multiple accounts while having the ability to login automatically, you will then need to use a different browser for each account you wish to use.</p>
                        </div>
                    </Accordion.Content>
                </Accordion>
                <p className='SupportEmail'>Support Email: <strong>service@enote.co.nz</strong></p>
            </Modal.Content>
        </Modal >
    );
});

export default HelpModal;