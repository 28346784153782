import { configureStore } from '@reduxjs/toolkit';
import moment from 'moment';
import reducer from './reducer';

export const initialState = {
    token: null,
    user: null,
    readCodesLoaded: false,
    templatesLoaded: false,
    templateItemsLoaded: false,
    availableReadCodes: [],
    templates: [],
    templateItems: {},
    appointmentView: {
        currentRefDate: moment().format('YYYY-MM-DD HH:mm:ss'),
        view: 'month'
    }
}
const store = configureStore({
    reducer,
    initialState,
})

export default store;