import React from 'react';
import { Modal, Button } from 'semantic-ui-react';
import ControlPane from '../../../../../../UI/Controls/Control/ControlPane';
import FocusLock from 'react-focus-lock';

export default function InvoiceHelper(props) {
    return (
        <Modal open={props.open} size='small'>
            <Modal.Header>Digital Certificate Setup</Modal.Header>
            <Modal.Content>
                <div>
                    <h2>Windows</h2>
                    <h4>Firefox</h4>
                    <p>The HealthLink certificate (.pfx file on your disk) needs to be imported into Firefox. Click <a href='https://knowledge.digicert.com/solution/SO5437.html' target='_blank' rel='noopener noreferrer'>here</a> to see instructions</p>
                    <h4>Chrome, Opera, Edge</h4>
                    <p>No additional configuration required. A certificate selection dialog will appear when invoicing ACC, given the certificate is installed properly in your system</p>
                    <h2>Mac OS</h2>
                    <h4>Safari, Chrome, Opera</h4>
                    <p>Double click the .pfx file on your disk to install the certificate into Keychains. A certificate selection dialog will appear when invoicing ACC, given the certificate is installed properly in your system</p>
                    <h4>Firefox</h4>
                    <p>In addition to installing the .pfx file, the HealthLink certificate needs to be imported into Firefox. Click <a href='https://knowledge.digicert.com/solution/SO5437.html' target='_blank' rel='noopener noreferrer'>here</a> to see instructions</p>
                </div>
                <ControlPane>

                </ControlPane>
            </Modal.Content>
            <Modal.Actions>
                <FocusLock>
                    <Button onClick={props.onClose}>Close</Button>
                </FocusLock>
            </Modal.Actions>
        </Modal>
    )
}