
import * as actionType from './actions';
import { initialState } from './store';

const Reducer = (state = initialState, action) => {
    switch (action.type) {
        case (actionType.UPDATE_USER):
            return {
                ...state,
                user: action.payload.user
            };
        case (actionType.UPDATE_USER_AND_TOKEN):
            return {
                ...state,
                token: action.payload.token,
                user: action.payload.user
            };
        case (actionType.UPDATE_READ_CODE_LIST):
            return {
                ...state,
                availableReadCodes: action.payload.readCodes,
                readCodesLoaded: action.payload.readCodesLoaded
            }
        case (actionType.UPDATE_TEMPLATE_LIST):
            return {
                ...state,
                templates: action.payload.templates,
                templatesLoaded: action.payload.templatesLoaded
            }
        case (actionType.UPDATE_TEMPLATE_ITEM_LIST):
            return {
                ...state,
                templateItems: action.payload.templateItems,
                templateItemsLoaded: action.payload.templateItemsLoaded
            }
        case (actionType.UPDATE_APPOINTMENT_VIEW_CONFIG):
            return {
                ...state,
                appointmentView: {
                    currentRefDate: action.payload.currentRefDate,
                    view: action.payload.view
                }
            }
        default:
            return state;
    }
}

export default Reducer;