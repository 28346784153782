import React, { Component } from 'react';
import './App.css';
import LoginPage from './containers/LoginPage/LoginPage';
import Board from './containers/Board/Board';
import { Switch, Route, Redirect } from 'react-router-dom';

class App extends Component {
  componentDidMount() {
    // used to fix the scrolling issue on iOS (https://stackoverflow.com/questions/48873171/webkit-overflow-touch-stops-working-when-tapping-on-element-outside-of-scroll)
    document.addEventListener('touchmove', (e) => {
      document.body.scrollTop = 0;
    });
  }

  render() {
    return (
      <div className="App">
        <Switch>
          <Route path='/board' component={Board} />
          <Route path='/login' component={LoginPage} />
          <Route render={() => <Redirect from='/' to='/login' />} />
        </Switch>
      </div>
    );
  }
}

export default App;
