import React, { ReactNode, useEffect, useState } from 'react';
import { isMobileOnly } from 'react-device-detect';
import { Dropdown, DropdownProps, Icon, Pagination, PaginationProps, Placeholder, PlaceholderLine, PlaceholderParagraph, Table } from 'semantic-ui-react';
import './CustomReactTable.scss';
import cn from 'classnames';
import { flexRender, getCoreRowModel, getExpandedRowModel, getGroupedRowModel, getPaginationRowModel, getSortedRowModel, GroupingState, PaginationState, Row, SortingState, useReactTable } from '@tanstack/react-table';

type CustomReactTableProps = {
  columns: Array<any>,
  data: Array<any>,
  pagination?: boolean,
  activePage?: number,
  defaultPageSize?: number,
  showPageSizeOptions?: boolean,
  pageSizeOptions?: Array<number>,
  groupBy?: Array<string>,
  noDataText?: string,
  clickable?: boolean,
  defaultSorting?: SortingState,
  loading?: boolean,
  stickyHeader?: boolean,
  xOverflowMode?: XOverflowMode,
  autoResetPageIndex?: boolean,
  highlightedRow?: HighlightRowCondition,
  fixedStyle?: boolean,
  isRowHighlighted?: (row: Row<any>) => boolean,
  onRowClick?: (e: React.MouseEvent<HTMLElement>, row: Row<any>) => void
  onPaginationChange?: (state: PaginationState) => void,
}

export enum XOverflowMode {
  Scroll,
  Stack
}

type HighlightRowCondition = {
  fieldName: string,
  highlightedValue: any
}

const DEFAULT_PAGE_SIZE = 10;
const DEFUALT_PAGE_SIZE_OPTIONS = [5, 10, 15, 20, 50, 100];

const defaultProps: CustomReactTableProps = {
  columns: [],
  data: [],
  defaultPageSize: DEFAULT_PAGE_SIZE,
  noDataText: 'No data found',
  autoResetPageIndex: true,
}

const CustomReactTable = (props: CustomReactTableProps) => {
  const derivedProps = {
    ...defaultProps,
    ...props,
  }
  const {
    data,
    columns,
    pagination,
    pageSizeOptions,
    defaultPageSize,
    showPageSizeOptions,
    clickable,
    defaultSorting,
    loading,
    noDataText,
    stickyHeader,
    xOverflowMode,
    autoResetPageIndex,
    highlightedRow,
    fixedStyle,
    onRowClick,
    onPaginationChange,
  } = derivedProps;

  const memorizedData = React.useMemo(() => data, [data]);
  const memorizedColumns = React.useMemo(() => columns, [columns]);

  const getInitialPageSize = () => {
    if (!pagination) {
      return Number.MAX_SAFE_INTEGER;
    }
    let pageSize = defaultPageSize || DEFAULT_PAGE_SIZE;
    const availablePageSizeOptions = pageSizeOptions && pageSizeOptions.length > 0 ? pageSizeOptions : DEFUALT_PAGE_SIZE_OPTIONS;
    if (availablePageSizeOptions.indexOf(pageSize) === -1) {
      console.error(`Selected page size ${pageSize} is not a valid options in page size options, default to the first option`);
      pageSize = availablePageSizeOptions[0];
    }
    return pageSize;
  }

  const [sorting, setSorting] = React.useState<SortingState>(defaultSorting || []);
  const [paginationState, setPaginationState] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: getInitialPageSize()
  });
  const [rowSelection, setRowSelection] = useState<any>({});

  const [grouping, setGrouping] = useState<GroupingState>(columns.filter(x => x.enableGrouping).map(x => x.id))

  const table = useReactTable({
    columns: memorizedColumns,
    data: memorizedData,
    state: {
      sorting,
      pagination: paginationState,
      rowSelection,
      grouping,
    },
    autoResetPageIndex: autoResetPageIndex === undefined ? true : autoResetPageIndex,
    onPaginationChange: setPaginationState,
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onRowSelectionChange: setRowSelection,
    onGroupingChange: setGrouping,
    getExpandedRowModel: getExpandedRowModel(),
    getGroupedRowModel: getGroupedRowModel(),
  });

  const changePage = (event: React.MouseEvent<HTMLAnchorElement>, paginationData: PaginationProps) => {
    const page = ((typeof paginationData.activePage === 'string') ? parseInt(paginationData.activePage) : paginationData.activePage) || 1;
    table.setPageIndex(page - 1);
  }

  const changePageSize = (event: React.SyntheticEvent<HTMLElement, Event>, data: DropdownProps) => {
    const pageSize = data.value as number;
    table.setPageSize(pageSize);
  }

  useEffect(() => {
    if (onPaginationChange) {
      onPaginationChange(paginationState);
    }
  }, [paginationState, onPaginationChange])

  let paginationControls = new Array<ReactNode>();
  if (pagination && data.length > 0) {
    paginationControls.push(
      <Pagination
        key='table-pagination'
        className='table-pagination'
        totalPages={table.getPageCount()}
        onPageChange={changePage}
        activePage={paginationState.pageIndex + 1}
        boundaryRange={isMobileOnly ? 0 : 1}
        ellipsisItem={isMobileOnly ? null : undefined}
        firstItem={null}
        lastItem={null}
      />
    )
    if (showPageSizeOptions) {
      const pageOptionsDropdownItems = (pageSizeOptions || DEFUALT_PAGE_SIZE_OPTIONS).map((op: number) => (
        { key: op, value: op, text: `${op} / page` }
      ));
      paginationControls.push(
        <Dropdown key='page-size-options' className='page-size-options' compact options={pageOptionsDropdownItems} selection value={table.getState().pagination.pageSize} onChange={changePageSize} />
      )
    }
  }

  return (
    <div className={cn({
      'custom-react-table': true,
      'clickable': clickable,
    })}>
      <div className={cn({
        'table-container-scroll-x': xOverflowMode === XOverflowMode.Scroll
      })}>
        <Table striped singleLine fixed={fixedStyle === undefined ? !isMobileOnly : fixedStyle} compact={isMobileOnly} celled stackable={xOverflowMode === XOverflowMode.Stack} unstackable={xOverflowMode !== XOverflowMode.Stack} selectable={clickable}>
          <Table.Header className={cn({
            'custom-react-table-header': true,
            'sticky': stickyHeader,
            'empty-table': data.length === 0
          })}>
            {
              table.getHeaderGroups().map((headerGp) => (
                <Table.Row key={headerGp.id}>
                  {
                    headerGp.headers.map((header) => (
                      <Table.HeaderCell collapsing key={header.id} textAlign={header.column.id === '_select' ? 'center' : undefined} width={header.column.id === '_select' ? 1 : undefined}>
                        {header.isPlaceholder ? null : (
                          <div
                            {...{
                              className: header.column.getCanSort()
                                ? 'cursor-pointer select-none'
                                : '',
                              onClick: header.column.getToggleSortingHandler(),
                            }}
                          >
                            {flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                            {{
                              asc: <Icon name='caret up' />,
                              desc: <Icon name='caret down' />,
                            }[header.column.getIsSorted() as string] ?? null}
                          </div>
                        )}
                      </Table.HeaderCell>
                    ))
                  }
                </Table.Row>
              ))
            }
          </Table.Header>
          {
            !loading && data.length > 0 &&
            <Table.Body>
              {table.getRowModel().rows.map(row => (
                <Table.Row key={row.id}
                  onClick={(e: React.MouseEvent<HTMLElement>) => { onRowClick && onRowClick(e, row); grouping.length > 0 && row.toggleExpanded() }}
                  className={cn({
                    'row-highlighted': highlightedRow && row.original[highlightedRow.fieldName] === highlightedRow.highlightedValue,
                    'row-grouped': row.getIsGrouped(),
                  })}>
                  {row.getVisibleCells().map(cell => {
                    let cellContent = flexRender(cell.column.columnDef.cell, cell.getContext());
                    if (cell.getIsGrouped()) {
                      cellContent = flexRender(cell.column.columnDef.aggregatedCell ?? cell.column.columnDef.cell, cell.getContext());
                    }

                    if (cell.column.id === '_select') {
                      return (
                        <Table.Cell key={cell.id} onClick={(e: React.MouseEvent<HTMLElement>) => {
                          e.preventDefault();
                          e.stopPropagation();
                        }} textAlign='center'>
                          {cellContent}
                        </Table.Cell>
                      )
                    }
                    return (
                      <Table.Cell key={cell.id}>
                        {cellContent}
                      </Table.Cell>
                    )
                  }

                  )}
                </Table.Row>
              ))}
            </Table.Body>
          }
        </Table>
        {
          !loading && data.length === 0 &&
          <div className='no-data-found-text'>
            {noDataText}
          </div>
        }
        {
          loading &&
          <div>
            <Placeholder fluid>
              <PlaceholderParagraph>
                <PlaceholderLine />
                <PlaceholderLine />
                <PlaceholderLine />
                <PlaceholderLine />
                <PlaceholderLine />
                <PlaceholderLine />
                <PlaceholderLine />
                <PlaceholderLine />
                <PlaceholderLine />
                <PlaceholderLine />
                <PlaceholderLine />
              </PlaceholderParagraph>
            </Placeholder>
          </div>
        }
      </div>
      <div className='pagiation-div'>
        {paginationControls}
      </div>
    </div>

  );
};

export default CustomReactTable;