import React, { Component } from 'react';
import { connect } from 'react-redux';
import './TopBar.css';
import { Button, Image, Icon, Popup, Modal } from 'semantic-ui-react';
import { FormatDate } from '../../../assets/helper';
import HelpModal from './Help/HelpModal';
import FocusLock from 'react-focus-lock';
import { isMobileOnly } from 'react-device-detect';

class TopBar extends Component {
    state = {
        showUserPopup: false,
        showLogoutModal: false,
        showHelpModal: false,
    }

    logOutClickHandler = () => {
        this.setState({
            showLogoutModal: true
        });
    }

    logOut = () => {
        localStorage.removeItem('enote-token');
        sessionStorage.removeItem('enote-token');

        document.title = 'eNote Portal'
        window.location.reload();
    }

    render() {

        let organisation = null;
        if (this.props.user.organisationId === 1) {
            organisation = 'AcNZ';
        } else if (this.props.user.organisationId === 2) {
            organisation = 'NZASA';
        }

        return (
            <div className='TopBar'>

                <div className='MenuToggler'>
                    <Icon name='bars' size='large' onClick={this.props.onToggleMenu} />
                </div>
                <div className='Brand'>
                    <Image src={process.env.PUBLIC_URL + '/images/logo_32.png'} className='TopLogoImg' />
                    <p className='BrandName'>eNote Pro</p>
                </div>
                <div className='Content'>
                    <Icon name='calendar alternate'></Icon>
                    <p>License Expiry Date: <strong>{FormatDate(this.props.user.licenseExpiryDate)}</strong></p>
                </div>
                <div className='UserDetail'>
                    <Popup trigger=
                        {
                            <span className='UserProfile'>
                                <Icon name='user outline' />
                                <p className='UserAccount'>{this.props.user.username}</p>
                            </span>
                        }
                        open={this.state.showUserPopup}
                        onOpen={() => this.setState({ showUserPopup: true })}
                        onClose={() => this.setState({ showUserPopup: false })}
                        on='click'
                        position='bottom right'
                        wide>
                        <div className='UserProfilePopup'>
                            <div>
                                <Icon name='user' />
                                <p>{isMobileOnly ? this.props.user.username : this.props.user.firstName + ' ' + this.props.user.lastName}</p>
                            </div>
                            <div>
                                <Icon name='id badge' />
                                <p>{organisation}</p>
                            </div>
                            <div>
                                <Icon name='calendar alternate'></Icon>
                                <p>License Expiry: <strong>{FormatDate(this.props.user.licenseExpiryDate)}</strong></p>
                            </div>
                            <div className='PopupAlt'>
                                <Button primary onClick={this.logOutClickHandler}>Sign Out</Button>
                                <Button onClick={() => this.setState({ showUserPopup: false, showHelpModal: true })}>Help</Button>
                            </div>
                        </div>
                    </Popup>

                    <Button className='SignOut' primary onClick={this.logOutClickHandler} title='End the current session and clear any saved credentail. Simply close the tab without signing out if you have enabled auto-login and wish to keep your credentail in this browser.'>Sign Out</Button>
                    <Icon name='question circle outline' className='Faq' size='large' onClick={() => this.setState({ showHelpModal: true })} />
                    <HelpModal open={this.state.showHelpModal} onClose={() => this.setState({ showHelpModal: false })} />
                    <Modal open={this.state.showLogoutModal}>
                        <Modal.Content>
                            <p>Are you sure you want to sign out? Any unsaved change will be lost and the saved account credential will be cleared. If you wish to log in automatically in the future, simply close the page without signing out.</p>
                        </Modal.Content>
                        <Modal.Actions>
                            <FocusLock>
                                <Button onClick={() => this.setState({ showLogoutModal: false })}>Cancel</Button>
                                <Button autoFocus primary onClick={this.logOut} onKeyDown={this.handleKeyPress}>OK</Button>
                            </FocusLock>
                        </Modal.Actions>
                    </Modal>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        user: state.user,
        token: state.token
    };
}

export default connect(mapStateToProps)(TopBar);

