import React, { Component } from 'react';
import { Modal, Header, List, Button, Message, Icon } from 'semantic-ui-react';
import AccAxios from '../../../../../axios-acc';
import { FormatDate, FormatDateTime } from '../../../../../assets/helper';
import { NotificationManager } from 'react-notifications';
import EnoteAxios from '../../../../../axios';
import packageJson from '../../../../../../package.json';
import { connect } from 'react-redux';
import CustomLoader from '../../../../../UI/CustomLoader/CustomLoader';
import moment from 'moment';
import './InvoiceAccModal.css';
import InvoiceHelper from './InvoiceHelper/InvoiceHelper';
import FocusLock from 'react-focus-lock';
import { fetchUserDetail } from '../../../../../store/thunks';
import { sendLogToNewRelicAsync } from '../../../../../newRelicHelper';
import currency from 'currency.js';

class InvoiceAccModal extends Component {
    state = {
        patient: {},
        injury: {},
        formattedTreatments: [],
        invoiceAmount: 0,
        missingFields: [],
        invoiceLimitAlertDates: {},
        invoiceMultipleTreatmentOnSameDay: [],
        invoiceLimitWarningConfirmed: false,
        invoicingFailed: false,
        loading: true,
        isSubmitting: false,
        progressText: '',
        showHelp: false,
        canInvoice: true,
    }

    async componentDidMount() {
        await this.props.fetchUserDetail();
        await this.populateInvoiceSummary();
    }

    populateInvoiceSummary = async () => {
        let errors = [];
        if (!this.props.patientId || !this.props.injuryId) {
            if (!this.props.patientId)
                errors.push('Patient ID (Internal)');
            if (!this.props.injuryId)
                errors.push('Injury ID (Internal');

            console.log('Missing patientId/injuryId. No-op');
            this.setState({
                missingFields: errors,
                loading: false
            });
            return;

        }

        const loadPatientPromise = this.loadPatientDetail(this.props.patientId);
        const loadInjuryPromise = this.loadInjuryDetail(this.props.patientId, this.props.injuryId);
        await Promise
            .all([loadPatientPromise, loadInjuryPromise])
            .then(async(results) => {
                await new Promise(resolve => {
                    this.setState({
                        patient: results[0],
                        injury: results[1],
                    }, resolve);
                })
            });

        if (!this.props.user.firstName)
            errors.push('Provider First Name');
        if (!this.props.user.lastName)
            errors.push('Provider Last Name');
        if (!this.props.user.companyName)
            errors.push('Company Name');
        if (!this.props.user.providerId)
            errors.push('Provider ID');
        if (!this.props.user.accRate)
            errors.push('ACC Rate');

        if (!this.state.patient.firstName)
            errors.push('Patient First Name');
        if (!this.state.patient.lastName)
            errors.push('Patient Last Name');
        if (!this.state.patient.dob)
            errors.push('Patient Date of Birth');

        if (!this.state.injury.accNumber)
            errors.push('Injury ACC Number');

        if (this.props.treatments.find(x => x.fee === 0))
            errors.push('Treatment Fee');
        
        if (errors.length > 0) {
            this.setState({
                missingFields: errors,
                loading: false
            });
            return;
        }

        const treatmentsToCheck = [];
        this.props.treatments.forEach(t => {
            if (t.claimStatus === 0) {
                treatmentsToCheck.push({
                    treatmentId: t.treatmentId,
                    patientId: t.patientId,
                    injuryId: t.injuryId,
                    index: t.treatmentIndex,
                    treatmentDate: t.date,
                    claimStatus: t.claimStatus
                });
            }
        });
        if (treatmentsToCheck.length > 0) {
            this.setState({
                loading: true
            });
            await EnoteAxios.post('/api/treatment/CheckInvoiceDailyLimit', treatmentsToCheck).then(response => {
                if (response && response.data) {
                    this.setState({
                        canInvoice: response.data.canInvoice,
                        invoiceLimitAlertDates: response.data.invoiceLimitList,
                        invoiceMultipleTreatmentOnSameDay: response.data.multipleInvoiceDates,
                    });
                }
            }).catch(error => {
                console.log(error);
            }).then(() => {
                this.setState({
                    loading: false,
                });
            });
        }

        let selectedTreatments = [];
        let totalAmount = 0;
        this.props.treatments.forEach(treatment => {
            let amount = currency(treatment.fee).value;

            selectedTreatments.push({
                accNumber: this.state.injury.accNumber,
                accidentDate: FormatDate(this.state.injury.date),
                treatmentDate: treatment.date,
                durationInMinutes: parseInt(treatment.expectedDuration),
                amount: amount,

                patient: this.state.patient,
                treatmentDetail: {
                    treatmentId: treatment.treatmentId,
                    patientId: treatment.patientId,
                    injuryId: treatment.injuryId,
                    index: treatment.treatmentIndex,
                    fee: amount
                }
            });
            totalAmount += amount;
        });

        totalAmount = currency(totalAmount).value;
        this.setState({
            formattedTreatments: selectedTreatments,
            invoiceAmount: totalAmount,
            loading: false
        });
    }

    loadPatientDetail = async (patientId) => {
        const res = await EnoteAxios.get('/api/patient/GetPatient', {
            params: {
                patientId: patientId
            }
        });

        return res.data;
    }

    loadInjuryDetail = async (patientId, injuryId) => {
        const res = await EnoteAxios.get('/api/injury/GetInjury', {
            params: {
                patientId: patientId,
                injuryId: injuryId
            }
        });

        return res.data;
    }

    invoiceAccClickHandler = async () => {
        this.setState({
            isSubmitting: true,
            progressText: 'Preparing invoice...'
        });
        
        sendLogToNewRelicAsync({
            message: `Submit invoice button clicked for user ${this.props.user.userId}`,
            userId: this.props.user.userId,
            accNumber: this.state.injury.accNumber
        });

        let invoiceNumber = await this.submitInvoicesToACC(this.state.formattedTreatments);

        sendLogToNewRelicAsync({
            message: `Invoicing request complete for user ${this.props.user.userId}, invoiceNumber ${invoiceNumber}`,
            userId: this.props.user.userId,
            accNumber: this.state.injury.accNumber,
            invoiceNumber: invoiceNumber
        });

        if (invoiceNumber) {
            this.setState({
                progressText: 'Updating invoice status...'
            });
            const treatmentSubmissionList = [];
            this.state.formattedTreatments.forEach(treatment => {
                treatmentSubmissionList.push({
                    ...treatment.treatmentDetail,
                    claimStatus: 1,
                    claimDateTime: FormatDateTime(moment(), 'YYYY-MM-DD HH:mm:ss')
                });
            })
            await EnoteAxios.put('/api/treatment/UpdateTreatmentInvoiceStatus', treatmentSubmissionList, {
                params: {
                    invoiceNumber: invoiceNumber
                }
            }).then(response => {
                NotificationManager.success('Invoice submitted successfully', 'Success');
                sendLogToNewRelicAsync({
                    message: `Invoice status updated in eNote for user ${this.props.userId}, invoiceNumber ${invoiceNumber}`,
                    userId: this.props.user.userId,
                    accNumber: this.state.injury.accNumber,
                    invoiceNumber: invoiceNumber
                });
                this.props.onComplete();
            }).catch(error => {
                console.log(error);
                NotificationManager.warning('Invoice submitted to ACC but failed to update claim status internally.', 'Failed');
                sendLogToNewRelicAsync({
                    message: `Failed to update invoice status in eNote for user ${this.props.userId}, invoiceNumber ${invoiceNumber}`,
                    userId: this.props.user.userId,
                    accNumber: this.state.injury.accNumber,
                    invoiceNumber: invoiceNumber,
                    error: error
                });
                this.props.onAbort();
            });
        } else {
            this.setState({
                invoicingFailed: true,
                loading: false
            });
        }
    }

    submitInvoicesToACC = async (treatmentList) => {
        if (treatmentList && treatmentList.length > 0) {
            let lines = [];
            treatmentList.forEach((treatment) => {
                lines.push({
                    claimId: treatment.accNumber,
                    accidentDate: treatment.accidentDate,
                    lineDetails: {
                        serviceCodes: [packageJson.enote.serviceCode],
                        serviceDate: treatment.treatmentDate,
                        purchaseOrderNumber: "",
                        billing: {
                            hoursClaimed: Math.floor(treatment.durationInMinutes / 60),
                            minutesClaimed: treatment.durationInMinutes % 60,
                            billingMethod: "time"
                        },
                        invoiceComment: "",
                        invoiceAmount: treatment.amount,
                    },
                    patient: {
                        nhi: treatment.patient.nhi,
                        firstName: treatment.patient.firstName,
                        middleName: "",
                        surname: treatment.patient.lastName,
                        dateOfBirth: treatment.patient.dob
                    },
                    provider: {
                        providerId: this.props.user.providerId,
                        firstName: this.props.user.firstName,
                        middleName: "",
                        surname: this.props.user.lastName,
                        facilityId: ""
                    }
                });
            });

            const invoice = {
                pmsSoftwareName: packageJson.enote.pmsSoftwareName,
                pmsSoftwareVersion: packageJson.version,
                vendor: {
                    vendorName: this.props.user.companyName,
                    vendorGST: this.props.user.gstNumber,
                    vendorID: this.props.user.vendorId ?? this.props.user.providerId,
                    vendorPhone: ""
                },
                comment: "",
                lines: [...lines],
                contractId: "",
                declaration: {
                    declarationDate: FormatDate(moment())
                }
            }

            this.setState({
                progressText: 'Waiting for user certificate...'
            });
            return await AccAxios.post('claims/vendors/invoice', invoice).then(response => {
                console.log(response);
                if (response.data && response.data.invoice) {
                    return response.data.invoice.invoiceId;
                }
            }).catch(error => {
                console.log(error);
                sendLogToNewRelicAsync({
                    message: `Failed to submit invoice for user ${this.props.user.userId}`,
                    userId: this.props.user.userId,
                    accNumber: this.state.injury.accNumber
                });
                return '';
            });
        }
        return '';
    }

    invoiceLimitWarningConfirmHandler = () => {
        this.setState({
            invoiceLimitWarningConfirmed: true
        });
    }

    render() {
        let progressMsg = null;
        if (this.state.isSubmitting) {
            progressMsg = (
                <Message icon>
                    <Icon name='circle notched' loading />
                    <Message.Content>
                        <p>{this.state.progressText}</p>
                    </Message.Content>
                </Message>
            );
        }

        let helpModal = (<Icon className='HelpIcon' name='help circle' size='large' onClick={() => { this.setState({ showHelp: true }) }} />);

        let content = null;
        if (this.state.loading) {
            content = (
                <React.Fragment>
                    <Modal.Header>Invoice Validation</Modal.Header>
                    <Modal.Content>
                        <CustomLoader active={true} cssLoader />
                    </Modal.Content>
                </React.Fragment>
            );
        } else if (this.state.missingFields.length > 0) {
            content = (
                <React.Fragment>
                    <Modal.Header>Invoice Validation</Modal.Header>
                    <Modal.Content>
                        <p>Cannot invoice ACC because the following information is missing:</p>
                        <List bulleted>
                            {this.state.missingFields.map(field => {
                                return <List.Item key={field}>{field}</List.Item>
                            })}
                        </List>
                    </Modal.Content>
                    <Modal.Actions>
                        <FocusLock>
                            <Button primary content='OK' onClick={this.props.onAbort} />
                        </FocusLock>
                    </Modal.Actions>
                </React.Fragment>
            );
        } else if (!this.state.invoiceLimitWarningConfirmed && (Object.keys(this.state.invoiceLimitAlertDates).length > 0 || this.state.invoiceMultipleTreatmentOnSameDay.length > 0)) {
            const alertDates = [];
            let limitElem = null;
            if (Object.keys(this.state.invoiceLimitAlertDates).length > 0) {
                Object.keys(this.state.invoiceLimitAlertDates).sort().forEach(date => {
                    alertDates.push(
                        <List.Item key={date}>{FormatDate(date)}: {this.state.invoiceLimitAlertDates[date]}</List.Item>
                    );
                });
                limitElem = (
                    <React.Fragment>
                        <p>You are trying to invoice too many treatments on the following date(s):</p>
                        <List bulleted>
                            {alertDates}
                        </List>
                    </React.Fragment>
                );
            }

            let datesWithMultipleTreatmentForPatient = null;
            if (this.state.invoiceMultipleTreatmentOnSameDay.length > 0) {
                let dates = this.state.invoiceMultipleTreatmentOnSameDay.map(d => {
                    return <List.Item key={d}>{FormatDate(d)}</List.Item>
                })
                datesWithMultipleTreatmentForPatient = (
                    <React.Fragment>
                        <p>You will have more than one invoiced treatment for this patient in the following date(s):</p>
                        <List bulleted>
                            {dates}
                        </List>
                    </React.Fragment>
                );
            }


            content = (
                <React.Fragment>
                    <Modal.Header>Invoice Validation</Modal.Header>
                    <Modal.Content>
                        {limitElem}
                        {datesWithMultipleTreatmentForPatient}
                        <p>Current Setting:</p>
                        <List bulleted>
                            {
                                this.props.user.treatmentAlertLimit > 0 ?
                                    <List.Item>Treatment Alert Limit: {this.props.user.treatmentAlertLimit} - You will be notified when you are about to invoice more than {this.props.user.treatmentAlertLimit} treatments on a day</List.Item>
                                    : null
                            }
                            {
                                this.props.user.treatmentDurationAlertLimit > 0 ?
                                    <List.Item>Treatment Daily Hours Limit: {this.props.user.treatmentDurationAlertLimit} - You will be notified when you are about to invoice more than {this.props.user.treatmentDurationAlertLimit - 1} hours of treatments on a day. Invoicing will be disabled when you are about to invoice more than {this.props.user.treatmentDurationAlertLimit} hours of treatments on a day.</List.Item>
                                    : null
                            }

                        </List>
                        {
                            this.state.canInvoice ?
                                <p>Are you sure you want to invoice ACC anyway? Invoices can only be withdrawn by contacting ACC directly.</p> :
                                <p>Cannot submit the invoice due to the configured invoice daily limit.</p>
                        }
                    </Modal.Content>
                    <Modal.Actions>
                        <FocusLock>
                            <Button content='Cancel' onClick={this.props.onAbort} />
                            <Button primary content='Continue' onClick={this.invoiceLimitWarningConfirmHandler} disabled={!this.state.canInvoice} />
                        </FocusLock>
                    </Modal.Actions>
                </React.Fragment>
            );
        } else if (this.state.invoicingFailed) {
            content = (
                <React.Fragment>
                    <Modal.Header>Invoicing Failed</Modal.Header>
                    <Modal.Content>
                        <p>An error occurred while invoicing ACC. This could be due to the following reason(s):</p>
                        <List bulleted>
                            <List.Item>HealthLink digital certificate not installed properly. <br /> You can click <a className='CertinfoLink' href='https://secure.healthlink.net/certinfo/' target="_blank" rel="noopener noreferrer">here</a> to validate your certificate. <br /> The page should display your certificate information if it's installed properly.</List.Item>
                            <List.Item>Incorrect HealthLink digital certificate selected <br />Check the currently selected certificate <a className='CertinfoLink' href='https://secure.healthlink.net/certinfo/' target="_blank" rel="noopener noreferrer">here</a> to make sure you are using the correct certificate. <br />If you have recently installed a new certificate, make sure the old certificate is not used when invoicing. <br />On Windows, please close your browser (not the tab) completely and launch it again. <br />On Mac OS, you may need to delete "Identity Preference" entires that are associated with "enote.co.nz" in Keychains, then close the browser (right click on the dock and click Quit) and launch it again.</List.Item>
                            <List.Item>Invalid ACC Number or treatment info</List.Item>
                            <List.Item>ACC server unavailable</List.Item>
                            <List.Item>Network connection failure</List.Item>
                        </List>
                    </Modal.Content>
                    <Modal.Actions>
                        <FocusLock>
                            <Button primary content='Close' onClick={this.props.onAbort} />
                        </FocusLock>
                    </Modal.Actions>
                </React.Fragment>
            );
        } else {
            let totalResubmission = this.props.treatments.filter(x => x.claimStatus === 1).length;
            content = (
                <React.Fragment>
                    <Modal.Header>Invoice Summary</Modal.Header>
                    <Modal.Content>
                        <p>Please check if the following information is correct before invoiving ACC. You will need to contact ACC directly to make modifications once submitted.</p>
                        <Header>Patient Detail</Header>
                        <List bulleted>
                            <List.Item>Patient Name: {this.state.patient.firstName + ' ' + this.state.patient.lastName}</List.Item>
                            <List.Item>Date of Birth: {FormatDate(this.state.patient.dob)}</List.Item>
                            <List.Item>NHI Number: {this.state.patient.nhi}</List.Item>
                            <List.Item>ACC Number: {this.state.injury.accNumber}</List.Item>
                        </List>
                        <Header>Invoice Detail</Header>
                        <List bulleted>
                            <List.Item className='Highlighted'>Total Treatments: {this.props.treatments.length}</List.Item>
                            <List.Item className={totalResubmission > 0 ? 'Highlighted' : ''}>Total Resubmissions: {this.props.treatments.filter(x => x.claimStatus === 1).length} {totalResubmission > 0 ? ' - Only resubmit invoices when necessary.' : ''}</List.Item>
                            <List.Item className='Highlighted'>Total Invoice Amount (inc.GST): ${this.state.invoiceAmount}</List.Item>
                        </List>
                        <Header>Provider Detail</Header>
                        <List bulleted>
                            <List.Item>Company: {this.props.user.companyName}</List.Item>
                            <List.Item>Provider: {this.props.user.firstName + ' ' + this.props.user.lastName}</List.Item>
                            <List.Item>Vendor ID: {
                                this.props.user.vendorId ?
                                    this.props.user.vendorId :
                                    <React.Fragment>
                                        {this.props.user.providerId}
                                        <span className='Highlighted'> (You have not provided your Vendor ID, we will use your Provider ID as the Vendor ID)</span>
                                    </React.Fragment>}
                            </List.Item>
                            <List.Item>Provider ID: {this.props.user.providerId}</List.Item>
                            <List.Item>GST Number (optional): {this.props.user.gstNumber}</List.Item>
                        </List>
                        {progressMsg}
                    </Modal.Content>
                    <Modal.Actions>
                        <FocusLock>
                            {helpModal}
                            <Button content='Cancel' onClick={this.props.onAbort} disabled={this.state.isSubmitting} />
                            <Button primary data-autofocus content='Submit' onClick={this.invoiceAccClickHandler} loading={this.state.isSubmitting} disabled={this.state.isSubmitting} />
                        </FocusLock>
                    </Modal.Actions>
                </React.Fragment>
            );
        }

        return (
            <Modal open={this.props.show} className='InvoiceAccModal'>
                {content}
                <InvoiceHelper open={this.state.showHelp} onClose={() => { this.setState({ showHelp: false }) }} />
            </Modal>
        )
    }
}

const mapStateToProps = state => {
    return {
        user: state.user
    }
}

const mapDispatchToProps = dispatch => {
    return {
        fetchUserDetail: () => dispatch(fetchUserDetail())
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceAccModal);