import React, { Component } from 'react';
import { Form, Button, Message, Header, List, ButtonGroup, ButtonOr } from 'semantic-ui-react';
import EnoteAxios from '../../../axios';
import './PasswordReset.css';

const USERNAME = 'username';
const NEW_PASSWORD = 'newPassword';
const New_PASSWORD_CONFIRM = 'newPasswordConfirm';
const SECURITY_CODE = 'securityCode';

const SEND_SECURITY_CODE = 'Verify';
const SENT = 'Email Sent';

class PasswordReset extends Component {
    state = {
        [USERNAME]: '',
        [NEW_PASSWORD]: '',
        [New_PASSWORD_CONFIRM]: '',
        [SECURITY_CODE]: '',
        showResetForm: false,
        showErrorMsg: false,
        errorMsg: '',
        loading: false
    }

    handleChange = (e, { name, value }) => {
        this.setState({
            [name]: value
        });
    }

    sendSecurityCodeClickHandler = async () => {
        this.setState({
            loading: true
        });

        await EnoteAxios.post('/api/account/SendPasswordResetCode', {
            [USERNAME]: this.state.username
        }).then(response => {
            this.setState({
                showResetForm: true,
                loading: false
            });
        }).catch(err => {
            let error = '';
            if (err.response && err.response.data) {
                error = err.response.data;
            }
            this.setState({
                showErrorMsg: true,
                errorMsg: error,
                loading: false
            });
        });
    }

    resetPasswordClickHandler = async () => {
        this.setState({
            loading: true
        });

        await EnoteAxios.post('/api/account/ResetPassword', {
            [USERNAME]: this.state.username,
            [NEW_PASSWORD]: this.state.newPassword,
            [New_PASSWORD_CONFIRM]: this.state.newPasswordConfirm,
            [SECURITY_CODE]: this.state.securityCode
        }).then(() => {
            this.props.onSwitchView();
        }).catch(err => {
            let msg = '';
            if (err.response && err.response.data) {
                msg = err.response.data;
            }
            this.setState({
                showErrorMsg: true,
                errorMsg: msg,
                loading: false
            });
        });
    }

    render() {
        let resetForm = null;
        let controls = (<Button className='SwitchToLogin' content='Back' onClick={this.props.onSwitchView} />);
        if (this.state.showResetForm) {
            resetForm = (
                <div>
                    <Message className='password-reset-msg' positive>
                        <p>An email with a security code has been sent to the email address if the user account exists. Please enter the security code provided in the email to reset your password.</p>
                    </Message>
                    <Form.Field>
                        <Form.Input type='text' className='Form_Input' label='Security Code' name={SECURITY_CODE} value={this.state.securityCode} onChange={this.handleChange} />
                    </Form.Field>
                    <Form.Field>
                        <Form.Input type='password' className='Form_Input' label='New Password' name={NEW_PASSWORD} value={this.state.newPassword} onChange={this.handleChange} />
                    </Form.Field>
                    <Form.Field>
                        <Form.Input type='password' className='Form_Input' label='Confirm New Password' name={New_PASSWORD_CONFIRM} value={this.state.newPasswordConfirm} onChange={this.handleChange} />
                    </Form.Field>
                    <Message className='password-reset-msg'>
                        <Message.Header>A password must meet the following criteria</Message.Header>
                        <List bulleted>
                            <List.Item>Contains 8 characters or more</List.Item>
                            <List.Item>Contains at least 1 digit</List.Item>
                            <List.Item>Contains at least 1 letter</List.Item>
                        </List>
                    </Message>
                </div>
            );
            controls = (
                <React.Fragment>
                    <ButtonGroup>
                        {controls}
                        <ButtonOr />
                        <Button primary className='Form_Input' content='Save'
                            onClick={this.resetPasswordClickHandler}
                            loading={this.state.loading}
                            disabled={!(this.state.securityCode &&
                                this.state.newPassword &&
                                this.state.newPasswordConfirm &&
                                this.state.newPassword.length >= 8 &&
                                (this.state.newPassword === this.state.newPasswordConfirm)) || this.state.loading} />
                    </ButtonGroup>

                </React.Fragment>
            );
        }

        let errMessage = null;
        if (this.state.showErrorMsg) {
            errMessage = (
                <Message negative>
                    <Message.Header>Failure</Message.Header>
                    <p>{this.state.errorMsg}</p>
                </Message>
            );
        }

        return (
            <div className="PasswordReset">
                <Form autoComplete='off'>
                    <Header>Password Reset</Header>
                    <p>Please enter your username (email address) to reset the password.</p>
                    <Form.Field>
                        <Form.Input type='text' className='Form_Input' label='Username' name={USERNAME} value={this.state.username} onChange={this.handleChange}
                            readOnly={this.state.showResetForm}
                        />
                        <Button primary onClick={this.sendSecurityCodeClickHandler} disabled={this.state.showResetForm || this.state.loading} loading={this.state.loading && !this.state.showResetForm}>
                            {this.state.showResetForm ? SENT : SEND_SECURITY_CODE}
                        </Button>
                    </Form.Field>
                    {resetForm}
                    {errMessage}
                    <div className='PasswordResetControls'>
                        {controls}
                    </div>
                </Form>
            </div>
        )
    }
}

export default PasswordReset;