import React from 'react';
import './TabContent.css';

const TabContent = React.memo((props) => {
    let classes = 'TabContent';
    if (!props.active) {
        classes = classes + ' HiddenTab'
    }
    return (
        <div className={classes}>{props.children}</div>
    );
})

export default TabContent;