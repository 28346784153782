import React, { Component } from 'react';
import { connect } from 'react-redux';
import './TreatmentNzra.css';
import ControlPane from '../../../../../UI/Controls/Control/ControlPane';
import { Form, Button, Header } from 'semantic-ui-react';
import { DateInput, TimeInput } from 'semantic-ui-calendar-react-yz';
import Slider from 'rc-slider';
import Tooltip from 'rc-tooltip';
import CustomTextArea from '../../../../../UI/CustomTextArea/CustomTextArea';
import 'rc-slider/assets/index.css';
import EnoteAxios from '../../../../../axios';
import { FormatDate, FormatTime, GetTreatmentTabID, GetPatientFullNameShort, IsValidDate, IsValidTime } from '../../../../../assets/helper';
import CustomLoader from '../../../../../UI/CustomLoader/CustomLoader';
import ConditionalArea from '../../../../../UI/ConditionalArea/ConditionalArea';
import QuickFillDropdown from '../../../../../UI/QuickFillDropdown/QuickFillDropdown';
import { NotificationManager } from 'react-notifications';
import { TREATMENT_DURATION } from '../../../../../assets/constants';
import moment from 'moment';
import ConfrimDialog from '../../../../../UI/ConfrimDialog/ConfirmDialog';

const DATE = 'date';
const START_TIME = 'startTime';
const STOP_TIME = 'stopTime';
const SUBJECT_FINDINGS = 'subjectFindings';
const ONSET_PROGRESSION = 'onsetProgression';
const AGGRAVATED_BY = 'aggravatedBy';
const RELIEVED_BY = 'relievedBy';
const PREV_TREATMENT = 'prevTreatment';
const FLAG_YELLOW = 'yellowFlag';
const FLAG_RED = 'redFlag';
const PROGRESS = 'progress'
const RANGE_OF_MOVEMENT = 'rangeOfMovement';
const PALPATION = 'palpation';
const MERIDIAN_AFFECTED = 'meridianAffected';
const EXAMINATION = 'examination';
const NPRS = 'nprs';
const PSFS = 'psfs';
const IMPEDIMENT_FUNC = 'impedimentFunc';
const IMPEDIMENT_FUNC_1 = 'impedimentFunc1';
const IMPEDIMENT_FUNC_2 = 'impedimentFunc2';
const IMPEDIMENT_FUNC_3 = 'impedimentFunc3';
const IMPEDIMENT_FUNC_1_SCALE = 'impedimentFunc1Scale';
const IMPEDIMENT_FUNC_2_SCALE = 'impedimentFunc2Scale';
const IMPEDIMENT_FUNC_3_SCALE = 'impedimentFunc3Scale';
const PULSE_QUALITY = 'pulseQuality';
const PULSE_LEFT_NOTE = 'pulseLeftNote';
const PULSE_RIGHT_NOTE = 'pulseRightNote';
const TONGUE_BODY = 'tongueBody';
const TONGUE_COATING = 'tongueCoating';
const DIAGNOSIS = 'diagnosis';
const TREATMENT_PRINCIPLES = 'treatmentPrinciples';
const TREATMENT_GOAL = 'treatmentGoal';
const TREATMENT_PLAN = 'treatmentPlan';
const EXPECTED_DURATION = 'expectedDuration'
const TREATMENT_METHODS = 'treatmentMethods';
const TREATMENT_METHOD_NOTES = 'treatmentMethodNotes';
const POINTS_SELECTED = 'pointsSelected';
const ADVICES = 'advices';
const CLAIM_STATUS = 'claimStatus';
const CLAIM_DATETIME = 'claimDateTime';
const FEE = 'fee';
const CLIENT_CONSENTED = 'clientConsented';

const ACUPUNCTURE = 'acupuncture';
const ELECTROACUPUNCTURE = 'electroAcupuncture';
const AURICLUARACUPUNCTURE = 'auricluarAcupuncture';
const GUASHA = 'guaSha';
const BLEEDING = 'bleeding';
const CUPPING = 'cupping';
const MOXIBUSTION = 'moxibustion';
const TUINA = 'tuiNa';
const TDP = 'tdp';
const LINIMENTS = 'liniments';
const HERBALPLASTERS = 'herbalPlasters';


const SLIDER_SCALE_MARKS = {
    0: 0,
    1: 1,
    2: 2,
    3: 3,
    4: 4,
    5: 5,
    6: 6,
    7: 7,
    8: 8,
    9: 9,
    10: 10
};

const NZRA_TREATMENT_METHOD_LIST = {
    [ACUPUNCTURE]: 1,
    [ELECTROACUPUNCTURE]: 2,
    [AURICLUARACUPUNCTURE]: 4,
    [GUASHA]: 8,
    [BLEEDING]: 16,
    [CUPPING]: 32,
    [MOXIBUSTION]: 64,
    [TUINA]: 128,
    [TDP]: 256,
    [LINIMENTS]: 512,
    [HERBALPLASTERS]: 1024
}

const TREATMENT_TEMPLATE_NAME_TO_ID = {
    [SUBJECT_FINDINGS]: 1,
    [ONSET_PROGRESSION]: 2,
    [AGGRAVATED_BY]: 3,
    [RELIEVED_BY]: 4,
    [PREV_TREATMENT]: 5,
    [FLAG_YELLOW]: 6,
    [FLAG_RED]: 7,
    [PROGRESS]: 8,
    [RANGE_OF_MOVEMENT]: 9,
    [PALPATION]: 10,
    [MERIDIAN_AFFECTED]: 11,
    [IMPEDIMENT_FUNC]: 12,
    [EXAMINATION]: 13,
    [PULSE_QUALITY]: 14,
    [PULSE_LEFT_NOTE]: 15,
    [PULSE_RIGHT_NOTE]: 16,
    [TONGUE_BODY]: 17,
    [TONGUE_COATING]: 18,
    [DIAGNOSIS]: 19,
    [TREATMENT_PLAN]: 20,
    [TREATMENT_PRINCIPLES]: 21,
    [ACUPUNCTURE]: 22,
    [GUASHA]: 23,
    [ELECTROACUPUNCTURE]: 24,
    [AURICLUARACUPUNCTURE]: 25,
    [BLEEDING]: 26,
    [CUPPING]: 27,
    [MOXIBUSTION]: 28,
    [TUINA]: 29,
    [TDP]: 30,
    [LINIMENTS]: 31,
    [HERBALPLASTERS]: 32,
    [POINTS_SELECTED]: 33,
    [ADVICES]: 34,
    [TREATMENT_GOAL]: 35
}

class TreatmentNzra extends Component {
    state = {
        index: 1,
        nzra: {
            [DATE]: '',
            [START_TIME]: '',
            [STOP_TIME]: '',
            [SUBJECT_FINDINGS]: '',
            [ONSET_PROGRESSION]: '',
            [AGGRAVATED_BY]: '',
            [RELIEVED_BY]: '',
            [PREV_TREATMENT]: '',
            [FLAG_YELLOW]: '',
            [FLAG_RED]: '',
            [PROGRESS]: '',
            [RANGE_OF_MOVEMENT]: '',
            [PALPATION]: '',
            [MERIDIAN_AFFECTED]: '',
            [EXAMINATION]: '',
            [NPRS]: 0,
            [PSFS]: 0,
            [IMPEDIMENT_FUNC_1]: '',
            [IMPEDIMENT_FUNC_2]: '',
            [IMPEDIMENT_FUNC_3]: '',
            [IMPEDIMENT_FUNC_1_SCALE]: 0,
            [IMPEDIMENT_FUNC_2_SCALE]: 0,
            [IMPEDIMENT_FUNC_3_SCALE]: 0,
            [PULSE_QUALITY]: '',
            [PULSE_LEFT_NOTE]: '',
            [PULSE_RIGHT_NOTE]: '',
            [TONGUE_BODY]: '',
            [TONGUE_COATING]: '',
            [DIAGNOSIS]: '',
            [TREATMENT_PRINCIPLES]: '',
            [TREATMENT_GOAL]: '',
            [TREATMENT_PLAN]: '',
            [EXPECTED_DURATION]: 0,
            [TREATMENT_METHODS]: 0,
            [TREATMENT_METHOD_NOTES]: '',
            [POINTS_SELECTED]: '',
            [ADVICES]: '',
            [CLAIM_STATUS]: 0,
            [CLAIM_DATETIME]: '',
            [FEE]: 0,
            [CLIENT_CONSENTED]: true,
        },
        nzraTreatmentMethods: {
            [ACUPUNCTURE]: false,
            [ELECTROACUPUNCTURE]: false,
            [AURICLUARACUPUNCTURE]: false,
            [GUASHA]: false,
            [BLEEDING]: false,
            [CUPPING]: false,
            [MOXIBUSTION]: false,
            [TUINA]: false,
            [TDP]: false,
            [LINIMENTS]: false,
            [HERBALPLASTERS]: false
        },
        templateOptions: {
            [SUBJECT_FINDINGS]: [],
            [ONSET_PROGRESSION]: [],
            [AGGRAVATED_BY]: [],
            [RELIEVED_BY]: [],
            [PREV_TREATMENT]: [],
            [FLAG_YELLOW]: [],
            [FLAG_RED]: [],
            [PROGRESS]: [],
            [RANGE_OF_MOVEMENT]: [],
            [PALPATION]: [],
            [MERIDIAN_AFFECTED]: [],
            [EXAMINATION]: [],
            [IMPEDIMENT_FUNC]: [],
            [PULSE_QUALITY]: [],
            [PULSE_LEFT_NOTE]: [],
            [PULSE_RIGHT_NOTE]: [],
            [TONGUE_BODY]: [],
            [TONGUE_COATING]: [],
            [DIAGNOSIS]: [],
            [TREATMENT_PRINCIPLES]: [],
            [TREATMENT_GOAL]: [],
            [TREATMENT_PLAN]: [],
            [ACUPUNCTURE]: [],
            [ELECTROACUPUNCTURE]: [],
            [AURICLUARACUPUNCTURE]: [],
            [GUASHA]: [],
            [BLEEDING]: [],
            [CUPPING]: [],
            [MOXIBUSTION]: [],
            [TUINA]: [],
            [TDP]: [],
            [LINIMENTS]: [],
            [HERBALPLASTERS]: [],
            [TREATMENT_METHOD_NOTES]: [],
            [POINTS_SELECTED]: [],
            [ADVICES]: []
        },
        createMode: true,
        loading: true,
        showTreatmentCountAlert: false,
        confirmationMsg: '',
        shouldCloseOnSave: false
    }

    async componentDidMount() {
        if (this.props.user) {
            if (this.props.data.treatmentId === 0) {
                await EnoteAxios.get('/api/treatment/GetLatestTreatment', {
                    params: {
                        patientId: this.props.data.patient.patientId,
                        injuryId: this.props.data.injuryId
                    }
                }).then(response => {
                    if (response && response.data) {
                        this.populateTreatmentDetail(response.data, true);
                    } else {
                        this.setState({
                            index: 1
                        });
                    }
                }).catch(err => {
                    console.log(err);
                });
            } else {
                await EnoteAxios.get('/api/treatment/GetTreatment', {
                    params: {
                        treatmentId: this.props.data.treatmentId
                    }
                }).then(response => {
                    if (response && response.data) {
                        this.populateTreatmentDetail(response.data, false);
                    } else {
                        console.log('invalid response from GetTreatment. No-op');
                    }
                }).catch(err => {
                    console.log(err);
                })
            }

            this.populateTemplateItems();
        }
        this.setState({
            loading: false
        })
    }

    componentDidUpdate(prevProps) {
        if (this.props.templateItems !== prevProps.templateItems) {
            this.populateTemplateItems();
        }
    }

    populateTemplateItems = () => {
        let options = {};
        if (this.props.templateItems) {
            Object.keys(this.state.templateOptions).forEach(key => {
                let templateID = TREATMENT_TEMPLATE_NAME_TO_ID[key]
                if (templateID) {
                    options[key] = this.props.templateItems[templateID] ? this.props.templateItems[templateID] : [];
                } else {
                    options[key] = [];
                }
            });
        }

        this.setState({
            templateOptions: options
        });
    }

    populateTreatmentDetail = (data, isNew) => {
        let { treatmentId, injuryId, patientId, treatmentIndex, ...treatmentDetail } = data;

        let treatmentToDisplay = {}

        if (isNew) {
            treatmentIndex++;
            if (treatmentIndex !== 5) {
                treatmentToDisplay = {
                    ...treatmentDetail,
                    [DATE]: FormatDate(moment(treatmentDetail.date).add(1, 'day')),
                    [START_TIME]: FormatTime(treatmentDetail.startTime, true),
                    [STOP_TIME]: FormatTime(treatmentDetail.stopTime, true),
                    [PROGRESS]: '',
                    [SUBJECT_FINDINGS]: '',
                    [FLAG_RED]: '',
                    [FLAG_YELLOW]: '',
                    [ONSET_PROGRESSION]: '',
                    [RELIEVED_BY]: '',
                    [AGGRAVATED_BY]: '',
                    [PREV_TREATMENT]: '',
                    [RANGE_OF_MOVEMENT]: '',
                    [PALPATION]: '',
                    [MERIDIAN_AFFECTED]: '',
                    [NPRS]: 0,
                    [IMPEDIMENT_FUNC_1]: '',
                    [IMPEDIMENT_FUNC_1_SCALE]: 0,
                    [IMPEDIMENT_FUNC_2]: '',
                    [IMPEDIMENT_FUNC_2_SCALE]: 0,
                    [IMPEDIMENT_FUNC_3]: '',
                    [IMPEDIMENT_FUNC_3_SCALE]: 0,
                    [CLAIM_STATUS]: 0,
                    [CLAIM_DATETIME]: '',
                    [CLIENT_CONSENTED]: true,
                }

                if (treatmentIndex === 2) {
                    treatmentToDisplay = {
                        ...treatmentToDisplay,
                        [EXAMINATION]: treatmentDetail.rangeOfMovement
                    }
                }
            } else {
                treatmentToDisplay = {
                    ...treatmentDetail,
                    [DATE]: FormatDate(moment(treatmentDetail.date).add(1, 'day')),
                    [START_TIME]: FormatTime(treatmentDetail.startTime, true),
                    [STOP_TIME]: FormatTime(treatmentDetail.stopTime, true),
                    [PROGRESS]: '',
                    [CLAIM_STATUS]: 0,
                    [CLAIM_DATETIME]: '',
                    [CLIENT_CONSENTED]: true,
                }
            }
        } else {
            treatmentToDisplay = {
                ...treatmentDetail,
                [DATE]: FormatDate(treatmentDetail.date),
                [START_TIME]: FormatTime(treatmentDetail.startTime, true),
                [STOP_TIME]: FormatTime(treatmentDetail.stopTime, true),
                [CLAIM_DATETIME]: FormatDate(treatmentDetail.claimDateTime, 'YYYY-MM-DD HH:mm:ss')
            }
        }


        this.setState({
            nzra: treatmentToDisplay,
            index: treatmentIndex,
            nzraTreatmentMethods: this.getTreatmentMethodsFromInt(treatmentDetail.treatmentMethods),
            createMode: isNew
        });

        if (isNew && this.props.onTouch)
            this.props.onTouch();
    }

    validateForm = () => {
        let valid = true;
        valid = FormatDate(this.state.nzra.date) !== '';

        return valid;
    }

    inputChangeHandler = (e, { name, value }) => {
        this.setState(prevState => ({
            nzra: {
                ...prevState.nzra,
                [name]: value
            }
        }));

        if (!this.props.touched && this.props.onTouch) {
            this.props.onTouch();
        }
    }

    checkBoxChangeHandler = (e, { name, checked }) => {
        this.setState(prevState => ({
            nzra: {
                ...prevState.nzra,
                [name]: checked
            }
        }));

        if (!this.props.touched && this.props.onTouch) {
            this.props.onTouch();
        }
    }

    startTimeChangeHandler = (e, { value }) => {
        let newStartTime = moment('2000-01-01 ' + value, 'YYYY-MM-DD HH:mm', true);
        if (newStartTime.isValid()) {
            this.setState(prevState => ({
                nzra: {
                    ...prevState.nzra,
                    startTime: value,
                    stopTime: newStartTime.add(1, 'hour').format('HH:mm'),
                    expectedDuration: 60
                }
            }));

            if (!this.props.touched && this.props.onTouch) {
                this.props.onTouch();
            }
        } else {
            this.setState(prevState => ({
                nzra: {
                    ...prevState.nzra,
                    startTime: value
                }
            }))
        }
    }

    nprsInputChangeHandler = (value) => {
        this.setState(prevState => ({
            nzra: {
                ...prevState.nzra,
                nprs: value
            }
        }));

        if (!this.props.touched && this.props.onTouch) {
            this.props.onTouch();
        }
    }

    psfsInputChangeHandler = (value) => {
        this.setState(prevState => ({
            nzra: {
                ...prevState.nzra,
                psfs: value
            }
        }));

        if (!this.props.touched && this.props.onTouch) {
            this.props.onTouch();
        }
    }

    impedimentFunc1ScaleInputChangeHandler = (value) => {
        this.setState(prevState => ({
            nzra: {
                ...prevState.nzra,
                impedimentFunc1Scale: value
            }
        }));

        if (!this.props.touched && this.props.onTouch) {
            this.props.onTouch();
        }
    }

    impedimentFunc2ScaleInputChangeHandler = (value) => {
        this.setState(prevState => ({
            nzra: {
                ...prevState.nzra,
                impedimentFunc2Scale: value
            }
        }));

        if (!this.props.touched && this.props.onTouch) {
            this.props.onTouch();
        }
    }

    impedimentFunc3ScaleInputChangeHandler = (value) => {
        this.setState(prevState => ({
            nzra: {
                ...prevState.nzra,
                impedimentFunc3Scale: value
            }
        }));

        if (!this.props.touched && this.props.onTouch) {
            this.props.onTouch();
        }
    }

    sliderHandle = (props) => {
        const { value, dragging, index, ...restProps } = props;
        return (
            <Tooltip
                prefixCls="rc-slider-tooltip"
                overlay={value}
                visible={dragging}
                placement="top"
                key={index}
            >
                <Slider.Handle value={value} {...restProps} />
            </Tooltip>
        );
    };

    nzraTreatmentMethodChangeHandler = (e, data) => {
        let newNotes = null;
        if (data.checked && this.state.templateOptions[data.name].length > 0 && this.state.templateOptions[data.name][0].items.length > 0) {
            let firstItem = this.state.templateOptions[data.name][0].items[0].content;
            if (firstItem) {
                newNotes = this.state.nzra.treatmentMethodNotes.length > 0 ? this.state.nzra.treatmentMethodNotes + '\n' + firstItem : firstItem
            }
        }

        this.setState(prevState => ({
            nzraTreatmentMethods: {
                ...prevState.nzraTreatmentMethods,
                [data.name]: data.checked
            },
            nzra: {
                ...prevState.nzra,
                treatmentMethods: prevState.nzra.treatmentMethods + (data.checked ? data.methodvalue : -data.methodvalue),
                treatmentMethodNotes: newNotes || prevState.nzra.treatmentMethodNotes
            }
        }));

        if (!this.props.touched && this.props.onTouch) {
            this.props.onTouch();
        }
    }

    getTreatmentMethodsFromInt = (methodsInt) => {
        let currMethods = {};
        Object.keys(this.state.nzraTreatmentMethods).forEach(key => {
            currMethods[key] = (methodsInt & NZRA_TREATMENT_METHOD_LIST[key]) === NZRA_TREATMENT_METHOD_LIST[key] ? true : false;
        });

        return currMethods;
    }

    getTreatmentTitle = () => {
        return 'Treatment: ' + GetPatientFullNameShort(this.props.data.patient.firstName, this.props.data.patient.lastName) + ' ' + FormatDate(this.state.nzra.date);
    }

    getTreatmentUID = (treatment) => {
        return treatment.injuryId + '_' + treatment.treatmentIndex;
    }

    saveTreatmentHandler = async (bypassLimitCheck = false, closeOnSave = false) => {
        if (!this.isFormValid(this.state.nzra)) {
            return;
        }

        if (closeOnSave) {
            this.setState({
                shouldCloseOnSave: true
            });
        }

        if (bypassLimitCheck) {
            this.setState({
                showTreatmentCountAlert: false,
                confirmationMsg: ''
            });
        }

        if (this.props.user) {
            this.toggleLoaddingState(true);

            let treatmentDetail = {
                ...this.state.nzra,
                treatmentId: this.props.data.treatmentId,
                patientId: this.props.data.patient.patientId,
                injuryId: this.props.data.injuryId,
                treatmentIndex: this.state.index,
                userId: this.props.user.userId
            }
            if (this.state.createMode) {
                await EnoteAxios.post('/api/treatment/AddTreatmentNZRA', treatmentDetail, {
                    params: {
                        bypassCheck: bypassLimitCheck
                    }
                }).then(response => {
                    if (response.data && response.data.responseData) {
                        const { treatmentId, injuryId, treatmentIndex } = response.data.responseData;
                        this.toggleLoaddingState(false);
                        this.props.onTabMetadataChange(this.props.tabID, GetTreatmentTabID(this.props.data.patient.patientId, injuryId, treatmentIndex),
                            this.getTreatmentTitle(), 'p_' + this.props.data.patient.patientId + ' i_' + injuryId, {
                            treatmentId: treatmentId,
                            injuryId: injuryId,
                            treatmentIndex: treatmentIndex,
                            patient: this.props.data.patient
                        });
                        if (closeOnSave && this.props.onTabClose) {
                            this.props.onTabClose();
                            return;
                        }
                    } else {
                        this.toggleLoaddingState(false);
                    }
                }).catch(err => {
                    console.log(err);
                    if (err && err.response && err.response.status === 422 && err.response.data && err.response.data.confirmation) {
                        this.setState({
                            showTreatmentCountAlert: true,
                            confirmationMsg: err.response.data.confirmation
                        });
                    }
                    this.toggleLoaddingState(false);
                });
            } else {
                await EnoteAxios.put('/api/treatment/EditTreatmentNZRA', treatmentDetail, {
                    params: {
                        currentIndex: this.state.index,
                        bypassCheck: bypassLimitCheck
                    }
                }).then(response => {
                    if (response.data && response.data.responseData) {
                        const { treatmentId, injuryId, treatmentIndex } = response.data.responseData;
                        this.toggleLoaddingState(false);
                        this.props.onTabMetadataChange(this.props.tabID, GetTreatmentTabID(this.props.data.patient.patientId, injuryId, treatmentIndex),
                            this.getTreatmentTitle(), 'p_' + this.props.data.patient.patientId + ' i_' + injuryId, {
                            treatmentId: treatmentId,
                            injuryId: injuryId,
                            treatmentIndex: treatmentIndex,
                            patient: this.props.data.patient
                        }
                        );
                        if (closeOnSave && this.props.onTabClose) {
                            this.props.onTabClose();
                        }
                    } else {
                        this.toggleLoaddingState(false);
                    }
                }).catch(err => {
                    console.log(err);
                    if (err && err.response && err.response.status === 422 && err.response.data && err.response.data.confirmation) {
                        this.setState({
                            showTreatmentCountAlert: true,
                            confirmationMsg: err.response.data.confirmation
                        });
                    }
                    this.toggleLoaddingState(false);
                })
            }
        }
    }

    isFormValid = (treatment) => {
        let errorMessage = '';
        let isValid = true;
        if (!IsValidDate(treatment.date, false) || !IsValidTime(treatment.startTime, false) || !IsValidTime(treatment.stopTime, false)) {
            errorMessage = 'Invalid Treatment date time';
        }

        if (this.props.data.injuryDate && moment(treatment.date).isBefore(moment(this.props.data.injuryDate))) {
            errorMessage += 'Treatment date cannot be earlier than the injury date';
        }

        if (errorMessage.length > 0) {
            NotificationManager.error(errorMessage, 'Failed');
            isValid = false;
        }

        return isValid;
    }

    treatmentAlertLimitCancelHandler = () => {
        this.setState({
            showTreatmentCountAlert: false
        });
    }

    toggleLoaddingState = (isLoading) => {
        this.setState({
            loading: isLoading
        });
    }

    quickFillInputClickHandler = (e, data) => {
        let newData = this.state.nzra[data.associatedinputname].length > 0 ? this.state.nzra[data.associatedinputname] + '\n' + data.itemcontent : data.itemcontent;
        this.setState(prevState => ({
            nzra: {
                ...prevState.nzra,
                [data.associatedinputname]: newData
            }
        }));

        if (!this.props.touched && this.props.onTouch) {
            this.props.onTouch();
        }
    }

    treatmentNotesQuickFillInputClickHandler = (e, data) => {
        this.quickFillInputClickHandler(e, data);
        let methodVal = NZRA_TREATMENT_METHOD_LIST[data.checkitem];
        this.setState(prevState => ({
            nzra: {
                ...prevState.nzra,
                [TREATMENT_METHODS]: prevState.nzraTreatmentMethods[data.checkitem] ? prevState.nzra.treatmentMethods : prevState.nzra.treatmentMethods + methodVal
            },
            nzraTreatmentMethods: {
                ...prevState.nzraTreatmentMethods,
                [data.checkitem]: true
            }
        }));
    }

    render() {
        let treatmentForm = [];
        if (!this.props.user) {
            return <div>Unauthorized</div>
        }

        treatmentForm.push(
            <React.Fragment key='nzra_1'>
                <Header dividing>Treatment Information</Header>
                <Form.Field>
                    <Form.Checkbox label='Informed consent confirmed by client' name={CLIENT_CONSENTED} checked={this.state.nzra.clientConsented} onChange={this.checkBoxChangeHandler} />
                </Form.Field>
                <Form.Group widths='equal'>
                    <Form.Field>
                        <Form.Input label='Index' type='text' name='treatmentIndex' value={this.state.index} readOnly />
                    </Form.Field>
                    <Form.Field>
                        <DateInput
                            required
                            label='Treatment Date'
                            dateFormat='YYYY-MM-DD'
                            name={DATE}
                            placeholder='YYYY-MM-DD'
                            value={this.state.nzra.date}
                            iconPosition="left"
                            closable
                            onChange={this.inputChangeHandler}
                            hideMobileKeyboard
                            minDate={this.props.data.injuryDate || ''}
                            maxDate={moment()}
                            title='Select the treatment date. The treatment date cannot be earlier than the injury date or in the future.'
                            animation='none'
                            error={!IsValidDate(this.state.nzra.date, true)}
                            popupPosition={'bottom left'}
                        />
                    </Form.Field>
                    <Form.Field>
                        <TimeInput
                            required
                            label='Start Time'
                            name={START_TIME}
                            placeholder='Start Time'
                            value={this.state.nzra.startTime}
                            iconPosition='left'
                            closable
                            onChange={this.startTimeChangeHandler}
                            hideMobileKeyboard
                            animation='none'
                            error={!IsValidTime(this.state.nzra.startTime, true)}
                            popupPosition={'bottom left'}
                        />
                    </Form.Field>
                    <Form.Field>
                        <TimeInput
                            required
                            label='Finish Time'
                            name={STOP_TIME}
                            placeholder='Finish Time'
                            value={this.state.nzra.stopTime}
                            iconPosition='left'
                            closable
                            onChange={this.inputChangeHandler}
                            hideMobileKeyboard
                            animation='none'
                            error={!IsValidTime(this.state.nzra.stopTime, true)}
                            popupPosition={'bottom left'}
                        />
                    </Form.Field>
                </Form.Group>
            </React.Fragment>
        );

        if (this.state.index === 1) {
            treatmentForm.push(
                <React.Fragment key='nzra_2a'>
                    <Header dividing>Subjective Findings</Header>
                    <Form.Group widths='equal'>
                        <Form.Field>
                            <Form.Field>
                                <QuickFillDropdown label='Description' onItemClick={this.quickFillInputClickHandler} associatedinputname={SUBJECT_FINDINGS} options={this.state.templateOptions[SUBJECT_FINDINGS]} />
                                <CustomTextArea name={SUBJECT_FINDINGS} rows={6} value={this.state.nzra.subjectFindings} onChange={this.inputChangeHandler} placeholder='Description' />
                            </Form.Field>
                        </Form.Field>
                        <Form.Field>
                            <Form.Field className='StackedField'>
                                <QuickFillDropdown label='Red Flag' onItemClick={this.quickFillInputClickHandler} associatedinputname={FLAG_RED} options={this.state.templateOptions[FLAG_RED]} />
                                <Form.Input type='text' name={FLAG_RED} value={this.state.nzra.redFlag} onChange={this.inputChangeHandler} placeholder='N/A' />
                            </Form.Field>
                            <Form.Field>
                                <QuickFillDropdown label='Yellow Flag' onItemClick={this.quickFillInputClickHandler} associatedinputname={FLAG_YELLOW} options={this.state.templateOptions[FLAG_YELLOW]} />
                                <Form.Input type='text' name={FLAG_YELLOW} value={this.state.nzra.yellowFlag} onChange={this.inputChangeHandler} placeholder='N/A' />
                            </Form.Field>
                        </Form.Field>
                    </Form.Group>
                    <Form.Group widths='equal'>
                        <Form.Field>
                            <QuickFillDropdown label='Onset And Progression' onItemClick={this.quickFillInputClickHandler} associatedinputname={ONSET_PROGRESSION} options={this.state.templateOptions[ONSET_PROGRESSION]} />
                            <Form.Input type='text' name={ONSET_PROGRESSION} value={this.state.nzra.onsetProgression} onChange={this.inputChangeHandler} placeholder='Onset and Progression' />
                        </Form.Field>
                        <Form.Field>
                            <QuickFillDropdown label='Relieved By' onItemClick={this.quickFillInputClickHandler} associatedinputname={RELIEVED_BY} options={this.state.templateOptions[RELIEVED_BY]} />
                            <Form.Input type='text' name={RELIEVED_BY} value={this.state.nzra.relievedBy} onChange={this.inputChangeHandler} placeholder='Relieved By' />
                        </Form.Field>
                    </Form.Group>
                    <Form.Group widths='equal'>
                        <Form.Field>
                            <QuickFillDropdown label='Aggravated By' onItemClick={this.quickFillInputClickHandler} associatedinputname={AGGRAVATED_BY} options={this.state.templateOptions[AGGRAVATED_BY]} />
                            <Form.Input type='text' name={AGGRAVATED_BY} value={this.state.nzra.aggravatedBy} onChange={this.inputChangeHandler} placeholder='Aggravated By' />
                        </Form.Field>
                        <Form.Field>
                            <QuickFillDropdown label='Previous Treatment' onItemClick={this.quickFillInputClickHandler} associatedinputname={PREV_TREATMENT} options={this.state.templateOptions[PREV_TREATMENT]} />
                            <Form.Input type='text' name={PREV_TREATMENT} value={this.state.nzra.prevTreatment} onChange={this.inputChangeHandler} placeholder='N/A' />
                        </Form.Field>
                    </Form.Group>
                </React.Fragment>
            );
        } else {
            treatmentForm.push(
                <React.Fragment key='nzra_2b'>
                    <Header dividing>Subjective Findings</Header>
                    <Form.Group widths='equal'>
                        <Form.Field>
                            <QuickFillDropdown label='Result/Progress' onItemClick={this.quickFillInputClickHandler} associatedinputname={PROGRESS} options={this.state.templateOptions[PROGRESS]} />
                            <CustomTextArea name={PROGRESS} rows={4} value={this.state.nzra.progress} onChange={this.inputChangeHandler} placeholder='Treatment results or progress' />
                        </Form.Field>
                    </Form.Group>
                </React.Fragment>
            )
        }

        if (this.state.index === 1) {
            treatmentForm.push(
                <React.Fragment key='nzra_3a'>
                    <Header dividing>Objective Findings</Header>
                    <Form.Group widths='equal'>
                        <Form.Field>
                            <QuickFillDropdown label='Range of Movement' onItemClick={this.quickFillInputClickHandler} associatedinputname={RANGE_OF_MOVEMENT} options={this.state.templateOptions[RANGE_OF_MOVEMENT]} />
                            <CustomTextArea name={RANGE_OF_MOVEMENT} rows={6} value={this.state.nzra.rangeOfMovement} onChange={this.inputChangeHandler} placeholder='Range of movement' />
                        </Form.Field>
                        <Form.Field>
                            <QuickFillDropdown label='Palpation' onItemClick={this.quickFillInputClickHandler} associatedinputname={PALPATION} options={this.state.templateOptions[PALPATION]} />
                            <CustomTextArea name={PALPATION} rows={3} value={this.state.nzra.palpation} onChange={this.inputChangeHandler} placeholder='Palpation detail' />
                        </Form.Field>
                    </Form.Group>
                </React.Fragment>
            );
        } else if (this.state.index === 5) {
            treatmentForm.push(
                <React.Fragment key='nzra_3b'>
                    <Header dividing>Objective Findings</Header>
                    <Form.Group widths='equal'>
                        <Form.Field>
                            <QuickFillDropdown label='Examination' onItemClick={this.quickFillInputClickHandler} associatedinputname={EXAMINATION} options={this.state.templateOptions[EXAMINATION]} />
                            <CustomTextArea name={EXAMINATION} rows={6} value={this.state.nzra.examination} onChange={this.inputChangeHandler} placeholder='Examination detail' />
                        </Form.Field>
                        <Form.Field>
                            <QuickFillDropdown label='Range of Movement' onItemClick={this.quickFillInputClickHandler} associatedinputname={RANGE_OF_MOVEMENT} options={this.state.templateOptions[RANGE_OF_MOVEMENT]} />
                            <CustomTextArea name={RANGE_OF_MOVEMENT} rows={6} value={this.state.nzra.rangeOfMovement} onChange={this.inputChangeHandler} placeholder='Describe range of movement' />
                        </Form.Field>
                    </Form.Group>
                    <Form.Group widths='equal'>
                        <Form.Field className='Slider'>
                            <label>Numerical Pain Rating Scale (NPRS)</label>
                            <Slider
                                min={0}
                                max={10}
                                value={this.state.nzra.nprs}
                                onChange={this.nprsInputChangeHandler}
                                dots
                                marks={SLIDER_SCALE_MARKS}
                                handle={this.sliderHandle}
                            />
                        </Form.Field>
                        <Form.Field>
                        </Form.Field>
                    </Form.Group>
                </React.Fragment>
            );
        } else {
            treatmentForm.push(
                <React.Fragment key='nzra_3c'>
                    <Header dividing>Objective Findings</Header>
                    <Form.Group widths='equal'>
                        <Form.Field>
                            <QuickFillDropdown label='Examination' onItemClick={this.quickFillInputClickHandler} associatedinputname={EXAMINATION} options={this.state.templateOptions[EXAMINATION]} />
                            <CustomTextArea name={EXAMINATION} rows={6} value={this.state.nzra.examination} onChange={this.inputChangeHandler} placeholder='Examination detail' />
                        </Form.Field>
                        <Form.Field>
                            <QuickFillDropdown label='Range of Movement' onItemClick={this.quickFillInputClickHandler} associatedinputname={RANGE_OF_MOVEMENT} options={this.state.templateOptions[RANGE_OF_MOVEMENT]} />
                            <CustomTextArea name={RANGE_OF_MOVEMENT} rows={6} value={this.state.nzra.rangeOfMovement} onChange={this.inputChangeHandler} placeholder='Describe range of movement' />
                        </Form.Field>
                    </Form.Group>
                </React.Fragment>
            );
        }

        if (this.state.index === 1) {
            treatmentForm.push(
                <React.Fragment key='nzra_4a'>
                    <Form.Group widths='equal'>
                        <Form.Field>
                            <QuickFillDropdown label='Meridian(s) Affected' onItemClick={this.quickFillInputClickHandler} associatedinputname={MERIDIAN_AFFECTED} options={this.state.templateOptions[MERIDIAN_AFFECTED]} />
                            <Form.Input type='text' name={MERIDIAN_AFFECTED} value={this.state.nzra.meridianAffected} onChange={this.inputChangeHandler} placeholder='Meridian(s) Affected' />
                        </Form.Field>
                        <Form.Field className='Slider'>
                            <label>Numerical Pain Rating Scale (NPRS)</label>
                            <Slider
                                min={0}
                                max={10}
                                value={this.state.nzra.nprs}
                                onChange={this.nprsInputChangeHandler}
                                dots
                                marks={SLIDER_SCALE_MARKS}
                                handle={this.sliderHandle}
                            />
                        </Form.Field>
                    </Form.Group>
                </React.Fragment>
            );
        }

        if (this.state.index === 1 || this.state.index === 5) {
            treatmentForm.push(
                <React.Fragment key='nzra_5'>
                    <Header dividing>Patient Specific Function Scales</Header>
                    <Form.Group widths='equal'>
                        <Form.Field className='Slider'>
                            <label>Patient Specific Function Scale (PSFS)</label>
                            <Slider
                                min={0}
                                max={10}
                                value={this.state.nzra.psfs}
                                onChange={this.psfsInputChangeHandler}
                                dots
                                marks={SLIDER_SCALE_MARKS}
                                handle={this.sliderHandle}
                            />
                        </Form.Field>
                        <Form.Field>
                        </Form.Field>
                    </Form.Group>
                    <Form.Group widths='equal'>
                        <Form.Field>
                            <Form.Field>
                                <QuickFillDropdown label='Impediment of Function 1' onItemClick={this.quickFillInputClickHandler} associatedinputname={IMPEDIMENT_FUNC_1} options={this.state.templateOptions[IMPEDIMENT_FUNC]} />
                                <Form.Input type='text' name={IMPEDIMENT_FUNC_1} value={this.state.nzra.impedimentFunc1} onChange={this.inputChangeHandler} placeholder='Impediment of Function 1' />
                            </Form.Field>
                        </Form.Field>

                        <Form.Field className='Slider'>
                            <label>Scale</label>
                            <Slider
                                min={0}
                                max={10}
                                value={this.state.nzra.impedimentFunc1Scale}
                                onChange={this.impedimentFunc1ScaleInputChangeHandler}
                                dots
                                marks={SLIDER_SCALE_MARKS}
                                handle={this.sliderHandle}
                            />
                        </Form.Field>
                    </Form.Group>

                    <Form.Group widths='equal'>
                        <Form.Field>
                            <Form.Field>
                                <QuickFillDropdown label='Impediment of Function 2' onItemClick={this.quickFillInputClickHandler} associatedinputname={IMPEDIMENT_FUNC_2} options={this.state.templateOptions[IMPEDIMENT_FUNC]} />
                                <Form.Input type='text' name={IMPEDIMENT_FUNC_2} value={this.state.nzra.impedimentFunc2} onChange={this.inputChangeHandler} placeholder='Impediment of Function 2' />
                            </Form.Field>
                        </Form.Field>

                        <Form.Field className='Slider'>
                            <label>Scale</label>
                            <Slider
                                min={0}
                                max={10}
                                value={this.state.nzra.impedimentFunc2Scale}
                                onChange={this.impedimentFunc2ScaleInputChangeHandler}
                                dots
                                marks={SLIDER_SCALE_MARKS}
                                handle={this.sliderHandle}
                            />
                        </Form.Field>
                    </Form.Group>

                    <Form.Group widths='equal'>
                        <Form.Field>
                            <Form.Field>
                                <QuickFillDropdown label='Impediment of Function 3' onItemClick={this.quickFillInputClickHandler} associatedinputname={IMPEDIMENT_FUNC_3} options={this.state.templateOptions[IMPEDIMENT_FUNC]} />
                                <Form.Input type='text' name={IMPEDIMENT_FUNC_3} value={this.state.nzra.impedimentFunc3} onChange={this.inputChangeHandler} placeholder='Impediment of Function 3' />
                            </Form.Field>
                        </Form.Field>

                        <Form.Field className='Slider'>
                            <label>Scale</label>
                            <Slider
                                min={0}
                                max={10}
                                value={this.state.nzra.impedimentFunc3Scale}
                                onChange={this.impedimentFunc3ScaleInputChangeHandler}
                                dots
                                marks={SLIDER_SCALE_MARKS}
                                handle={this.sliderHandle}
                            />
                        </Form.Field>
                    </Form.Group>
                </React.Fragment>
            );
        }


        treatmentForm.push(
            <React.Fragment key='nzra_6'>
                <Header dividing>Pulse</Header>
                <Form.Group widths='equal'>
                    <Form.Field>
                        <QuickFillDropdown label='Pulse Overall Quality' onItemClick={this.quickFillInputClickHandler} associatedinputname={PULSE_QUALITY} options={this.state.templateOptions[PULSE_QUALITY]} />
                        <Form.Input type='text' name={PULSE_QUALITY} value={this.state.nzra.pulseQuality} onChange={this.inputChangeHandler} placeholder='Pulse Overall Quality' />
                    </Form.Field>
                    <Form.Field>
                        <QuickFillDropdown label='Left' onItemClick={this.quickFillInputClickHandler} associatedinputname={PULSE_LEFT_NOTE} options={this.state.templateOptions[PULSE_LEFT_NOTE]} />
                        <Form.Input type='text' name={PULSE_LEFT_NOTE} value={this.state.nzra.pulseLeftNote} onChange={this.inputChangeHandler} placeholder='Left' />
                    </Form.Field>
                    <Form.Field>
                        <QuickFillDropdown label='Right' onItemClick={this.quickFillInputClickHandler} associatedinputname={PULSE_RIGHT_NOTE} options={this.state.templateOptions[PULSE_RIGHT_NOTE]} />
                        <Form.Input type='text' name={PULSE_RIGHT_NOTE} value={this.state.nzra.pulseRightNote} onChange={this.inputChangeHandler} placeholder='Right' />
                    </Form.Field>
                </Form.Group>
                <Header dividing>Tongue</Header>
                <Form.Group widths='equal'>
                    <Form.Field>
                        <QuickFillDropdown label='Tongue Body' onItemClick={this.quickFillInputClickHandler} associatedinputname={TONGUE_BODY} options={this.state.templateOptions[TONGUE_BODY]} />
                        <Form.Input type='text' name={TONGUE_BODY} value={this.state.nzra.tongueBody} onChange={this.inputChangeHandler} placeholder='Tongue Body' />
                    </Form.Field>
                    <Form.Field>
                        <QuickFillDropdown label='Tongue Coating' onItemClick={this.quickFillInputClickHandler} associatedinputname={TONGUE_COATING} options={this.state.templateOptions[TONGUE_COATING]} />
                        <Form.Input type='text' name={TONGUE_COATING} value={this.state.nzra.tongueCoating} onChange={this.inputChangeHandler} placeholder='Tongue Coating' />
                    </Form.Field>
                    <Form.Field>
                    </Form.Field>
                </Form.Group>
            </React.Fragment>
        )

        treatmentForm.push(
            <React.Fragment key='nzra_7'>
                <Header dividing>Diagnosis & Treatment Plan</Header>
                <Form.Group widths='equal'>
                    <Form.Field>
                        <QuickFillDropdown label='Diagnosis' onItemClick={this.quickFillInputClickHandler} associatedinputname={DIAGNOSIS} options={this.state.templateOptions[DIAGNOSIS]} />
                        <CustomTextArea name={DIAGNOSIS} rows={3} value={this.state.nzra.diagnosis} onChange={this.inputChangeHandler} placeholder='Injury diagnosis' />
                    </Form.Field>
                    <Form.Field>
                        <QuickFillDropdown label='Treatment Principles' onItemClick={this.quickFillInputClickHandler} associatedinputname={TREATMENT_PRINCIPLES} options={this.state.templateOptions[TREATMENT_PRINCIPLES]} />
                        <CustomTextArea name={TREATMENT_PRINCIPLES} rows={3} value={this.state.nzra.treatmentPrinciples} onChange={this.inputChangeHandler} placeholder='Treatment principles' />
                    </Form.Field>
                </Form.Group>
            </React.Fragment>
        );

        if (this.state.index === 1) {
            treatmentForm.push(
                <React.Fragment key='nzra_8'>
                    <Form.Group widths='equal'>
                        <Form.Field>
                            <QuickFillDropdown label='Treatment Goal' onItemClick={this.quickFillInputClickHandler} associatedinputname={TREATMENT_GOAL} options={this.state.templateOptions[TREATMENT_GOAL]} />
                            <CustomTextArea name={TREATMENT_GOAL} rows={3} value={this.state.nzra.treatmentGoal} onChange={this.inputChangeHandler} placeholder='Goals of treatment' />
                        </Form.Field>
                        <Form.Field>
                            <QuickFillDropdown label='Treatment Plan' onItemClick={this.quickFillInputClickHandler} associatedinputname={TREATMENT_PLAN} options={this.state.templateOptions[TREATMENT_PLAN]} />
                            <CustomTextArea name={TREATMENT_PLAN} rows={3} value={this.state.nzra.treatmentPlan} onChange={this.inputChangeHandler} placeholder='Treatment plan' />
                        </Form.Field>
                    </Form.Group>
                </React.Fragment>
            );
        }

        treatmentForm.push(
            <React.Fragment key='nzra_9'>
                <Header dividing>Treatment Detail</Header>
                <Form.Group widths='equal'>
                    <Form.Field>
                        <Form.Dropdown required selection name={EXPECTED_DURATION} options={TREATMENT_DURATION} label='Expected Treatment Duration (Minutes)' value={this.state.nzra.expectedDuration} onChange={this.inputChangeHandler} placeholder='Select duration' />
                    </Form.Field>
                    <Form.Field>
                    </Form.Field>
                </Form.Group>
                <Form.Group widths='16'>
                    <Form.Field width='4' className='TreatmentMainCol'>
                        <Form.Field className='flex-form-field'>
                            <Form.Checkbox name={ACUPUNCTURE} methodvalue={NZRA_TREATMENT_METHOD_LIST[ACUPUNCTURE]} checked={this.state.nzraTreatmentMethods.acupuncture} onChange={this.nzraTreatmentMethodChangeHandler} className='TreatmentMethodCheckBox' />
                            <span className='TreatmentMethodSpan'>
                                <QuickFillDropdown label='Acupuncture' checkitem={ACUPUNCTURE} onItemClick={this.treatmentNotesQuickFillInputClickHandler} associatedinputname={TREATMENT_METHOD_NOTES} options={this.state.templateOptions[ACUPUNCTURE]} />
                            </span>
                        </Form.Field>
                        <Form.Field className='flex-form-field'>
                            <Form.Checkbox name={ELECTROACUPUNCTURE} methodvalue={NZRA_TREATMENT_METHOD_LIST[ELECTROACUPUNCTURE]} checked={this.state.nzraTreatmentMethods.electroAcupuncture} onChange={this.nzraTreatmentMethodChangeHandler} className='TreatmentMethodCheckBox' />
                            <span className='TreatmentMethodSpan'>
                                <QuickFillDropdown label='Electro-Acupuncture' checkitem={ELECTROACUPUNCTURE} onItemClick={this.treatmentNotesQuickFillInputClickHandler} associatedinputname={TREATMENT_METHOD_NOTES} options={this.state.templateOptions[ELECTROACUPUNCTURE]} />
                            </span>
                        </Form.Field>
                        <Form.Field className='flex-form-field'>
                            <Form.Checkbox name={AURICLUARACUPUNCTURE} methodvalue={NZRA_TREATMENT_METHOD_LIST.auricluarAcupuncture} checked={this.state.nzraTreatmentMethods.auricluarAcupuncture} onChange={this.nzraTreatmentMethodChangeHandler} className='TreatmentMethodCheckBox' />
                            <span className='TreatmentMethodSpan'>
                                <QuickFillDropdown label='Auricluar-Acupuncture' checkitem={AURICLUARACUPUNCTURE} onItemClick={this.treatmentNotesQuickFillInputClickHandler} associatedinputname={TREATMENT_METHOD_NOTES} options={this.state.templateOptions[AURICLUARACUPUNCTURE]} />
                            </span>
                        </Form.Field>
                        <Form.Field className='flex-form-field'>
                            <Form.Checkbox name={GUASHA} methodvalue={NZRA_TREATMENT_METHOD_LIST.guaSha} checked={this.state.nzraTreatmentMethods.guaSha} onChange={this.nzraTreatmentMethodChangeHandler} className='TreatmentMethodCheckBox' />
                            <span className='TreatmentMethodSpan'>
                                <QuickFillDropdown label='Gua Sha' checkitem={GUASHA} onItemClick={this.treatmentNotesQuickFillInputClickHandler} associatedinputname={TREATMENT_METHOD_NOTES} options={this.state.templateOptions[GUASHA]} />
                            </span>
                        </Form.Field>
                        <Form.Field className='flex-form-field'>
                            <Form.Checkbox name={BLEEDING} methodvalue={NZRA_TREATMENT_METHOD_LIST.bleeding} checked={this.state.nzraTreatmentMethods.bleeding} onChange={this.nzraTreatmentMethodChangeHandler} className='TreatmentMethodCheckBox' />
                            <span className='TreatmentMethodSpan'>
                                <QuickFillDropdown label='Bleeding' checkitem={BLEEDING} onItemClick={this.treatmentNotesQuickFillInputClickHandler} associatedinputname={TREATMENT_METHOD_NOTES} options={this.state.templateOptions[BLEEDING]} />
                            </span>
                        </Form.Field>
                        <Form.Field className='flex-form-field'>
                            <Form.Checkbox name={CUPPING} methodvalue={NZRA_TREATMENT_METHOD_LIST.cupping} checked={this.state.nzraTreatmentMethods.cupping} onChange={this.nzraTreatmentMethodChangeHandler} className='TreatmentMethodCheckBox' />
                            <span className='TreatmentMethodSpan'>
                                <QuickFillDropdown label='Cupping' checkitem={CUPPING} onItemClick={this.treatmentNotesQuickFillInputClickHandler} associatedinputname={TREATMENT_METHOD_NOTES} options={this.state.templateOptions[CUPPING]} />
                            </span>
                        </Form.Field>
                        <Form.Field className='flex-form-field'>
                            <Form.Checkbox name={MOXIBUSTION} methodvalue={NZRA_TREATMENT_METHOD_LIST.moxibustion} checked={this.state.nzraTreatmentMethods.moxibustion} onChange={this.nzraTreatmentMethodChangeHandler} className='TreatmentMethodCheckBox' />
                            <span className='TreatmentMethodSpan'>
                                <QuickFillDropdown label='Moxibustion' checkitem={MOXIBUSTION} onItemClick={this.treatmentNotesQuickFillInputClickHandler} associatedinputname={TREATMENT_METHOD_NOTES} options={this.state.templateOptions[MOXIBUSTION]} />
                            </span>
                        </Form.Field>
                        <Form.Field className='flex-form-field'>
                            <Form.Checkbox name={TUINA} methodvalue={NZRA_TREATMENT_METHOD_LIST.tuiNa} checked={this.state.nzraTreatmentMethods.tuiNa} onChange={this.nzraTreatmentMethodChangeHandler} className='TreatmentMethodCheckBox' />
                            <span className='TreatmentMethodSpan'>
                                <QuickFillDropdown label='Tui Na' checkitem={TUINA} onItemClick={this.treatmentNotesQuickFillInputClickHandler} associatedinputname={TREATMENT_METHOD_NOTES} options={this.state.templateOptions[TUINA]} />
                            </span>
                        </Form.Field>
                    </Form.Field>
                    <Form.Field width='4' className='TreatmentAdditionalCol'>
                        <Form.Field className='flex-form-field'>
                            <Form.Checkbox name={TDP} methodvalue={NZRA_TREATMENT_METHOD_LIST.tdp} checked={this.state.nzraTreatmentMethods.tdp} onChange={this.nzraTreatmentMethodChangeHandler} className='TreatmentMethodCheckBox' />
                            <span className='TreatmentMethodSpan'>
                                <QuickFillDropdown label='TDP' checkitem={TDP} onItemClick={this.treatmentNotesQuickFillInputClickHandler} associatedinputname={TREATMENT_METHOD_NOTES} options={this.state.templateOptions[TDP]} />
                            </span>
                        </Form.Field>
                        <Form.Field className='flex-form-field'>
                            <Form.Checkbox name={LINIMENTS} methodvalue={NZRA_TREATMENT_METHOD_LIST.liniments} checked={this.state.nzraTreatmentMethods.liniments} onChange={this.nzraTreatmentMethodChangeHandler} className='TreatmentMethodCheckBox' />
                            <span className='TreatmentMethodSpan'>
                                <QuickFillDropdown label='Liniments' checkitem={LINIMENTS} onItemClick={this.treatmentNotesQuickFillInputClickHandler} associatedinputname={TREATMENT_METHOD_NOTES} options={this.state.templateOptions[LINIMENTS]} />
                            </span>
                        </Form.Field>
                        <Form.Field className='flex-form-field'>
                            <Form.Checkbox name={HERBALPLASTERS} methodvalue={NZRA_TREATMENT_METHOD_LIST.herbalPlasters} checked={this.state.nzraTreatmentMethods.herbalPlasters} onChange={this.nzraTreatmentMethodChangeHandler} className='TreatmentMethodCheckBox' />
                            <span className='TreatmentMethodSpan'>
                                <QuickFillDropdown label='Herbal Plasters' checkitem={HERBALPLASTERS} onItemClick={this.treatmentNotesQuickFillInputClickHandler} associatedinputname={TREATMENT_METHOD_NOTES} options={this.state.templateOptions[HERBALPLASTERS]} />
                            </span>
                        </Form.Field>
                    </Form.Field>
                    <Form.Field width='8'>
                        <label>Treatment Notes</label>
                        <CustomTextArea name={TREATMENT_METHOD_NOTES} rows={8} value={this.state.nzra.treatmentMethodNotes} onChange={this.inputChangeHandler} placeholder='Treatment notes' />
                    </Form.Field>
                </Form.Group>
                <Form.Group widths='equal'>
                    <Form.Field>
                        <QuickFillDropdown label='Point(s) Selected' onItemClick={this.quickFillInputClickHandler} associatedinputname={POINTS_SELECTED} options={this.state.templateOptions[POINTS_SELECTED]} />
                        <CustomTextArea name={POINTS_SELECTED} rows={4} value={this.state.nzra.pointsSelected} onChange={this.inputChangeHandler} placeholder='Point(s) selected' />
                    </Form.Field>
                    <Form.Field>
                        <QuickFillDropdown label='Advice' onItemClick={this.quickFillInputClickHandler} associatedinputname={ADVICES} options={this.state.templateOptions[ADVICES]} />
                        <CustomTextArea name={ADVICES} rows={4} value={this.state.nzra.advices} onChange={this.inputChangeHandler} placeholder='Advice for the patient' />
                    </Form.Field>
                </Form.Group>
            </React.Fragment>
        );

        return (
            <div className='TreatmentNzra'>
                {
                    this.props.inModal ?
                        <CustomLoader active={this.state.loading} size='massive' cssLoader /> :
                        <CustomLoader active={this.state.loading} size='massive' />
                }
                <ConditionalArea show={!this.state.loading}>
                    <ControlPane>
                        {this.props.onCloseInControlPane ? <Button name='Close' onClick={this.props.onCloseInControlPane} content='Close' /> : null}
                        <Button primary name='saveTreatment' onClick={() => this.saveTreatmentHandler(false)} disabled={!this.props.touched}>Save</Button>
                        <Button primary name='saveTreatmentAndClose' onClick={() => this.saveTreatmentHandler(false, true)} disabled={!this.props.touched}>Save & Close</Button>
                    </ControlPane>
                    <Form autoComplete="off">
                        {treatmentForm}
                    </Form>
                    <ControlPane>
                        {this.props.onCloseInControlPane ? <Button name='Close' onClick={this.props.onCloseInControlPane} content='Close' /> : null}
                        <Button primary name='saveTreatment' onClick={() => this.saveTreatmentHandler(false)} disabled={!this.props.touched}>Save</Button>
                        <Button primary name='saveTreatmentAndClose' onClick={() => this.saveTreatmentHandler(false, true)} disabled={!this.props.touched}>Save & Close</Button>
                    </ControlPane>
                    <ConfrimDialog content={this.state.confirmationMsg} open={this.state.showTreatmentCountAlert} onConfirm={() => this.saveTreatmentHandler(true, this.state.shouldCloseOnSave)} onCancel={this.treatmentAlertLimitCancelHandler} />
                </ConditionalArea>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        user: state.user,
        token: state.token,
        templateItems: state.templateItems
    }
}

export default connect(mapStateToProps)(TreatmentNzra);