import { useSelector } from "react-redux";
import { Message, MessageHeader } from "semantic-ui-react";

export const AppointmentReminderConfigMessage = () => {
  const user = useSelector((s : any) => s.user);
  
  return user.appointmentReminderEnabled ? (
    <Message positive>
        <MessageHeader>Appointment reminder enabled</MessageHeader>
        <p>Patient will receive email reminders about this appointment if a valid email is provided</p>
      </Message>
  ) : (
      <Message warning visible={true}>
          <MessageHeader>Appointment reminder disabled</MessageHeader>
          <p>Set up appointment reminders in Settings page if you'd like to send appointment reminders to patients</p>
      </Message>
  );
}