import React, { Component } from 'react';
import { Form, FormField, Button, Header, Input, Icon, DropdownItem, Popup, Search, Checkbox } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { DateInput } from 'semantic-ui-calendar-react-yz';
import CustomTextArea from '../../../../UI/CustomTextArea/CustomTextArea';
import ControlPane from '../../../../UI/Controls/Control/ControlPane';
import './InjuryDetail.scss';
import EnoteAxios from '../../../../axios';
import { FormatDate, GetInjuryTabID, GetTreatmentTabID, FormatDateTime, GetPatientFullNameShort, IsValidDate } from '../../../../assets/helper';
import CustomLoader from '../../../../UI/CustomLoader/CustomLoader';
import ConditionalArea from '../../../../UI/ConditionalArea/ConditionalArea';
import { ORGANISATION, CLAIM_STATUS } from '../../../../assets/constants';
import CustomReactTable, { XOverflowMode } from '../../../../UI/CustomReactTable/CustomReactTable';
import { NotificationManager } from 'react-notifications';
import InvoiceAccModal from '../Shared/InvoiceAccModal/InvoiceAccModal';
import FormHeader from '../../../../UI/FormHeader/FormHeader';
import OptionsDropdown from '../../../../UI/OptionsDropdown/OptionsDropdown';
import printJS from 'print-js';
import moment from 'moment';
import { isMobile } from 'react-device-detect';
import QuickFillDropdown from '../../../../UI/QuickFillDropdown/QuickFillDropdown';
import ConfrimDialog from '../../../../UI/ConfrimDialog/ConfirmDialog';
import currency from 'currency.js';
import _ from 'lodash';
import { createColumnHelper } from '@tanstack/table-core';

const DATE = 'date';
const ACC_NUMBER = 'accNumber';
const ACC_NUMBER_USED_COUNT = 'accNumberUsedCount';
const REASON = 'reason';
const PREV_SURGERY = 'prevSurgery';
const PREV_ILLNESSES = 'prevIllnesses';
const MEDICATION = 'medication';
const ACCIDENT_ASA = 'accidentAsa';
const INJURY_CAUSED_ASA = 'injuryCausedAsa';

const POSITION_OPTIONS = [
    { text: 'N/A', value: 0 },
    { text: 'L', value: 1 },
    { text: 'R', value: 2 },
    { text: 'L+R', value: 3 }
];

const TEMPLATE_NAME_TO_ID = {
    [PREV_SURGERY]: 62,
    [PREV_ILLNESSES]: 63,
    [MEDICATION]: 64
}

const MIN_INJURY_DATE = new Date(2000, 0, 1);
const ACC_NUMBER_REGIX_1 = new RegExp(/^[a-zA-Z]{2}[0-9]{5}$/);
const ACC_NUMBER_REGIX_2 = new RegExp(/^[0-9]{5}[a-zA-Z]{2}$/);
const ACC_NUMBER_REGIX_3 = new RegExp(/^[0-9]{4}[a-zA-Z]{3}$/);

const CUSTOM_READCODE_OPTIONS = {
    ADD_CUSTOM_CODE: 'ENOTE_RESERVED_ADD_CUSTOM_CODE',
    NO_RESULT: 'ENOTE_RESERVED_NO_RESULT',
    TOO_MANY_RESULTS: 'ENOTE_RESERVED_TOO_MANY_RESULTS',
    LOADING: 'ENOTE_RESERVED_LOADING'
}

const MAX_READ_CODE_COUNT = 50;

const columnHelper = createColumnHelper();

class InjuryDetail extends Component {
    state = {
        injury: {
            [DATE]: '',
            [ACC_NUMBER]: '',
            [ACC_NUMBER_USED_COUNT]: 0,
            [REASON]: '',
            [PREV_SURGERY]: '',
            [PREV_ILLNESSES]: '',
            [MEDICATION]: '',
            [ACCIDENT_ASA]: '',
            [INJURY_CAUSED_ASA]: '',
            readCodes: []
        },
        treatmentList: [],
        createMode: true,
        loading: true,
        loadingTreatmentTable: true,

        selectedTreatmentIds: [],
        invoiceLimitAlertDates: {},
        showInvoiceAccModal: false,

        exportLink: '',
        exportFileName: '',
        customReadCodeList: [],

        showConfirmationMsg: false,
        confirmationMsg: '',

        showAccNumberWarning: false,
        bypassAccNumberWarning: false,

        templateOptions: {
            [PREV_SURGERY]: [],
            [PREV_ILLNESSES]: [],
            [MEDICATION]: [],
        },
        readCodeList: [],
        readCodeSearchQuery: '',
        readCodeLoading: false
    }

    exportLinkRef = React.createRef();

    async componentDidMount() {
        if (this.props.data && this.props.data.injuryId && this.props.data.patient) {
            // TODO - get injury detail, read codes and its corresponding treatments
            await EnoteAxios.get('/api/injury/GetInjuryWithReadCode', {
                params: {
                    patientId: this.props.data.patient.patientId,
                    injuryId: this.props.data.injuryId
                }
            }).then(response => {
                this.setState({
                    injury: {
                        ...response.data.injuryDetail,
                        [DATE]: FormatDate(response.data.injuryDetail.date),
                        [ACC_NUMBER_USED_COUNT]: response.data.injuryDetail.accNumberUsedCount ? response.data.injuryDetail.accNumberUsedCount : 0,
                        readCodes: response.data.injuryReadCodeList
                    },
                    createMode: false
                });
            }).catch(err => {
                console.log(err);
            });

            await this.LoadTreatmentList();


        }
        this.populateTemplateItems();
        this.setState({
            loading: false,
            loadingTreatmentTable: false
        })
    }

    async componentDidUpdate(prevProps) {
        if (this.props && prevProps !== this.props) {
            if (this.props.reloadTimestamp > prevProps.reloadTimestamp) {
                await this.LoadTreatmentList();
            }
        }

        if (this.props && this.props.templateItems !== prevProps.templateItems) {
            this.populateTemplateItems();
        }
    }

    populateTemplateItems = () => {
        if (this.props.user.organisationId === ORGANISATION.NZRA) {
            let options = {};
            Object.keys(this.state.templateOptions).forEach(key => {
                let templateID = TEMPLATE_NAME_TO_ID[key]
                if (templateID) {
                    options[key] = this.props.templateItems[templateID] ? this.props.templateItems[templateID] : [];
                } else {
                    options[key] = [];
                }
            });

            this.setState({
                templateOptions: options
            });
        }
    }

    LoadTreatmentList = async () => {
        this.setState({
            loadingTreatmentTable: true
        });
        await EnoteAxios.get('/api/treatment/GetTreatmentListForInjury', {
            params: {
                patientId: this.props.data.patient.patientId,
                injuryId: this.props.data.injuryId
            }
        }).then(response => {
            this.setState({
                treatmentList: response.data,
                loadingTreatmentTable: false
            });
        }).catch(err => {
            console.log(err);
            this.setState({
                loadingTreatmentTable: false
            });
        });
    }

    inputChangeHandler = (e, { name, value }) => {
        this.setState(prevState => ({
            injury: {
                ...prevState.injury,
                [name]: value
            }
        }));
        if (!this.props.touched) {
            this.props.onTouch();
        }
    }

    accNumberChangeHanlder = (e, { name, value }) => {
        this.setState(prevState => ({
            injury: {
                ...prevState.injury,
                [name]: value.toUpperCase().trim()
            }
        }));

        if (!this.props.touched) {
            this.props.onTouch();
        }
    }

    readCodeSearchChangeHandler = async (e, data) => {
        this.setState({
            readCodeSearchQuery: data.value,
        });
        if (!data.value) {
            return;
        }
        this.setState({
            readCodeLoading: true
        });
        await this.fetchReadCodes(e, data)
    }

    fetchReadCodes = _.debounce(async (e, { value }) => {
        await EnoteAxios.get(`/api/readcode/${value}`, {
            params: {
                pageSize: MAX_READ_CODE_COUNT
            }
        }
        ).then(res => {
            if (res.data) {
                this.setState({
                    readCodeList: res.data
                })
            }
        }).catch(err => {
            this.setState({
                readCodeList: []
            })
        }).then(() => {
            this.setState({
                readCodeLoading: false
            });
        })
    }, 500)

    addReadCode = (e, data) => {
        if (data.result.title === CUSTOM_READCODE_OPTIONS.NO_RESULT
            || data.result.title === CUSTOM_READCODE_OPTIONS.TOO_MANY_RESULTS
            || data.result.title === CUSTOM_READCODE_OPTIONS.LOADING) {
            return;
        }

        let newReadCode = null;
        let currentReadCodes = this.state.injury.readCodes.slice();

        const readCodeId = data.result.id;
        let readCodeDetail = this.state.readCodeList.find(code => code.readCodeId === readCodeId);
        if (readCodeId > 0) {
            if (!readCodeDetail) {
                console.log('Invalid Read Code selected. ReadCodeId: ' + readCodeId);
                NotificationManager.error('Invalid Read Code selected', 'Failed');
                return;
            }
            newReadCode = readCodeDetail;
        } else {
            // custom read code
            newReadCode = {
                readCodeId: data.value,
                code: data.value,
                description: data.value,
                position: 0,
                times: 12,
                trigger: 12,
                injuryId: this.props.data.injuryId
            };
        }

        const duplicateCodeFound = currentReadCodes.find(code => code.readCode === newReadCode.code);
        if (duplicateCodeFound) {
            console.log('Cannot add duplicate read code. ReadCodeId: ' + readCodeId);
            NotificationManager.error('Cannot add duplicate read code', 'Failed');
            return;
        }

        if (newReadCode) {
            currentReadCodes.push({
                readCodeId: newReadCode.readCodeId,
                readCode: newReadCode.code,
                description: newReadCode.description,
                position: newReadCode.position,
                times: newReadCode.times,
                trigger: newReadCode.trigger,
                injuryId: this.props.data.injuryId
            });

            this.setState({
                injury: {
                    ...this.state.injury,
                    readCodes: currentReadCodes
                },
                readCodeSearchQuery: ''
            });
            if (!this.props.touched) {
                this.props.onTouch();
            }
        }
    }

    removeReadCode = (readCode) => {
        const newReadCodes = this.state.injury.readCodes.filter(code => code.readCode !== readCode);
        this.setState(prevState => ({
            injury: {
                ...prevState.injury,
                readCodes: newReadCodes
            }
        }));
        if (!this.props.touched) {
            this.props.onTouch();
        }
    }

    readCodeDetailChangeHandler = (e, data) => {
        const currentReadCodes = this.state.injury.readCodes.slice();

        currentReadCodes.forEach((code) => {
            if (code.readCode === data.readcode) {
                code.position = data.value;
            }
        })

        this.setState(prevState => ({
            injury: {
                ...prevState.injury,
                readCodes: currentReadCodes
            }
        }));

        if (!this.props.touched) {
            this.props.onTouch();
        }
    }

    saveInjuryHandler = async (bypassCheck = false) => {
        if (!this.state.bypassAccNumberWarning && this.state.injury.accNumber && !this.IsValidAccNumber(this.state.injury.accNumber)) {
            this.setState({
                showAccNumberWarning: true,
                confirmationMsg: `${this.state.injury.accNumber} does not appear to be a valid ACC Number. Do you still want to save it anyway?`
            });
            return;
        }

        if (!this.IsFormValid(this.state.injury)) {
            return;
        }

        if (bypassCheck) {
            this.setState({
                showConfirmationMsg: false,
                confirmationMsg: ''
            });
        }

        this.toggleLoaddingState(true);
        if (this.state.createMode) {
            if (this.props.data.patient) {
                const { readCodes, ...injury } = this.state.injury;
                const injuryWithReadCodes = {
                    injuryDetail: {
                        ...injury,
                        patientId: this.props.data.patient.patientId
                    },
                    injuryReadCodeList: readCodes
                }

                await EnoteAxios.post('/api/injury/AddInjury', injuryWithReadCodes).then(response => {
                    if (response.data && response.data.responseData) {
                        const data = {
                            injuryId: response.data.responseData,
                            injuryDate: this.state.injury.date,
                            patient: {
                                patientId: this.props.data.patient.patientId,
                                firstName: this.props.data.patient.firstName,
                                lastName: this.props.data.patient.lastName,
                            }
                        }

                        const treatmentTabsMetadataOverride = {
                            injuryDate: this.state.injury.date,
                        }
                        this.props.onTabMetadataChange(this.props.tabID, GetInjuryTabID(this.props.data.patient.patientId, data.injuryId),
                            this.getInjuryTitle(this.state.injury), 'p_' + this.props.data.patient.patientId, data, treatmentTabsMetadataOverride);
                    }
                }).catch(err => {
                    console.log(err);
                    this.toggleLoaddingState(false);
                })
            }
        } else {
            if (this.props.data.patient) {
                const { readCodes, ...injury } = this.state.injury;
                const injuryWithReadCodes = {
                    injuryDetail: {
                        ...injury,
                        patientId: this.props.data.patient.patientId
                    },
                    injuryReadCodeList: readCodes
                }

                await EnoteAxios.put('/api/injury/EditInjury', injuryWithReadCodes, {
                    params: {
                        bypassCheck: bypassCheck
                    }
                }).then(response => {
                    if (response.data && response.data.responseData) {
                        const injuryId = response.data.responseData;
                        this.setState({
                            createMode: false,
                            selectedTreatmentIds: [],
                            showConfirmationMsg: false,
                            confirmationMsg: ''
                        });
                        const data = {
                            injuryId: injuryId,
                            injuryDate: this.state.injury.date,
                            patient: {
                                patientId: this.props.data.patient.patientId,
                                firstName: this.props.data.patient.firstName,
                                lastName: this.props.data.patient.lastName,
                            }
                        }
                        const treatmentTabsMetadataOverride = {
                            injuryDate: this.state.injury.date,
                        }
                        this.props.onTabMetadataChange(this.props.tabID, GetInjuryTabID(this.props.data.patient.patientId, this.props.data.injuryId),
                            this.getInjuryTitle(this.state.injury), 'p_' + this.props.data.patient.patientId, data, treatmentTabsMetadataOverride);
                    } else {
                        this.setState({
                            createMode: false,
                            selectedTreatmentIds: [],
                        });
                    }

                    this.setState({
                        bypassAccNumberWarning: false,
                        showAccNumberWarning: false
                    })
                }).catch(err => {
                    console.log(err);
                    if (err && err.response && err.response.status === 422 && err.response && err.response.data && err.response.data.confirmation) {
                        this.setState({
                            showConfirmationMsg: true,
                            confirmationMsg: err.response.data.confirmation,
                        });
                    } else {
                        this.setState({
                            showConfirmationMsg: false,
                            confirmationMsg: ''
                        });
                    }
                }).then(() => {
                    this.setState({
                        loading: false,
                    })
                });
            }
        }
    }

    IsValidAccNumber = (accNumber) => {
        return ACC_NUMBER_REGIX_1.test(accNumber) 
            || ACC_NUMBER_REGIX_2.test(accNumber)
            || ACC_NUMBER_REGIX_3.test(accNumber)
    }

    IsFormValid(injury) {
        let errorMessage = '';
        let isValid = true;

        if (!IsValidDate(injury.date, true) || moment(injury.date).isAfter(moment(), 'days')) {
            errorMessage += 'Injury date cannot be in the future';
        }

        if (injury.date && moment(injury.date).isBefore(MIN_INJURY_DATE, 'year')) {
            errorMessage += "Injury date cannot be earlier than 2000-01-01";
        }

        if (errorMessage.length > 0) {
            NotificationManager.error(errorMessage, 'Failed');
            isValid = false;
        }

        return isValid;
    }

    toggleLoaddingState = (isLoading) => {
        this.setState({
            loading: isLoading
        });
    }

    getPatientName = () => {
        return GetPatientFullNameShort(this.props.data.patient.firstName, this.props.data.patient.lastName);
    }

    getTreatmentTitle = (treatment) => {
        return 'Treatment ' + treatment.treatmentIndex + ': ' + this.getPatientName() + ' ' + FormatDate(treatment.date);
    }

    getTreatmentUID(treatment) {
        return treatment.injuryId + '_' + treatment.treatmentIndex;
    }

    getInjuryTitle = (injury) => {
        return this.getPatientName() + ' Injury: ' + (injury.date ? moment(injury.date).format('YYYY-MM-DD') : '');
    }

    onRowClick = (e, rowInfo) => {
        if (e.target.type === 'checkbox') {
            // Touched checkboxes, so do not open new tabs here.
            return;
        }
        const treatment = rowInfo.original;
        this.props.onTabAdd(GetTreatmentTabID(this.props.data.patient.patientId, this.props.data.injuryId, treatment.treatmentIndex), this.getTreatmentTitle(treatment), 'TreatmentDetail', {
            treatmentId: treatment.treatmentId,
            injuryId: this.props.data.injuryId,
            treatmentIndex: treatment.treatmentIndex,
            injuryDate: this.state.injury.date,
            patient: this.props.data.patient
        });
    }

    toggleTreatmentSelection = (key, shift, row) => {
        let currSelection = this.state.selectedTreatmentIds.slice();

        if (currSelection.includes(row.treatmentId)) {
            currSelection = currSelection.filter(x => x !== row.treatmentId);
        } else {
            currSelection.push(row.treatmentId);
        }

        this.setState({
            selectedTreatmentIds: currSelection
        });
    }

    toggleAllTreatmentSelection = () => {
        const currSelection = this.state.selectedTreatmentIds.slice();
        const allTreatments = this.state.treatmentList.slice();
        if (currSelection.length < allTreatments.length) {
            this.setState({
                selectedTreatmentIds: allTreatments.map(v => v.treatmentId)
            });
        } else {
            this.setState({
                selectedTreatmentIds: []
            });
        }
    }

    isSelectAll = () => {
        const currSelection = this.state.selectedTreatmentIds.slice();
        const allTreatments = this.state.treatmentList.slice();

        return allTreatments.length > 0 && currSelection.length === allTreatments.length;
    }

    invoiceClickHandler = () => {
        this.setState({
            showInvoiceAccModal: true
        });
    }

    invoiceAccCompleteHandler = async () => {
        await this.LoadTreatmentList();

        this.setState({
            selectedTreatmentIds: [],
            showInvoiceAccModal: false
        });
    }

    toggleInvoiceModal = (show) => {
        this.setState({
            showInvoiceAccModal: show
        });
    }

    exportInjuryDetail = async (print) => {
        NotificationManager.info('File will be exported shortly...', 'Preparing document', 3000);
        await EnoteAxios.get('/api/report/ExportInjuryDetail', {
            params: {
                patientId: this.props.data.patient.patientId,
                injuryId: this.props.data.injuryId
            },
            responseType: 'blob'
        }).then(response => {
            // Use application/octet-stream here so that the file can be downloaded on iOS. Otherwise the pdf will be opened in the current tab (as of iOS 13)
            let blob = new Blob([response.data], { type: 'application/octet-stream' });
            let link = window.URL.createObjectURL(blob);
            if (!print) {
                this.setState({
                    exportLink: link,
                    exportFileName: this.props.data.patient.firstName + ' ' + this.props.data.patient.lastName + ' ' + FormatDate(this.state.injury.date) + '.pdf'
                }, () => {
                    NotificationManager.success('File exported successfully', 'Success', 3000, null, true);
                    this.exportLinkRef.current.click();
                });
            } else {
                printJS(link);
            }
        }).catch(err => {
            console.log(err);
            NotificationManager.error('Failed to export file', 'Failed');
        });
    }

    exportTreatmentNotesToFile = () => {
        let outputText = '';
        this.state.treatmentList.forEach(t => {
            outputText = outputText.concat(`${t.treatmentIndex}. Treatment Date: ${FormatDate(t.date)}\r\n${t.detail}\r\n\r\n\r\n`);
        });
        /*if (isIOS || isSafari) {
            this.downloadFile(outputText, 'Treatment_Notes.rtf', 'application/rtf');
        }
        else {
            this.downloadFile(outputText, 'Treatment_Notes.txt', 'text/plain');
        }*/
        this.downloadFile(outputText, 'Treatment_Notes.txt', 'text/plain');
    }

    downloadFile = (data, fileName, type) => {
        let blob = new Blob([data], { type: type });
        let link = window.URL.createObjectURL(blob);
        this.setState({
            exportLink: link,
            exportFileName: fileName
        });
        setTimeout(() => {
            if (isMobile) {
                window.open(link);
            } else {
                this.exportLinkRef.current.click();
            }
        }, 1000);
        NotificationManager.success('File will be downloaded shortly...', 'Success');
    }

    quickFillInputClickHandler = (e, data) => {
        let newData = this.state.injury[data.associatedinputname].length > 0 ? this.state.injury[data.associatedinputname] + '\n' + data.itemcontent : data.itemcontent;

        this.setState(prevState => ({
            injury: {
                ...prevState.injury,
                [data.associatedinputname]: newData
            }
        }));

        if (!this.props.touched && this.props.onTouch) {
            this.props.onTouch();
        }
    }

    onAccNumberWarningResponse = (bypassWarning) => {
        this.setState({
            bypassAccNumberWarning: bypassWarning,
            showAccNumberWarning: false
        }, () => {
            if (bypassWarning) {
                this.saveInjuryHandler(false);
            }
        })
    }

    onTreatmentInfoCopy = (e, treatmentId, field) => {
        e.preventDefault();
        e.stopPropagation();
        const treatment = this.state.treatmentList.find(x => x.treatmentId === treatmentId);
        if (treatment) {
            navigator.clipboard.writeText(treatment[field])
        }
    }

    getReadCodeOptions = () => {
        let readCodeList = [];
        if (this.state.readCodeList.length === 0) {
            if (this.state.readCodeLoading) {
                readCodeList = [{
                    title: CUSTOM_READCODE_OPTIONS.LOADING
                }];
            } else {
                readCodeList = [{
                    title: CUSTOM_READCODE_OPTIONS.NO_RESULT
                }];
            }
        } else {
            readCodeList = this.state.readCodeList.map(readCode => {
                return {
                    id: readCode.readCodeId,
                    description: readCode.description,
                    title: readCode.code
                }
            });

            if (this.state.readCodeList.length >= MAX_READ_CODE_COUNT) {
                readCodeList.push({
                    title: CUSTOM_READCODE_OPTIONS.TOO_MANY_RESULTS
                });
            }
        }

        if (!this.state.readCodeSearchQuery
            || !readCodeList.find(x => x.title.toLowerCase() === this.state.readCodeSearchQuery.toLowerCase())) {

            readCodeList.push({
                id: -1,
                title: CUSTOM_READCODE_OPTIONS.ADD_CUSTOM_CODE,
            });
        }

        return readCodeList;
    }

    readCodeResultRenderer = (result) => {
        switch (result.title) {
            case CUSTOM_READCODE_OPTIONS.ADD_CUSTOM_CODE:
                return (
                    <React.Fragment>
                        <div className='readcode-search-result add-custom-code'>Add custom read code: <span>{this.state.readCodeSearchQuery}</span></div>
                    </React.Fragment>
                );
            case CUSTOM_READCODE_OPTIONS.NO_RESULT:
                return (
                    <React.Fragment>
                        <div className='readcode-search-result no-result'>No matching read code found</div>
                    </React.Fragment>
                );
            case CUSTOM_READCODE_OPTIONS.TOO_MANY_RESULTS:
                return (
                    <React.Fragment>
                        <div className='readcode-search-result too-many-results'>Only the first {MAX_READ_CODE_COUNT} results are displayed, refine your search to see more read codes</div>
                    </React.Fragment>
                );
            case CUSTOM_READCODE_OPTIONS.LOADING:
                return (
                    <React.Fragment>
                        <div className='readcode-search-result loading'>Loading...</div>
                    </React.Fragment>
                );
            default:
                return (
                    <React.Fragment>
                        <div className='readcode-search-result read-code-result'>{result.title}</div>
                        <div>{result.description}</div>
                    </React.Fragment>
                );
        }
    }

    onTreatmentRowSelectionChange = (treatmentIdToToggle) => {
        let selectedTreatmentIds = this.state.selectedTreatmentIds.slice();
        const isSelected = selectedTreatmentIds.includes(treatmentIdToToggle);
        if (isSelected) {
            selectedTreatmentIds = selectedTreatmentIds.filter(x => x !== treatmentIdToToggle)
        } else {
            selectedTreatmentIds.push(treatmentIdToToggle)
        }
        this.setState({
            selectedTreatmentIds: selectedTreatmentIds
        });
    }

    onAllTreatmentRowSelectionChange = () => {
        if (this.areAllTreatmentsSelected()) {
            this.setState({
                selectedTreatmentIds: []
            });
        } else {
            this.setState(prevState => ({
                selectedTreatmentIds: prevState.treatmentList.map(v => v.treatmentId)
            }));
        }
    }

    areAllTreatmentsSelected = () => {
        return this.state.selectedTreatmentIds.length === this.state.treatmentList.length;
    }

    isSomeTreatmentSelected = () => {
        const selectedTreatmentsCount = this.state.selectedTreatmentIds.length;
        return selectedTreatmentsCount > 0 && selectedTreatmentsCount < this.state.treatmentList.length;
    }

    isTreatmentSelected = (treatmentId) => {
        return this.state.selectedTreatmentIds.includes(treatmentId);
    }

    treatmentNzraColumns = [
        columnHelper.accessor(c => c, {
            id: '_select',
            header: () => (
                <Checkbox
                    checked={this.areAllTreatmentsSelected()}
                    indeterminate={this.isSomeTreatmentSelected()}
                    onChange={this.onAllTreatmentRowSelectionChange}
                />
            ),
            cell: ({ row }) => (
                <Checkbox
                    key={row.original.treatmentId}
                    checked={this.isTreatmentSelected(row.original.treatmentId)}
                    onChange={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        this.onTreatmentRowSelectionChange(row.original.treatmentId);
                    }}
                />
            ),
            enableSorting: false,
        }),
        columnHelper.accessor(row => row.treatmentIndex, {
            id: 'treatmentIndex',
            header: 'Times',
        }),
        columnHelper.accessor(row => FormatDate(row.date), {
            id: 'date',
            header: 'Treatment Date',
        }),
        columnHelper.accessor(row => CLAIM_STATUS[row.claimStatus], {
            id: 'claimStatus',
            header: 'Claim Status',
        }),
        columnHelper.accessor(row => FormatDateTime(row.claimDateTime), {
            id: 'claimTime',
            header: 'Last Claim Time',
        }),
        columnHelper.accessor(row => row.expectedDuration, {
            id: 'duration',
            header: 'Duration',
        }),
        columnHelper.accessor(row => currency(row.fee, { symbol: '' }).format(), {
            id: 'fee',
            header: 'Fee',
        }),
        columnHelper.accessor(r => r.detail, {
            id: 'detail',
            header: 'Detail',
            cell: ({ row }) => {
                let croppedDetail = row.original.detail;
                if (croppedDetail && croppedDetail.length > 50) {
                    croppedDetail = croppedDetail.substring(0, 50) + '...';
                };
                return <div className='no-wrap' title={row.original.detail}>{croppedDetail}</div>
            }
        }),
        columnHelper.accessor(r => r, {
            id: 'rom',
            header: 'Range Of Movement',
            cell: ({ row }) => {
                let croppedRom = row.original.rangeOfMovement;
                if (croppedRom && croppedRom.length > 50) {
                    croppedRom = croppedRom.substring(0, 50) + '...';
                };
                return (
                    <span className='no-wrap' title={row.original.rangeOfMovement}>
                        {croppedRom && <Popup content='Copy content' position='top center' trigger={<Icon name='copy' onClick={(e) => this.onTreatmentInfoCopy(e, row.original.treatmentId, 'rangeOfMovement')} />} />}
                        {croppedRom}
                    </span>
                )
            }
        }),
    ];

    treatmentNzasaColumns = [
        columnHelper.accessor(c => c, {
            id: '_select',
            header: () => (
                <Checkbox
                    checked={this.areAllTreatmentsSelected()}
                    indeterminate={this.isSomeTreatmentSelected()}
                    onChange={this.onAllTreatmentRowSelectionChange}
                />
            ),
            cell: ({ row }) => (
                <Checkbox
                    key={row.original.treatmentId}
                    checked={this.isTreatmentSelected(row.original.treatmentId)}
                    onChange={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        this.onTreatmentRowSelectionChange(row.original.treatmentId);
                    }}
                />
            ),
            enableSorting: false,
        }),
        columnHelper.accessor(row => row.treatmentIndex, {
            id: 'treatmentIndex',
            header: 'Times',
        }),
        columnHelper.accessor(row => FormatDate(row.date), {
            id: 'date',
            header: 'Treatment Date',
        }),
        columnHelper.accessor(row => CLAIM_STATUS[row.claimStatus], {
            id: 'claimStatus',
            header: 'Claim Status',
        }),
        columnHelper.accessor(row => FormatDateTime(row.claimDateTime), {
            id: 'claimTime',
            header: 'Last Claim Time',
        }),
        columnHelper.accessor(row => row.expectedDuration, {
            id: 'duration',
            header: 'Duration',
        }),
        columnHelper.accessor(row => currency(row.fee, { symbol: '' }).format(), {
            id: 'fee',
            header: 'Fee',
        }),
        columnHelper.accessor(r => r.detail, {
            id: 'detail',
            header: 'Detail',
            cell: ({ row }) => {
                let croppedDetail = row.original.detail;
                if (croppedDetail && croppedDetail.length > 50) {
                    croppedDetail = croppedDetail.substring(0, 50) + '...';
                };
                return <div className='no-wrap' title={row.original.detail}>{croppedDetail}</div>
            }
        }),
        columnHelper.accessor(r => r, {
            id: 'physicalFindings',
            header: 'Physical Findings',
            cell: ({ row }) => {
                let croppedPhysicalFindings = row.original.physicalFindings;
                if (croppedPhysicalFindings && croppedPhysicalFindings.length > 50) {
                    croppedPhysicalFindings = croppedPhysicalFindings.substring(0, 50) + '...';
                };
                return (
                    <span className='no-wrap' title={row.original.physicalFindings}>
                        {croppedPhysicalFindings && <Popup content='Copy content' position='top center' trigger={<Icon name='copy' onClick={(e) => this.onTreatmentInfoCopy(e, row.original.treatmentId, 'physicalFindings')} />} />}
                        {croppedPhysicalFindings}
                    </span>
                )
            }
        }),
        columnHelper.accessor(r => r, {
            id: 'rom',
            header: 'Range Of Movement',
            cell: ({ row }) => {
                let croppedRom = row.original.rangeOfMovement;
                if (croppedRom && croppedRom.length > 50) {
                    croppedRom = croppedRom.substring(0, 50) + '...';
                };
                return (
                    <span className='no-wrap' title={row.original.rangeOfMovement}>
                        {croppedRom && <Popup content='Copy content' position='top center' trigger={<Icon name='copy' onClick={(e) => this.onTreatmentInfoCopy(e, row.original.treatmentId, 'rangeOfMovement')} />} />}
                        {croppedRom}
                    </span>
                )
            }
        }),
    ];

    readCodeColumns = [
        columnHelper.accessor(r => r.readCode, {
            id: 'readCode',
            header: 'Read Code',
        }),
        columnHelper.accessor(r => r.position, {
            id: 'position',
            header: 'Position',
            cell: ({ row }) => (
                <Form.Dropdown width='1' selection readcode={row.original.readCode} options={POSITION_OPTIONS} name='readCodePosition' value={row.original.position} onChange={this.readCodeDetailChangeHandler} />
            )
        }),
        columnHelper.accessor(r => r.times, {
            id: 'times',
            header: 'Times',
        }),
        columnHelper.accessor(r => r.trigger, {
            id: 'trigger',
            header: 'Trigger',
        }),
        columnHelper.accessor(r => r.description, {
            id: 'description',
            header: 'Diagnosis',
        }),
        columnHelper.accessor(r => r, {
            id: 'options',
            header: 'Options',
            cell: ({ row }) => (
                <span className='RemoveReadCodeIcon'><Icon onClick={() => this.removeReadCode(row.original.readCode)} name='close' /></span>
            )
        }),
    ]

    renderReadCodeSection = () => {
        return (
            <>
                <Form.Group widths='16'>
                    <FormField width='8'>
                        <label>New Read Code</label>
                        <Search
                            className='readcode-search-input'
                            loading={this.state.readCodeLoading}
                            placeholder='Search read codes...'
                            onResultSelect={this.addReadCode}
                            onSearchChange={this.readCodeSearchChangeHandler}
                            results={this.getReadCodeOptions()}
                            resultRenderer={this.readCodeResultRenderer}
                            value={this.state.readCodeSearchQuery}
                        />
                    </FormField>
                </Form.Group>
                <Form.Group widths='16'>
                    <FormField width='16'>
                        <CustomReactTable
                            columns={this.readCodeColumns}
                            data={this.state.injury.readCodes}
                            noDataText='No read code added'
                            clickable
                            fixedStyle={false}
                            xOverflowMode={XOverflowMode.Stack}
                        />
                    </FormField>
                </Form.Group>
            </>
        );
    }

    render() {
        let content = null;
        const treatmentColumn = this.props.user.organisationId === ORGANISATION.NZASA ? this.treatmentNzasaColumns : this.treatmentNzraColumns;

        if (this.props.user.organisationId === ORGANISATION.NZRA) {
            content = (
                <React.Fragment>
                    <FormHeader dividing trimtop>Injury Detail</FormHeader>
                    <Form.Group widths='equal'>
                        <FormField>
                            <DateInput
                                label='Date of Injury'
                                dateFormat='YYYY-MM-DD'
                                name={DATE}
                                placeholder='YYYY-MM-DD'
                                value={this.state.injury.date}
                                iconPosition="left"
                                closable
                                onChange={this.inputChangeHandler}
                                minDate={MIN_INJURY_DATE}
                                maxDate={moment()}
                                hideMobileKeyboard
                                title='Select the injury date. The injury date cannot be in the future.'
                                animation='none'
                                error={!IsValidDate(this.state.injury.date, true)}
                                popupPosition={'bottom left'}
                            />
                        </FormField>
                        <FormField>
                            <Form.Input label='ACC Number' type='text' name={ACC_NUMBER} value={this.state.injury.accNumber} onChange={this.accNumberChangeHanlder} placeholder='Acc Number' />
                        </FormField>
                        <FormField>
                            <label>Previous treatments with this ACC Number</label>
                            <Input label={{ basic: true, content: 'time(s)' }} labelPosition='right' type='number' name={ACC_NUMBER_USED_COUNT} value={this.state.injury.accNumberUsedCount} onChange={this.inputChangeHandler} />
                        </FormField>
                    </Form.Group>
                    <Form.Group widths='equal'>
                        <FormField>
                            <label>Visit Reason</label>
                            <CustomTextArea rows={5} name={REASON} value={this.state.injury.reason} onChange={this.inputChangeHandler} placeholder='Visit reasons' />
                        </FormField>
                        <FormField>
                            <QuickFillDropdown label='Previous Surgery or Fractures' onItemClick={this.quickFillInputClickHandler} associatedinputname={PREV_SURGERY} options={this.state.templateOptions[PREV_SURGERY]} />
                            <CustomTextArea rows={5} name={PREV_SURGERY} value={this.state.injury.prevSurgery} onChange={this.inputChangeHandler} placeholder='N/A' />
                        </FormField>
                    </Form.Group>

                    <Form.Group widths='equal'>
                        <FormField>
                            <QuickFillDropdown label='Previous Illnesses' onItemClick={this.quickFillInputClickHandler} associatedinputname={PREV_ILLNESSES} options={this.state.templateOptions[PREV_ILLNESSES]} />
                            <CustomTextArea rows={5} name={PREV_ILLNESSES} value={this.state.injury.prevIllnesses} onChange={this.inputChangeHandler} placeholder='N/A' />
                        </FormField>
                        <FormField>
                            <QuickFillDropdown label='Medication and/or Supplements' onItemClick={this.quickFillInputClickHandler} associatedinputname={MEDICATION} options={this.state.templateOptions[MEDICATION]} />
                            <CustomTextArea rows={5} name={MEDICATION} value={this.state.injury.medication} onChange={this.inputChangeHandler} placeholder='N/A' />
                        </FormField>
                    </Form.Group>
                    {this.renderReadCodeSection()}
                </React.Fragment>
            );
        }
        else if (this.props.user.organisationId === ORGANISATION.NZASA) {
            content = (
                <React.Fragment>
                    <FormHeader dividing trimtop>Injury Detail</FormHeader>
                    <Form.Group widths='equal'>
                        <FormField>
                            <DateInput
                                label='Date of Injury'
                                dateFormat='YYYY-MM-DD'
                                name={DATE}
                                placeholder='YYYY-MM-DD'
                                value={this.state.injury.date}
                                iconPosition="left"
                                closable
                                onChange={this.inputChangeHandler}
                                maxDate={moment()}
                                hideMobileKeyboard
                                title='Select the injury date. The injury date cannot be in the future.'
                                animation='none'
                                error={!IsValidDate(this.state.injury.date, true)}
                                popupPosition={'bottom left'}
                            />
                        </FormField>
                        <FormField>
                            <Form.Input label='ACC Number' type='text' name={ACC_NUMBER} value={this.state.injury.accNumber} onChange={this.accNumberChangeHanlder} placeholder='Acc Number' />
                        </FormField>
                        <FormField>
                            <label>Previous treatments with this ACC Number</label>
                            <Input label={{ basic: true, content: 'time(s)' }} labelPosition='right' type='number' name={ACC_NUMBER_USED_COUNT} value={this.state.injury.accNumberUsedCount} onChange={this.inputChangeHandler} />
                        </FormField>
                    </Form.Group>
                    <Form.Group widths='equal'>
                        <FormField>
                            <label>Presenting Injury/Accident</label>
                            <CustomTextArea name={ACCIDENT_ASA} value={this.state.injury.accidentAsa} onChange={this.inputChangeHandler} placeholder='Presenting injury/accident' />
                        </FormField>
                        <FormField>
                            <label>Injury Caused by Accident</label>
                            <CustomTextArea name={INJURY_CAUSED_ASA} value={this.state.injury.injuryCausedAsa} onChange={this.inputChangeHandler} placeholder="What's the personal injury caused by the accident" />
                        </FormField>
                    </Form.Group>
                    {this.renderReadCodeSection()}
                </React.Fragment>
            );
        }
        let invoiceAccModal = null;
        if (this.state.showInvoiceAccModal) {
            const treatmentsToInvoice = this.state.treatmentList.filter(x => this.isTreatmentSelected(x.treatmentId));
            invoiceAccModal = (
                <InvoiceAccModal
                    show={true}
                    treatments={treatmentsToInvoice}
                    patientId={this.props.data.patient.patientId}
                    injuryId={this.props.data.injuryId}
                    onComplete={this.invoiceAccCompleteHandler}
                    onAbort={() => this.toggleInvoiceModal(false)}
                />
            );
        }

        return (
            <div className='InjuryDetail'>
                {
                    this.props.inModal ?
                        <CustomLoader active={this.state.loading} size='massive' cssLoader /> :
                        <CustomLoader active={this.state.loading} size='massive' />
                }
                <ConditionalArea show={!this.state.loading}>
                    <ControlPane>
                        <Button primary name='saveInjury' onClick={() => this.saveInjuryHandler(false)} disabled={!this.props.touched}>Save</Button>
                        <Button primary
                            name='createTreatment'
                            onClick={() => this.props.onTabAdd(GetTreatmentTabID(this.props.data.patient.patientId, this.props.data.injuryId, 0), 'New Treatment: ' + this.getPatientName(), 'TreatmentDetail', {
                                treatmentId: 0,
                                injuryId: this.props.data.injuryId,
                                treatmentIndex: 0,
                                injuryDate: this.state.injury.date,
                                patient: this.props.data.patient
                            })}
                            disabled={this.state.createMode}>
                            New Treatment
                        </Button>
                        <OptionsDropdown>
                            <DropdownItem title='Save injury and treatment information as PDF' onClick={() => this.exportInjuryDetail(false)} disabled={this.state.createMode}>Save as PDF</DropdownItem>
                            {
                                isMobile ? null :
                                    <DropdownItem title='Print injury and treatment information' onClick={() => this.exportInjuryDetail(true)} disabled={this.state.createMode}>Print</DropdownItem>
                            }
                            <DropdownItem title='Open ACC website in a new window to apply for ACC32 Treatment Extension' target='_blank' href='https://forms.acc.co.nz/ACC32/index.htm' disabled={this.state.createMode}>Apply for ACC32</DropdownItem>
                            <DropdownItem title='Save treatment notes to a file' onClick={this.exportTreatmentNotesToFile} disabled={this.state.createMode}>List Treatment Process</DropdownItem>
                        </OptionsDropdown>
                    </ControlPane>
                    <Form autoComplete="off">
                        {content}
                        {
                            this.props.data.injuryId && (
                                <div>
                                    <Header as='h3' dividing>Treatment History</Header>
                                    <ControlPane align='left'>
                                        <Button primary content='Submit Invoice' onClick={this.invoiceClickHandler} disabled={this.state.selectedTreatmentIds.length === 0 || this.state.createMode || this.props.touched} />
                                    </ControlPane>
                                    <div className='TreatmentTable'>
                                        <CustomReactTable
                                            columns={treatmentColumn}
                                            data={this.state.treatmentList}
                                            onRowClick={this.onRowClick}
                                            noDataText='No treatment found'
                                            loading={this.state.loadingTreatmentTable}
                                            clickable
                                            fixedStyle={false}
                                            xOverflowMode={XOverflowMode.Scroll}
                                        />
                                    </div>
                                </div>
                            )
                        }
                    </Form>
                    <ControlPane>
                        <Button primary name='saveInjury' onClick={() => this.saveInjuryHandler(false)} disabled={!this.props.touched}>Save</Button>
                    </ControlPane>
                </ConditionalArea>
                {invoiceAccModal}
                <a className='HiddenLink' target='_blank' rel="noopener noreferrer" href={this.state.exportLink} download={this.state.exportFileName} ref={this.exportLinkRef}> </a>
                <ConfrimDialog open={this.state.showConfirmationMsg} header='Warning' content={this.state.confirmationMsg} onConfirm={() => this.saveInjuryHandler(true)} onCancel={() => this.setState({ showConfirmationMsg: false })} />
                <ConfrimDialog open={this.state.showAccNumberWarning} header='ACC Number Format' content={this.state.confirmationMsg} onConfirm={() => this.onAccNumberWarningResponse(true)} onCancel={() => this.onAccNumberWarningResponse(false)} />
            </div>
        )
    }
}



const mapStateToProps = state => {
    return {
        user: state.user,
        token: state.token,
        templateItems: state.templateItems
    }
}

export default connect(mapStateToProps)(InjuryDetail);