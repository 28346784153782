import React from 'react';
import './TabHeaderSection.css';
import { Dropdown } from 'semantic-ui-react';
import { isMobileOnly } from 'react-device-detect';

const TabHeaderSection = (props) => {
    let content = null;
    if (isMobileOnly) {
        let activeTabHeader = '';
        content = props.children.map(header => {
            if (header.props.active) {
                activeTabHeader = header.props.title;
            }
            
            return <Dropdown.Item key={header.key}>{header}</Dropdown.Item>
        });
        content = (
            <Dropdown text={activeTabHeader} button>
                <Dropdown.Menu>
                    {content}
                </Dropdown.Menu>
            </Dropdown>
        );
    } else {
        content = props.children;
    }
    return (
        <ul className='TabHeaderSection'>
            {content}
        </ul>
    )
}

export default TabHeaderSection;