import React, { Component } from 'react';
import { connect } from 'react-redux';
import './TreatmentDetail.css';
import { ORGANISATION } from '../../../../assets/constants';
import TreatmentNzra from './TreatmentNzra/TreatmentNzra';
import TreatmentNzasa from './TreatmentNzasa/TreatmentNzasa';

class TreatmentDetail extends Component {

    render() {
        let content = null;
        if (this.props.user.organisationId === ORGANISATION.NZRA) {
            content = <TreatmentNzra {...this.props} />;
        } else {
            content = <TreatmentNzasa {...this.props} />;
        }

        return (
            <div className='TreatmentDetail'>
                {content}
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        user: state.user,
        token: state.token
    }
}

export default connect(mapStateToProps)(TreatmentDetail);
