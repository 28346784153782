import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import './ReportingPane.css';
import ActivitySummary from './Reports/ActivitySummary/ActivitySummary';
import ControlPane from '../../../UI/Controls/Control/ControlPane';
import { Button } from 'semantic-ui-react';
import IncomeSummary from './Reports/IncomeSummary/IncomeSummary';
import { Route, Link, withRouter } from 'react-router-dom';

const ACTIVITY_SUMMARY = 0;
const INCOME_SUMMARY = 1;

function ReportingPane(props) {
	const [currentReport, setCurrentReport] = useState(ACTIVITY_SUMMARY);

	useEffect(() => {
		if (props.match) {
			let url = props.location.pathname.toLowerCase();
			if (url.indexOf('activity-summary') === -1 && url.indexOf('invoice-submission-summary') === -1) {
				switch (currentReport) {
					case INCOME_SUMMARY:
						props.history.push("/board/reporting/invoice-submission-summary");
						break;
					case ACTIVITY_SUMMARY:
					default:
						props.history.push("/board/reporting/activity-summary");
						break;
				}
			}

		}
	}, [props.location, props.history, props.match, currentReport]);

	const getBaseUrl = () => {
		if (props.match) {
			return props.match.url;
		}
		return '';
	}

	return (
		<div className={'ReportingPane ContentMainPane ' + (props.match ? '' : 'css-hidden')}>
			<ControlPane align='left' padBottom='20px'>
				<Button as={Link} to={getBaseUrl() + '/activity-summary'} name='activitySummary' content='Activity Summary' primary={props.location.pathname.toLowerCase().indexOf('/board/reporting/activity-summary') > -1} onClick={() => setCurrentReport(ACTIVITY_SUMMARY)} />
				<Button as={Link} to={getBaseUrl() + '/invoice-submission-summary'} name='invoiceSubmissionSummary' content='Invoice Submission Summary' primary={props.location.pathname.toLowerCase().indexOf('/board/reporting/invoice-submission-summary') > -1} onClick={() => setCurrentReport(INCOME_SUMMARY)} />
			</ControlPane>
			<Route path={getBaseUrl() + '/activity-summary'} children={() => <ActivitySummary />} />
			<Route path={getBaseUrl() + '/invoice-submission-summary'} children={() => <IncomeSummary />} />
		</div>
	)
}

const mapStateToProps = (state) => {
	return {
		user: state.user
	};
}

export default withRouter(connect(mapStateToProps)(ReportingPane));