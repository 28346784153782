import React, { Component } from 'react';
import { TITLE_OPTIONS } from '../../../../assets/constants';
import { DateInput, DateTimeInput } from 'semantic-ui-calendar-react-yz';
import CustomTextArea from '../../../../UI/CustomTextArea/CustomTextArea';
import { Button, Form, Grid, Header, Message, Search } from 'semantic-ui-react';
import { connect } from 'react-redux';
import ControlPane from '../../../../UI/Controls/Control/ControlPane';
import EnoteAxios from '../../../../axios';
import { FormatDate, FormatDateTime, GetPatientFullName, IsValidDateTime, IsValidDate } from '../../../../assets/helper';
import './ScheduleAppointment.css';
import CustomLoader from '../../../../UI/CustomLoader/CustomLoader';
import ConditionalArea from '../../../../UI/ConditionalArea/ConditionalArea';
import _ from 'lodash';
import ConfrimDialog from '../../../../UI/ConfrimDialog/ConfirmDialog';
import { NotificationManager } from 'react-notifications';
import { AppointmentReminderConfigMessage } from '../AppointmentReminderConfigMessage/AppointmentReminderConfigMessage';

var moment = require('moment');

const FIRST_NAME = 'patientFirstName';
const LAST_NAME = 'patientLastName';
const TITLE = 'patientTitle';
const DOB = 'patientDob';
const EMAIL = 'patientEmail';
const MOBILE_PHONE = 'patientPhone';
const START_DATE_TIME = 'startDateTime';
const STOP_DATE_TIME = 'stopDateTime';
const COMMENT = 'comment';

const patientResultRenderer = ({ fullname, dob }) => (
    <React.Fragment>
        <Header size='small' className='AppointmentPatientResultName'>{fullname}</Header>
        <p className='AppointmentPatientResultDob'>{dob}</p>
    </React.Fragment>
)

class ScheduleAppointment extends Component {
    state = {
        patientSearchValue: '',
        selectedPatientId: 0,
        selectedPatient: {
            [TITLE]: '',
            [FIRST_NAME]: '',
            [LAST_NAME]: '',
            [DOB]: '',
            [MOBILE_PHONE]: '',
            [EMAIL]: ''
        },
        appointment: {
            [START_DATE_TIME]: '',
            [STOP_DATE_TIME]: '',
            [COMMENT]: ''
        },
        loading: true,
        loadingMatchPatient: false,
        showMatchPatient: false,
        saving: false,
        showConfirmationMsg: false,
        showDuplicateConfirmationMsg: false,
        confirmationMsg: '',
        patientList: [],
        bypassDuplicatePatient: false,
        showDeleteWarning: false
    }

    async componentDidMount() {
        if (this.props.selectedAppointmentID) {
            await this.loadAppointmentDetail(this.props.selectedAppointmentID);
        }

        if (!this.props.selectedAppointmentID && this.props.appointmentTemplate && this.props.appointmentTemplate.startDateTime && this.props.appointmentTemplate.stopDateTime) {
            // The scheduling view is opened via a click on a calendar slot, so we need to pre-fill the date.
            this.setState(prevState => ({
                appointment: {
                    ...prevState.appointment,
                    [START_DATE_TIME]: this.props.appointmentTemplate.startDateTime,
                    [STOP_DATE_TIME]: this.props.appointmentTemplate.stopDateTime
                }
            }));
        }

        this.setState({
            loading: false
        });
    }

    loadAppointmentDetail = async (appointmentID) => {
        this.setState({
            loading: true
        });

        return await EnoteAxios.get('/api/appointment/GetAppointment', {
            params: {
                appointmentID: appointmentID
            }
        }).then(response => {
            const appointmentDetail = response.data;
            this.setState({
                selectedPatientId: appointmentDetail.patientId,
                selectedPatient: {
                    [TITLE]: appointmentDetail.patientTitle,
                    [FIRST_NAME]: appointmentDetail.patientFirstName,
                    [LAST_NAME]: appointmentDetail.patientLastName,
                    [DOB]: FormatDate(appointmentDetail.patientDob),
                    [MOBILE_PHONE]: appointmentDetail.patientPhone,
                    [EMAIL]: appointmentDetail.patientEmail
                },
                appointment: {
                    [START_DATE_TIME]: FormatDateTime(appointmentDetail.startDateTime),
                    [STOP_DATE_TIME]: FormatDateTime(appointmentDetail.stopDateTime),
                    [COMMENT]: appointmentDetail.comment || ''
                },
                patientSearchValue: appointmentDetail.patientFirstName + ' ' + appointmentDetail.patientLastName,
                loading: false
            });

            return response.data;
        }).catch(err => {
            this.setState({
                loading: false
            });
            return null;
        });

    }

    saveAppointmentHandler = async (bypassLimitCheck = false, bypassOverlapCheck = false, bypassDuplicatePatientCheck = false) => {
        if (this.state.selectedPatient.patientFirstName.length === 0 || this.state.selectedPatient.patientLastName.length === 0) {
            NotificationManager.warning('Please enter a valid patient name', 'Invalid Name');
            return;
        }

        this.setState({
            saving: true,
        });

        let appointmentIdToSave = 0;
        if (this.props.selectedAppointmentID) {
            appointmentIdToSave = this.props.selectedAppointmentID;
        }

        const appointmentData = {
            appointmentID: appointmentIdToSave,
            createdUserId: this.props.user.userId,
            patientId: this.state.selectedPatientId,
            ...this.state.selectedPatient,
            ...this.state.appointment
        }

        await EnoteAxios.post('/api/appointment/UpdateAppointment', appointmentData, {
            params: {
                bypassLimitCheck: bypassLimitCheck,
                bypassOverlapCheck: bypassOverlapCheck,
                bypassDuplicatePatientCheck: bypassDuplicatePatientCheck
            }
        }).then(response => {
            if (response.status === 200) {
                this.props.onSave();
                // no need to reset saving state because the modal will be closed.
            }
        }).catch(err => {
            if (err && err.response && err.response.status === 422 && err.response && err.response.data && err.response.data.confirmation) {
                this.setState({
                    saving: false,
                    showConfirmationMsg: true,
                    confirmationMsg: err.response.data.confirmation
                });
            } else {
                this.setState({
                    saving: false
                });
            }
        });

    }

    alertConfirmationCancelHandler = () => {
        this.setState({
            showConfirmationMsg: false,
            confirmationMsg: ''
        });
    }

    closeAppointmentClickHandler = (e) => {
        e.preventDefault();
        this.setState({
            selectedPatientId: 0,
            selectedPatient: {
                [TITLE]: '',
                [FIRST_NAME]: '',
                [LAST_NAME]: '',
                [DOB]: '',
                [MOBILE_PHONE]: '',
                [EMAIL]: ''
            },
            appointment: {
                [START_DATE_TIME]: '',
                [STOP_DATE_TIME]: '',
                [COMMENT]: ''
            }
        });
        this.props.onClose();
    }

    selectedPatientChangeHandler = (e, { result }) => {
        if (result) {
            let selectedPatient = this.state.patientList.find(x => x.patientId === result.id);
            if (selectedPatient) {
                this.setState({
                    showMatchPatient: false,
                    patientSearchValue: GetPatientFullName(selectedPatient.firstName || '', selectedPatient.lastName || ''),
                    selectedPatientId: selectedPatient.patientId,
                    selectedPatient: {
                        [TITLE]: selectedPatient.title,
                        [FIRST_NAME]: selectedPatient.firstName,
                        [LAST_NAME]: selectedPatient.lastName,
                        [DOB]: FormatDate(selectedPatient.dob),
                        [MOBILE_PHONE]: selectedPatient.mobilePhone,
                        [EMAIL]: selectedPatient.email
                    }
                });
            }
        }
    }

    patientInputChangeHandler = (e, { name, value }) => {
        this.setState(prevState => ({
            selectedPatient: {
                ...prevState.selectedPatient,
                [name]: value
            }
        }));
    }

    appointmentInputChangeHandler = (e, { name, value }) => {
        this.setState(prevState => ({
            appointment: {
                ...prevState.appointment,
                [name]: value
            }
        }));
    }

    appointmentStartTimeChangeHandler = (e, { name, value }) => {
        let newStartTime = moment(value);
        this.setState(prevState => ({
            appointment: {
                startDateTime: value,
                stopDateTime: IsValidDateTime(value) ? FormatDateTime(newStartTime.add(1, 'hour')) : ''
            }
        }));
    }

    clearSelectedPatient = () => {
        this.setState({
            showMatchPatient: false,
            patientSearchValue: '',
            patientList: [],
            selectedPatientId: 0,
            selectedPatient: {
                [TITLE]: '',
                [FIRST_NAME]: '',
                [LAST_NAME]: '',
                [DOB]: '',
                [MOBILE_PHONE]: '',
                [EMAIL]: ''
            }
        });
    }

    deleteEventClickHandler = async (e, appointmentId) => {
        e.preventDefault();
        if (appointmentId > 0) {
            if (this.props.onDelete)
                this.props.onDelete(e, appointmentId)
        }
    }

    getPatientListDebounce = _.debounce(async (value) => {
        await this.getPatientList(value);
    }, 500)

    getPatientList = async (value) => {
        this.setState({
            loadingMatchPatient: true
        });
        await EnoteAxios.get('/api/patient/GetPatientList', {
            params: {
                keyword: value,
                searchType: 1
            }
        }).then(response => {
            if (response.data) {
                const sortedData = response.data.sort((a, b) => b.patientId - a.patientId);
                this.setState({
                    patientList: sortedData,
                });
            }
        }).catch(err => {
            console.log(err);
        }).then(() => {
            this.setState({
                loadingMatchPatient: false,
                showMatchPatient: true
            });
        })
    }

    patientSearchChangeHandler = async (e, { value }) => {
        this.setState({ patientSearchValue: value });
        if (value) {
            this.getPatientListDebounce(value);
        } else {
            this.setState({
                patientList: []
            });
        }
    }

    patientSearchFocused = async () => {
        let value = this.state.patientSearchValue;
        if (value) {
            await this.getPatientList(value);
        }
    }

    render() {
        let canSave = true;

        if (!IsValidDateTime(this.state.appointment.startDateTime, false) || !IsValidDateTime(this.state.appointment.stopDateTime, false)) {
            canSave = false;
        }

        let formattedPatientList = this.state.patientList.map(p => {
            let fullName = GetPatientFullName(p.firstName, p.lastName);
            return (
                {
                    'key': p.patientId,
                    'id': p.patientId,
                    'title': fullName,
                    'fullname': fullName,
                    'dob': FormatDate(p.dob)
                }
            )
        });

        return (
            <div className='AppointmentScheduler'>
                <CustomLoader active={this.state.loading} cssLoader />
                <ConditionalArea show={!this.state.loading}>
                    <Form autoComplete="off">
                        <Header as='h5'>Search patients by entering name below or create a new patient</Header>
                        <Form.Group widths='equal'>
                            <Form.Field>
                                <Search
                                    onFocus={() => this.patientSearchFocused()}
                                    onBlur={() => this.setState({ showMatchPatient: false, loadingMatchPatient: false })}
                                    open={this.state.showMatchPatient}
                                    loading={this.state.loadingMatchPatient}
                                    onResultSelect={this.selectedPatientChangeHandler}
                                    onSearchChange={this.patientSearchChangeHandler}
                                    results={formattedPatientList}
                                    value={this.state.patientSearchValue}
                                    resultRenderer={patientResultRenderer}
                                    fluid
                                    placeholder='Search for existing patients'
                                />
                            </Form.Field>
                            <Form.Field>
                                {
                                    this.state.selectedPatientId === 0 ? null :
                                        <Button primary onClick={this.clearSelectedPatient} title='Clear the current selected patient and manually enter a new patient below' disabled={this.state.selectedPatientId === 0}>Clear Selected Patient</Button>
                                }
                            </Form.Field>
                        </Form.Group>
                        <Message info hidden={this.state.selectedPatientId === 0}>
                            <Header as='h5'>The name and date of birth of an existing patient cannot be changed while scheduling an appointment.</Header>
                        </Message>
                        <Header as='h4' dividing>Patient Detail</Header>
                        <Form.Group widths='equal'>

                            <Form.Field>
                                <Form.Dropdown label='Title' options={TITLE_OPTIONS} selection name={TITLE} value={this.state.selectedPatient.patientTitle} onChange={this.patientInputChangeHandler} placeholder='Select Title' readOnly={this.state.selectedPatientId} />
                            </Form.Field>

                            <Form.Field>
                                <Form.Input required label='First Name' name={FIRST_NAME} value={this.state.selectedPatient.patientFirstName} type='text' onChange={this.patientInputChangeHandler} placeholder='First Name' readOnly={this.state.selectedPatientId} />
                            </Form.Field>

                            <Form.Field>
                                <Form.Input required label='Last Name' name={LAST_NAME} value={this.state.selectedPatient.patientLastName} type='text' onChange={this.patientInputChangeHandler} placeholder='Last Name' readOnly={this.state.selectedPatientId} />
                            </Form.Field>

                        </Form.Group>
                        <Form.Group widths='equal'>

                            <Form.Field>
                                <DateInput
                                    label='Date of Birth'
                                    dateFormat='YYYY-MM-DD'
                                    name={DOB}
                                    initialDate='1990-01-01'
                                    startMode='year'
                                    placeholder='YYYY-MM-DD'
                                    value={this.state.selectedPatient.patientDob}
                                    iconPosition="left"
                                    closable
                                    onChange={this.patientInputChangeHandler}
                                    hideMobileKeyboard
                                    readOnly={(this.state.selectedPatientId > 0) === true}
                                    animation='none'
                                    error={!IsValidDate(this.state.selectedPatient.patientDob, true)}
                                    popupPosition={'bottom left'}
                                />
                            </Form.Field>

                            <Form.Field>
                                <Form.Input label='Email' name={EMAIL} value={this.state.selectedPatient.patientEmail} type='email' onChange={this.patientInputChangeHandler} placeholder='Email' />
                            </Form.Field>

                            <Form.Field>
                                <Form.Input label='Mobile Phone' name={MOBILE_PHONE} value={this.state.selectedPatient.patientPhone} type='text' onChange={this.patientInputChangeHandler} placeholder='Mobile Phone' />
                            </Form.Field>
                        </Form.Group>
                        <AppointmentReminderConfigMessage />
                        <Header as='h4' dividing>Appointment Detail</Header>
                        <Form.Group widths='equal'>
                            <Form.Field>
                                <DateTimeInput
                                    label='Start At'
                                    dateFormat='YYYY-MM-DD'
                                    timeFormat='24'
                                    name={START_DATE_TIME}
                                    placeholder='Appointment date and time'
                                    value={this.state.appointment.startDateTime}
                                    iconPosition="left"
                                    closable
                                    onChange={this.appointmentStartTimeChangeHandler}
                                    hideMobileKeyboard
                                    animation='none'
                                    required
                                    error={!IsValidDateTime(this.state.appointment.startDateTime, true)}
                                    popupPosition={'bottom left'}
                                />
                            </Form.Field>
                            <Form.Field>
                                <DateTimeInput
                                    label='Finish At'
                                    dateFormat='YYYY-MM-DD'
                                    timeFormat='24'
                                    name={STOP_DATE_TIME}
                                    placeholder='Finish date and time'
                                    value={this.state.appointment.stopDateTime}
                                    iconPosition="left"
                                    closable
                                    onChange={this.appointmentInputChangeHandler}
                                    hideMobileKeyboard
                                    animation='none'
                                    required
                                    error={!IsValidDateTime(this.state.appointment.stopDateTime, true)}
                                    popupPosition={'bottom left'}
                                />
                            </Form.Field>
                        </Form.Group>
                        <Grid widths='16'>
                            <Grid.Column width='16'>
                                <label>Comment</label>
                                <CustomTextArea name={COMMENT} value={this.state.appointment.comment} onChange={this.appointmentInputChangeHandler} placeholder='Enter appointment comment' />
                            </Grid.Column>
                        </Grid>
                        <div className='NewAppointmentControlPane'>
                            <ControlPane>
                                {
                                    this.props.selectedAppointmentID !== 0 &&
                                    <Button className='DeleteAppointmentButton' color='red' onClick={() => this.setState({ showDeleteWarning: true })}>Delete</Button>
                                }
                                <Button onClick={this.closeAppointmentClickHandler}>Close</Button>
                                {
                                    this.props.onViewPatient ? <Button primary onClick={(e) => this.props.onViewPatient(e, {
                                        patientId: this.state.selectedPatientId,
                                        patientFirstName: this.state.selectedPatient.patientFirstName,
                                        patientLastName: this.state.selectedPatient.patientLastName
                                    })} disabled={this.state.selectedPatientId === 0}>Patient Notes</Button> : null
                                }
                                <Button primary onClick={() => this.saveAppointmentHandler()} disabled={this.state.saving || !canSave} loading={this.state.saving}>Save</Button>
                            </ControlPane>
                        </div>
                    </Form>
                </ConditionalArea>
                <ConfrimDialog content='Are you sure you want to delete this appointment?' open={this.state.showDeleteWarning} onConfirm={(e) => this.deleteEventClickHandler(e, this.props.selectedAppointmentID)} onCancel={() => this.setState({ showDeleteWarning: false})} />
                <ConfrimDialog content={this.state.confirmationMsg} open={this.state.showConfirmationMsg} onConfirm={() => this.saveAppointmentHandler(true, true, true)} onCancel={this.alertConfirmationCancelHandler} />
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        user: state.user,
        token: state.token
    }
}

export default connect(mapStateToProps)(ScheduleAppointment);