import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Header, Button, Grid, Radio, Input, Form, Checkbox, Modal, Icon } from 'semantic-ui-react';
import { DatesRangeInput } from 'semantic-ui-calendar-react-yz';
import { FormatDate, FormatDateTime, GetInjuryTabID, GetStartStopDatesFromRangeString, GetPatientFullNameShort, GetPatientTabID } from '../../../../../assets/helper';
import CustomReactTable, { XOverflowMode } from '../../../../../UI/CustomReactTable/CustomReactTable';
import moment from 'moment';
import EnoteAxios from '../../../../../axios';
import ControlPane from '../../../../../UI/Controls/Control/ControlPane';
import './ActivitySummary.css';
import TreatmentDetail from '../../../PatientPane/TreatmentDetail/TreatmentDetail';
import ScheduleAppointment from '../../../AppointmentPane/ScheduleAppointment/ScheduleAppointment';
import PatientPane from '../../../PatientPane/PatientPane';
import { ORGANISATION } from '../../../../../assets/constants';
import { isMobileOnly } from 'react-device-detect';
import ConfrimDialog from '../../../../../UI/ConfrimDialog/ConfirmDialog';
import { withRouter } from 'react-router-dom';
import { createColumnHelper } from '@tanstack/react-table';

const columnHelper = createColumnHelper();

const dailyActivityInSubmissionColumns = [
	columnHelper.accessor(r => FormatDate(r.date), {
		id: 'date',
		header: 'Date',
	}),
	columnHelper.accessor(r => r.totalAppointments, {
		id: 'totalAppointments',
		header: 'Total Appointments',
	}),
	columnHelper.accessor(r => r.totalTreatments, {
		id: 'totalTreatments',
		header: 'Total Treatments',
	}),
	columnHelper.accessor(r => r.totalInvoicedTreatments, {
		id: 'totalInvoicedTreatments',
		header: 'Total Invoiced Treatments',
	}),
];

const dailyActivityInMinutesColumns = [
	columnHelper.accessor(r => FormatDate(r.date), {
		id: 'date',
		header: 'Date',
	}),
	columnHelper.accessor(r => (r.totalAppointmentMinutes / 60).toFixed(2), {
		id: 'totalAppointmentHours',
		header: 'Total Appointment Hours',
		sortingFn: (a, b) => {
			return +a > +b ? 1 : -1
		},
	}),
	columnHelper.accessor(r => (r.totalInvoicedMinutes / 60).toFixed(2), {
		id: 'totalInvoicedHours',
		header: 'Total Invoiced Hours',
		sortingFn: (a, b) => {
			return +a > +b ? 1 : -1
		},
	}),
];

function ActivitySummary(props) {
	const [selectedDateOption, setSelectedDateOption] = useState(1);
	const [lastNYear, setLastNYear] = useState(1);
	const [customDateRange, setCustomDateRange] = useState('');

	const [selectedThreasholdOption, setSelectedThreasholdOption] = useState(0);
	const [treatmentThreshold, settreatmentThreshold] = useState(props.user.treatmentAlertLimit || 8);
	const [treatmentThresholdHours, settreatmentThresholdHours] = useState(props.user.treatmentDurationAlertLimit || 8);

	const [showRecordMismatchDatesOnly, setShowRecordMismatchDatesOnly] = useState(false);
	const [disableMismatchDatesFilter, setDisableMismatchDatesFilter] = useState(false);

	const [activityList, setActivityList] = useState([]);
	const [isDataInMinutes, setIsDataInMinutes] = useState(false);
	const [activityDisplayList, setActivityDisplayList] = useState([]);
	const [selectedDate, setSelectedDate] = useState('');
	const [selectedDateAppointments, setSelectedDateAppointments] = useState([]);
	const [selectedDateTreatments, setSelectedDateTreatments] = useState([]);

	const [loadingReport, setLoadingReport] = useState(false);
	const [loadingAppointments, setLoadingAppointments] = useState(false);
	const [loadingTreatments, setLoadingTreatments] = useState(false);

	const [showTreatmentModal, setShowTreatmentModal] = useState(false);
	const [showAppointmentModal, setShowAppointmentModal] = useState(false);
	const [showInjuryModal, setShowInjuryModal] = useState(false);
	const [showPatientModal, setShowPatientModal] = useState(false);

	const [modalData, setModalData] = useState({});

	const [modalHasUnsavedChange, setModalHasUnsavedChange] = useState(false);
	const [showUnsavedChangeWarning, setShowUnsavedChangeWarning] = useState(false);

	const onActivityRowClick = async (e, rowInfo) => {
		setSelectedDate(rowInfo.original.date);
		await loadDayDetail(rowInfo.original.date);
	}

	const loadDayDetail = async (date) => {
		date = FormatDate(date);
		setLoadingAppointments(true);
		setLoadingTreatments(true);
		const getAppointments = EnoteAxios.get('/api/report/GetAppointmentListForDay', {
			params: { date: date }
		}).then(response => {
			setSelectedDateAppointments(response.data);
		}).catch(error => {
			console.log(error);
		}).then(() => {
			setLoadingAppointments(false);
		});

		const getTreatments = EnoteAxios.get('/api/report/GetTreatmentListForDay', {
			params: { date: date }
		}).then(response => {
			setSelectedDateTreatments(response.data);
		}).catch(error => {
			console.log(error);
		}).then(() => {
			setLoadingTreatments(false);
		});

		await Promise.all([getAppointments, getTreatments]);
	}

	const onAppointmentRowClick = (e, rowInfo) => {
		const appointmentSummary = rowInfo.original;
		setModalData(appointmentSummary.appointmentId);
		setShowAppointmentModal(true);
	}

	const onTreatmentRowClick = (e, rowInfo) => {
		const treatmentSummary = rowInfo.original;
		setModalData({
			patient: {
				firstName: treatmentSummary.firstName,
				lastName: treatmentSummary.lastName,
				patientId: treatmentSummary.patientId
			},
			treatmentId: treatmentSummary.treatmentId,
			injuryId: treatmentSummary.injuryId,
			treatmentIndex: treatmentSummary.treatmentIndex
		});
		setShowTreatmentModal(true);
	}

	const onViewInjuryClick = (e, treatment) => {
		e.stopPropagation();
		setModalData({
			patientId: treatment.patientId,
			patientFirstName: treatment.patientFirstName,
			patientLastName: treatment.patientLastName,
			injuryId: treatment.injuryId,
			dob: treatment.dateOfBirth
		});
		setShowInjuryModal(true);
	}

	const showRecordMismatchDatesOnlyChangeHandler = (e, { checked }) => {
		setShowRecordMismatchDatesOnly(checked);
		if (checked) {
			setActivityDisplayList(activityList.filter(x => x.totalAppointments !== x.totalTreatments));
		} else {
			setActivityDisplayList(activityList);
		}
	}

	const onViewPatientClick = (e, patient) => {
		setModalData(patient);
		setShowPatientModal(true);
	}

	const loadActivityList = async () => {
		let startDate = '';
		let endDate = '';

		switch (selectedDateOption) {
			case 0:
				//do nothing
				break;
			case 1:
				startDate = FormatDate(moment().add(lastNYear <= 0 ? -1 : -lastNYear, 'year'));
				// no need to set endDate as it defaults to MaxValue
				break;
			case 2:
				if (customDateRange) {
					[startDate, endDate] = GetStartStopDatesFromRangeString(customDateRange)
				} else {
					console.log('invalid date range provided, fetch all instead');
				}
				break;
			default:
				break;
		}
		console.log('fetching data between dates: ', startDate || 'min', endDate || 'max');

		let requestParams = {};
		switch (selectedThreasholdOption) {
			case 1:
				requestParams = {
					treatmentThreshold: treatmentThreshold,
					treatmentThresholdHour: 0
				}
				break;
			case 2:
				requestParams = {
					treatmentThreshold: 0,
					treatmentThresholdHour: treatmentThresholdHours,
					filterByTimeOnly: true
				}
				break;
			case 0:
			default:
				break;
		}

		if (startDate) {
			requestParams = {
				...requestParams,
				startDate: startDate
			}
		}

		if (endDate) {
			requestParams = {
				...requestParams,
				endDate: endDate
			}
		}
		setLoadingReport(true);
		await EnoteAxios.get('/api/report/GetActivityList', {
			params: requestParams
		}).then(response => {
			setActivityList(response.data);
			if (showRecordMismatchDatesOnly) {
				setActivityDisplayList(response.data.filter(x => x.totalAppointments !== x.totalTreatments));
			} else {
				setActivityDisplayList(response.data);
			}

			if (selectedThreasholdOption === 2) {
				setIsDataInMinutes(true);
			} else {
				setIsDataInMinutes(false);
			}
		}).catch(error => {
			console.log(error);
		}).then(() => {
			setLoadingReport(false);
		});
	}

	let dailyActivityColumns = isDataInMinutes ? dailyActivityInMinutesColumns : dailyActivityInSubmissionColumns;

	let appointmentColumns = React.useMemo(() => [
		columnHelper.accessor(r => FormatDateTime(r.startDateTime, 'HH:mm'), {
			id: 'startTime',
			header: 'Start',
		}),
		columnHelper.accessor(r => FormatDateTime(r.stopDateTime, 'HH:mm'), {
			id: 'stopTime',
			header: 'End',
		}),
		columnHelper.accessor(r => r.patientFirstName, {
			id: 'patientFirstName',
			header: 'First Name',
		}),
		columnHelper.accessor(r => r.patientLastName, {
			id: 'patientLastname',
			header: 'Last Name',
		}),
	], []);

	let treatmentColumns = React.useMemo(() => {
		let columns = [];
		if (props.user.organisationId === ORGANISATION.NZRA) {
			columns.push(
				columnHelper.accessor(r => FormatDateTime(r.startDateTime, 'HH:mm'), {
					id: 'startTime',
					header: 'Start',
				}), columnHelper.accessor(r => FormatDateTime(r.stopDateTime, 'HH:mm'), {
					id: 'stopTime',
					header: 'End',
				}))
		}
		columns.push(
			columnHelper.accessor(r => r.patientFirstName, {
				id: 'patientFirstName',
				header: 'First Name',
			}),
			columnHelper.accessor(r => r.patientLastName, {
				id: 'patientLastname',
				header: 'Last Name',
			}),
			columnHelper.accessor(r => FormatDate(r.dateOfBirth), {
				id: 'dob',
				header: 'Date of Birth',
			}),
			columnHelper.accessor(r => r.accNumber, {
				id: 'accNumber',
				header: 'ACC Number',
			}),
			columnHelper.accessor(r => r.claimStatus === 0 ? '-' : 'Claimed', {
				id: 'claimStatus',
				header: 'Claim Status',
			}),
			columnHelper.accessor(r => FormatDate(r.claimDateTime), {
				id: 'claimDateTime',
				header: 'Last Claim Time',
			}),
			columnHelper.accessor(r => r, {
				id: 'action',
				header: 'Action',
				cell: ({ row }) => (
					<span className='injury'>
						<Icon title='View Injury Detail' onClick={(e) => onViewInjuryClick(e, row.original)} name='file alternate outline' />
					</span>
				)
			}),
		);
		return columns;
	}, [props.user.organisationId]);


	const modalCloseHandler = async (confirmed = false) => {
		if (modalHasUnsavedChange && !confirmed) {
			setShowUnsavedChangeWarning(true);
		} else {
			setModalHasUnsavedChange(false);
			setShowUnsavedChangeWarning(false);
			setShowTreatmentModal(false);
			setShowAppointmentModal(false);
			setShowInjuryModal(false);
			setModalData({});
			await loadActivityList();
			await loadDayDetail(selectedDate);
		}
	}

	const appointmentDeleteHandler = async (e, appointmentId) => {
		e.preventDefault();

		if (appointmentId > 0) {
			await EnoteAxios.delete('/api/appointment/DeleteAppointment', {
				params: {
					appointmentID: appointmentId
				}
			}).catch(err => {
				console.log(err)
			}).then(async () => {
				modalCloseHandler(true);
			})
		}
	}

	const filterChangeHandler = (val) => {
		setSelectedThreasholdOption(val);
		if (val === 2) {
			setShowRecordMismatchDatesOnly(false);
			setDisableMismatchDatesFilter(true);
		} else {
			setDisableMismatchDatesFilter(false);
		}
	}

	let modalContent = null;
	let patientModalContent = null;

	if (showTreatmentModal) {
		modalContent = (
			<TreatmentDetail
				data={modalData}
				touched={modalHasUnsavedChange}
				onTouch={() => setModalHasUnsavedChange(true)}
				onTabMetadataChange={() => setModalHasUnsavedChange(false)}
				onCloseInControlPane={() => modalCloseHandler(false)}
				onTabClose={() => modalCloseHandler(true)}
				inModal />
		)
	} else if (showAppointmentModal) {
		modalContent = (
			<ScheduleAppointment selectedAppointmentID={modalData} onSave={modalCloseHandler} onClose={modalCloseHandler} onDelete={appointmentDeleteHandler} hasUnsavedChange={(unsavedChange) => setModalHasUnsavedChange(unsavedChange)} onViewPatient={onViewPatientClick} />
		)
	} else if (showInjuryModal) {
		modalContent = (
			<PatientPane
				tabs={[{
					tabID: GetInjuryTabID(modalData.patientId, modalData.injuryId),
					tabTitle: GetPatientFullNameShort(modalData.patientFirstName, modalData.patientLastName) + ' Injury: ' + FormatDate(selectedDate),
					componentClass: 'InjuryDetail',
					data: {
						injuryId: modalData.injuryId,
						patient: {
							patientId: modalData.patientId,
							firstName: modalData.patientFirstName,
							lastName: modalData.patientLastName,
							dob: modalData.dob
						}

					}
				}]}
				instanceMode
				onNoTab={() => setShowInjuryModal(false)}
				inModal={true}
				hasUnsavedChange={(unsavedChange) => setModalHasUnsavedChange(unsavedChange)}
			/>
		)
	}

	if (showPatientModal) {
		patientModalContent = (
			<PatientPane
				tabs={[{
					tabID: GetPatientTabID(modalData.patientId),
					tabTitle: GetPatientFullNameShort(modalData.patientFirstName, modalData.patientLastName),
					componentClass: 'PatientDetail',
					data: {
						patientId: modalData.patientId,
					}
				}]}
				instanceMode
				onNoTab={() => setShowPatientModal(false)}
				inModal={true}
				hasUnsavedChange={(unsavedChange) => setModalHasUnsavedChange(unsavedChange)}
			/>
		)
	}

	let dailyAppointmentTable = (
		<Grid.Column mobile={16} tablet={5} computer={5}>
			<Header>Appointments</Header>
			<div className='AppointmentTable'>
				<CustomReactTable
					columns={appointmentColumns}
					data={selectedDateAppointments}
					onRowClick={onAppointmentRowClick}
					noDataText='No data available'
					loading={loadingAppointments}
					clickable
					xOverflowMode={XOverflowMode.Scroll}
					fixedStyle={false}
				/>
			</div>
		</Grid.Column>
	);

	let dailyTreatmentTable = (
		<Grid.Column mobile={16} tablet={11} computer={11}>
			<div className='TreatmentTable'>
				<Header>Treatments</Header>
				<CustomReactTable
					columns={treatmentColumns}
					data={selectedDateTreatments}
					onRowClick={onTreatmentRowClick}
					noDataText='No data available'
					loading={loadingTreatments}
					clickable
					xOverflowMode={XOverflowMode.Scroll}
					fixedStyle={false}
				/>
			</div>
		</Grid.Column>
	)

	let dailyActivityTables = null;

	if (!isMobileOnly) {
		dailyActivityTables = (
			<React.Fragment>
				{dailyAppointmentTable}
				{dailyTreatmentTable}
			</React.Fragment>
		)
	} else {
		dailyActivityTables = (
			<React.Fragment>
				{dailyTreatmentTable}
				{dailyAppointmentTable}
			</React.Fragment>
		)
	}

	return (
		<div className={'ActivitySummary ' + (props.match ? '' : 'css-hidden')}>
			<Grid stretched padded={true}>
				<Grid.Column mobile={16} tablet={5} computer={5} className='ReportParamPane'>
					<Form>
						<Header>Dates</Header>
						<Grid.Row>
							<Radio
								label='All'
								name='DateRange'
								value={0}
								checked={selectedDateOption === 0}
								onChange={(e, { value }) => setSelectedDateOption(value)}
							/>
						</Grid.Row>
						<Grid.Row>
							<Radio
								label='Last'
								name='DateRange'
								value={1}
								checked={selectedDateOption === 1}
								onChange={(e, { value }) => setSelectedDateOption(value)}
							/>
							<Input className='FilterInput' size='mini' type='number' name='lastNYear' value={lastNYear} onChange={(e, { value }) => setLastNYear(value)} disabled={selectedDateOption !== 1} />
							<span>year(s)</span>
						</Grid.Row>
						<Grid.Row>
							<Radio
								label='Between dates'
								name='DateRange'
								value={2}
								checked={selectedDateOption === 2}
								onChange={(e, { value }) => setSelectedDateOption(value)}>
							</Radio>
							<DatesRangeInput
								className='InlineDateInput'
								closable
								disabled={selectedDateOption !== 2}
								dateFormat='YYYY-MM-DD'
								onChange={(e, { value }) => setCustomDateRange(value)}
								value={customDateRange}
								placeholder='From - To'
								animation='none'
								hideMobileKeyboard
								popupPosition={'bottom left'}
							/>
						</Grid.Row>
						<Header>Filters</Header>
						<Grid.Row>
							<Radio
								label='All'
								name='treatmentThreshold'
								value={0}
								checked={selectedThreasholdOption === 0}
								onChange={(e, { value }) => filterChangeHandler(value)}
							/>
						</Grid.Row>
						<Grid.Row>
							<Radio
								label='Dates with at least '
								name='treatmentThreshold'
								value={1}
								checked={selectedThreasholdOption === 1}
								onChange={(e, { value }) => filterChangeHandler(value)} />
							<Input className='FilterInput' size='mini' type='number' name='treatmentThreshold' value={treatmentThreshold} onChange={(e, { value }) => settreatmentThreshold(value)} disabled={selectedThreasholdOption !== 1} />
							<span>treatment(s)</span>
						</Grid.Row>
						<Grid.Row>
							<Radio
								label='Dates with at least '
								name='treatmentThreshold'
								value={2}
								checked={selectedThreasholdOption === 2}
								onChange={(e, { value }) => filterChangeHandler(value)} />
							<Input className='FilterInput' size='mini' type='number' name='treatmentThresholdHours' value={treatmentThresholdHours} onChange={(e, { value }) => settreatmentThresholdHours(value)} disabled={selectedThreasholdOption !== 2} />
							<span>invoiced hour(s)</span>
						</Grid.Row>
						<Header>Display</Header>
						<Grid.Row>
							<Checkbox
								label='Only show dates with mismatched appointment and treatment counts'
								checked={showRecordMismatchDatesOnly}
								onChange={showRecordMismatchDatesOnlyChangeHandler}
								disabled={disableMismatchDatesFilter}
							/>
						</Grid.Row>

						<ControlPane padTop>
							<Button primary name='RunReport' content='Run Report' onClick={loadActivityList} loading={loadingReport} disabled={loadingReport} />
						</ControlPane>

					</Form>
				</Grid.Column>
				<Grid.Column mobile={16} tablet={11} computer={11} >
					<div className='ReportResultTable'>
						<Header>Activity Statistics</Header>
						<CustomReactTable
							columns={dailyActivityColumns}
							data={activityDisplayList}
							onRowClick={onActivityRowClick}
							pagination
							showPageSizeOptions={false}
							defaultPageSize={10}
							noDataText='No data available'
							loading={loadingReport}
							clickable
							fixedStyle={false}
							xOverflowMode={XOverflowMode.Scroll}
							autoResetPageIndex={false}
							highlightedRow={{
								fieldName: 'date',
								highlightedValue: selectedDate
							}}
						/>
					</div>
				</Grid.Column>
			</Grid>
			<Grid>
				{dailyActivityTables}
			</Grid>
			<Modal open={showAppointmentModal} size='small' closeIcon onClose={() => setShowAppointmentModal(false)}>
				<Modal.Content>
					<div className='ModalContent'>
						{modalContent}
					</div>
				</Modal.Content>
			</Modal>
			<Modal open={showPatientModal} size='large' closeIcon onClose={() => setShowPatientModal(false)}>
				<Modal.Content>
					<div className='ModalContent'>
						{patientModalContent}
					</div>
				</Modal.Content>
			</Modal>
			<Modal open={showInjuryModal || showTreatmentModal} size='large' closeIcon onClose={() => modalCloseHandler(false)}>
				<Modal.Content scrolling>
					<div className='ModalContent'>
						{modalContent}
					</div>
				</Modal.Content>
			</Modal>
			<ConfrimDialog
				content='Are you sure you want to close the page? Any unsaved change will be lost.'
				open={showUnsavedChangeWarning}
				onConfirm={() => modalCloseHandler(true)}
				onCancel={() => setShowUnsavedChangeWarning(false)} />
		</div>
	)
}

const mapStateToProps = (state) => {
	return {
		user: state.user
	};
}

export default withRouter(connect(mapStateToProps)(ActivitySummary));