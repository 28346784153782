import React from 'react';
import { FormatDate } from '../../../../assets/helper';
import { Icon } from 'semantic-ui-react';
import './HoursHeader.css';

type WorkTime = {
    appointmentMinutes: number,
    invoicedMinutes: number
}

interface HoursHeaderProps {
    getWorkTimeCount: (date : Date) => WorkTime,
    view: string
}

const HoursHeader = ({ getWorkTimeCount, view }: HoursHeaderProps) => (props: any) => {
    let workTime = getWorkTimeCount(FormatDate(props.date));
    let appointmentHours = 0;
    let invoicedHours = 0;
    if (workTime) {
        appointmentHours = workTime.appointmentMinutes / 60;
        invoicedHours = workTime.invoicedMinutes / 60;
    }
    return (
        <div>
            {
                view === 'day' ? null : <p>{props.label}</p>
            }
            <div className='HoursDetail' title='Appointment Hours'>
                <Icon name='calendar outline' size='large' />
                <span>{appointmentHours.toFixed(2)}</span>
            </div>
            <div className='HoursDetail' title='Invoiced Hours'>
                <Icon name='checked calendar' size='large' />
                <span>{invoicedHours.toFixed(2)}</span>
            </div>

        </div>
    );
}

export default HoursHeader;