import React from 'react';
import './TabContentSection.css';
import { isMobileOnly } from 'react-device-detect';

const TabContentSection = (props) => (
    <div className={'TabContentSection ' + (isMobileOnly ? 'Mobile' : '')}>
        {props.children}
    </div>
)

export default TabContentSection;