import axios from 'axios';
import packageJson from '../package.json';

let baseURL = 'https://log-api.newrelic.com/log/v1';

const nrAxios = axios.create({
    headers: {
        'Content-Type': 'application/json',
        'X-License-Key': packageJson.enote.newRelicLicenseKey
    }
});

export function sendLogToNewRelicAsync(data) {
    return nrAxios.post(baseURL, {
        ...data,
        timestamp: Date.now()
    });
}