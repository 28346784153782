var moment = require('moment');

export function FormatDate(date, format = 'YYYY-MM-DD') {
    let formatedDate = date;
    if (date) {
        try {
            formatedDate = moment(date).format(format);
        } catch (err) {
            console.log("Failed to format date: " + date + ' to format ' + format);
        }
    } else {
        formatedDate = '';
    }
    return formatedDate;
}

export function FormatTime(time, timeWithoutDate = false, format = 'HH:mm') {
    let formatedTime = time;
    if (time) {
        try {
            if (timeWithoutDate) {
                formatedTime = moment('2000-01-01 ' + time).format(format);
            } else {
                formatedTime = moment(time).format(format);
            }
        } catch (err) {
            console.log("Failed to format time: " + time + ' to format ' + format);
        }
    } else {
        formatedTime = '';
    }
    return formatedTime;
}

export function FormatDateTime(dateTime, format = 'YYYY-MM-DD HH:mm') {
    return FormatDate(dateTime, format);
}

export function GetPatientTabID(patientId) {
    return 'p_' + patientId;
}

export function GetInjuryTabID(patientId, injuryId) {
    return GetPatientTabID(patientId) + ' i_' + injuryId;
}

export function GetTreatmentTabID(patientId, injuryId, treatmentIndex) {
    return GetInjuryTabID(patientId, injuryId) + 't_' + treatmentIndex;
}

export function GetPatientFullNameShort(firstName, lastName) {
    return firstName + ' ' + (lastName && lastName.length > 0 ? lastName.charAt(0) : '').trim();
}

export function GetPatientFullName(firstName, lastName) {
    return firstName + ' ' + (lastName || '').trim();
}

export function GetStartStopDatesFromRangeString(range) {
    let dates = range.split(' - ');
    let startDate = null;
    let stopDate = null;
    if (dates.length === 2) {
        startDate = FormatDate(dates[0].trim());
        stopDate = FormatDate(dates[1].trim());
    } else if (dates.length === 1) {
        startDate = FormatDate(dates[0].trim());
    }

    return [startDate, stopDate]
}

export function IsValidDate(dateString, optionalField) {
    let isValidDateFormat = /^\d{4}-([1-9]|0[1-9]|1[0-2])-([1-9]|[0-2][0-9]|3[0-1])$/.test(dateString);
    if ((optionalField && !dateString) || (isValidDateFormat && moment(dateString).isValid())) {
        return true;
    }
    return false;
}

export function IsValidTime(timeString, optionalField) {
    let isValidTimeFormat = /^([0-9]|[0-1][0-9]|2[0-3]):([0-9]|[0-5][0-9])$/.test(timeString);
    if ((optionalField && !timeString) || isValidTimeFormat) {
        return true;
    }
    return false;
}

export function IsValidDateTime(dateTimeString, optionalField) {
    let isValidDateFormat = /^\d{4}-([1-9]|0[1-9]|1[0-2])-([1-9]|[0-2][0-9]|3[0-1]) ([0-9]|[0-1][0-9]|2[0-3]):([0-9]|[0-5][0-9])$/.test(dateTimeString);
    if ((optionalField && !dateTimeString) || isValidDateFormat) {
        return true;
    }
    return false;
}
