import React, { Component } from 'react';
import { connect } from 'react-redux';
import './TreatmentNzasa.css';
import ControlPane from '../../../../../UI/Controls/Control/ControlPane';
import { Form, Button, Header, Accordion, Icon, FormGroup } from 'semantic-ui-react';
import { DateInput } from 'semantic-ui-calendar-react-yz';
import Slider from 'rc-slider';
import Tooltip from 'rc-tooltip';
import CustomTextArea from '../../../../../UI/CustomTextArea/CustomTextArea';
import 'rc-slider/assets/index.css';
import EnoteAxios from '../../../../../axios';
import { FormatDate, GetTreatmentTabID, GetPatientFullNameShort, IsValidDate } from '../../../../../assets/helper';
import CustomLoader from '../../../../../UI/CustomLoader/CustomLoader';
import ConditionalArea from '../../../../../UI/ConditionalArea/ConditionalArea';
import QuickFillDropdown from '../../../../../UI/QuickFillDropdown/QuickFillDropdown';
import { NotificationManager } from 'react-notifications';
import { TREATMENT_DURATION } from '../../../../../assets/constants';
import moment from 'moment';
import FormHeader from '../../../../../UI/FormHeader/FormHeader';
import ConfrimDialog from '../../../../../UI/ConfrimDialog/ConfirmDialog';

const DATE = 'date';

const CURR_INJURY_HIST = 'currInjuryHistory';
const PATTERN_24_HRS = 'pattern24hours';
const EASING_FACTORS = 'easingFactors'

const AGGRAVATING_FACTORS = 'aggravatingFactors';
const MEDICATION = 'medication';
const PAIN_FREQUENCY = 'painFrequency';
const PREV_TREATMENT = 'prevTreatment';
const PROGRESSION = 'progression';
const NATURE_OF_PAIN = 'natureOfPain';
const PAIN_SCALE = 'painScale';
const PRESENTING_COMPLAINTS = 'presentingComplaints';
const PHYSICAL_FINDINGS = 'physicalFindings';
const RANGE_OF_MOVEMENT = 'rangeOfMovement';
const PALPATION = 'palpation';
const IMPEDIMENT_OF_FUNC = 'impedimentOfFunc';
const FUNC_SCALE = 'functionalScale';
const ASSESSMENT = 'assessment';
const TREATMENT_PRINCIPLES = 'treatmentPrinciples';
const TREATMENT_METHODS = 'treatmentMethods';
const TREATMENT_METHOD_NOTES = 'treatmentMethodNotes';
const POINTS_SELECTED = 'pointsSelected';
const ADVICES = 'advices';
const EXPECTED_DURATION = 'expectedDuration'
const TREATMENT_GOAL = 'treatmentGoal';

//TCM
const TCM_DIAGNOSIS = 'tcmDiagnosis';
const VITALITY = 'vitality';
const COMPLEXION = 'complexion';
const BODY_PALPATION = 'bodyPalpation';
const MERIDIAN_PALPATION = 'meridianPalpation';
const CHILLS_FEVER = 'chillsFever';
const PERSPIRATION = 'perspiration';
const PAIN = 'pain';
const SENSATION = 'sensation';
const BOWEL_MOTION = 'bowelMotion';
const BLADDER = 'bladder';
const APPETITE = 'appetite';
const THRIST = 'thrist';
const HEARING = 'hearing';
const VISION = 'vision';
const SLEEP = 'sleep';
const MOOD = 'mood';
const HEADACHE = 'headache';
const COUGH = 'cough';
const FATIGUE = 'fatigue';
const GYNAECOLOGY = 'gynaecology';
const TCM_ADDITIONAL = 'tcmAdditional';
const PULSE_QUALITY = 'pulseQuality';

const PULSE_LEFT = 'pulseLeft';
const PULSE_RIGHT = 'pulseRight';
const TONGUE_BODY = 'tongueBody';
const TONGUE_COATING = 'tongueCoating';
const SUBLINGUAL_VEINS = 'sublingualVeins';
const SIX_EVILS = 'sixEvils';
const SEVEN_EMOTIONS = 'sevenEmotions';
const BIAO_LI = 'biaoLi';
const HAN_RE = 'hanRe';
const XU_SHI = 'xuShi';
const YIN_YANG = 'yinYang';
const WU_XING = 'wuXing';

const CLAIM_STATUS = 'claimStatus';
const CLAIM_DATETIME = 'claimDateTime';
const FEE = 'fee';
const CLIENT_CONSENTED = 'clientConsented';

const ACUPUNCTURE = 'acupuncture';
const ELECTROACUPUNCTURE = 'electroAcupuncture';
const AURICLUARACUPUNCTURE = 'auricluarAcupuncture';
const GUASHA = 'guaSha';
const BLEEDING = 'bleeding';
const CUPPING = 'cupping';
const MOXIBUSTION = 'moxibustion';
const TUINA = 'tuiNa';
const TDP = 'tdp';
const LINIMENTS = 'liniments';
const HERBALPLASTERS = 'herbalPlasters';

const WIND = 'wind';
const COLD = 'cold';
const SUMMER_HEAT = 'summerHeat';
const DAMP = 'damp';
const DRYNESS = 'dryness';
const FIRE = 'fire';

const JOY = 'joy';
const ANGER = 'anger';
const ANXIETY = 'anxiety';
const WORRY = 'worry';
const SADNESS = 'sadness';
const FEAR = 'fear';
const FRIGHT = 'fright';

const EXTERNAL_BIAO = 'externalBiao';
const INTERNAL_LI = 'internalLi';

const COLD_HAN = 'coldHan';
const HOT_RE = 'hotRe';

const XU = 'xu';
const SHI = 'shi';

const YIN = 'yin';
const YANG = 'yang';

const SLIDER_SCALE_MARKS = {
    0: 0,
    1: 1,
    2: 2,
    3: 3,
    4: 4,
    5: 5,
    6: 6,
    7: 7,
    8: 8,
    9: 9,
    10: 10
};

const NZASA_TREATMENT_METHOD_LIST = {
    [ACUPUNCTURE]: 1,
    [ELECTROACUPUNCTURE]: 2,
    [AURICLUARACUPUNCTURE]: 4,
    [GUASHA]: 8,
    [BLEEDING]: 16,
    [CUPPING]: 32,
    [MOXIBUSTION]: 64,
    [TUINA]: 128,
    [TDP]: 256,
    [LINIMENTS]: 512,
    [HERBALPLASTERS]: 1024
}

const SIX_EVILS_LIST = {
    [WIND]: 1,
    [COLD]: 2,
    [SUMMER_HEAT]: 4,
    [DAMP]: 8,
    [DRYNESS]: 16,
    [FIRE]: 32,
}

const SEVEN_EMOTIONS_LIST = {
    [JOY]: 1,
    [ANGER]: 2,
    [ANXIETY]: 4,
    [WORRY]: 8,
    [SADNESS]: 16,
    [FEAR]: 32,
    [FRIGHT]: 64,
}

const BIAO_LI_LIST = {
    [EXTERNAL_BIAO]: 1,
    [INTERNAL_LI]: 2,
}

const HAN_RE_LIST = {
    [COLD_HAN]: 1,
    [HOT_RE]: 2,
}

const XU_SHI_LIST = {
    [XU]: 1,
    [SHI]: 2,
}

const YIN_YANG_LIST = {
    [YIN]: 0,
    [YANG]: 1
}

const TREATMENT_TEMPLATE_NAME_TO_ID = {
    [CURR_INJURY_HIST]: 36,
    [EASING_FACTORS]: 37,
    [AGGRAVATING_FACTORS]: 38,
    [MEDICATION]: 39,
    [PREV_TREATMENT]: 40,
    [PRESENTING_COMPLAINTS]: 41,
    [PHYSICAL_FINDINGS]: 42,
    [RANGE_OF_MOVEMENT]: 43,
    [PALPATION]: 44,
    [IMPEDIMENT_OF_FUNC]: 45,
    [ASSESSMENT]: 46,
    [TREATMENT_PRINCIPLES]: 47,

    [ACUPUNCTURE]: 48,
    [GUASHA]: 49,
    [ELECTROACUPUNCTURE]: 50,
    [AURICLUARACUPUNCTURE]: 51,

    [BLEEDING]: 52,
    [CUPPING]: 53,
    [MOXIBUSTION]: 54,
    [TUINA]: 55,
    [TDP]: 56,
    [LINIMENTS]: 57,
    [HERBALPLASTERS]: 58,

    [POINTS_SELECTED]: 59,
    [ADVICES]: 60,
    [TREATMENT_GOAL]: 61,
}

const PATTERN_24_HRS_LIST = [
    {
        text: 'Worse in the mornings',
        value: 'Worse in the mornings'
    },
    {
        text: 'Worse at night',
        value: 'Worse at night'
    },
    {
        text: 'Sleep Disturbance',
        value: 'Sleep Disturbance'
    },
    {
        text: 'Worse after movement',
        value: 'Worse after movement'
    }
]

const PAIN_FREQUENCY_LIST = [
    {
        text: 'Constant',
        value: 'Constant'
    },
    {
        text: 'Intermittent',
        value: 'Intermittent'
    },
    {
        text: 'Occasional',
        value: 'Occasional'
    }
];

const PROGRESSION_LIST = [
    {
        text: 'Same',
        value: 'Same'
    },
    {
        text: 'Worsening',
        value: 'Worsening'
    },
    {
        text: 'Improving',
        value: 'Improving'
    },
];

const NATURE_OF_PAIN_LIST = [
    {
        text: 'Aching',
        value: 'Aching'
    },
    {
        text: 'Burning',
        value: 'Burning'
    },
    {
        text: 'Cramping',
        value: 'Cramping'
    },
    {
        text: 'Crushing',
        value: 'Crushing'
    },
    {
        text: 'Discomfort',
        value: 'Discomfort'
    },
    {
        text: 'Dull',
        value: 'Dull'
    },
    {
        text: 'Heaviness',
        value: 'Heaviness'
    },
    {
        text: 'Numbness',
        value: 'Numbness'
    },
    {
        text: 'Piercing',
        value: 'Piercing'
    },
    {
        text: 'Pressure',
        value: 'Pressure'
    },
    {
        text: 'Radiating',
        value: 'Radiating'
    },
    {
        text: 'Ripping',
        value: 'Ripping'
    },
    {
        text: 'Sharp',
        value: 'Sharp'
    },
    {
        text: 'Shooting',
        value: 'Shooting'
    },
    {
        text: 'Squeezing',
        value: 'Squeezing'
    },
    {
        text: 'Stabbing',
        value: 'Stabbing'
    },
    {
        text: 'Tearing',
        value: 'Tearing'
    },
    {
        text: 'Tenderness',
        value: 'Tenderness'
    },
    {
        text: 'Throbbing',
        value: 'Throbbing'
    },
    {
        text: 'Tingling',
        value: 'Tingling'
    },
]

class TreatmentNzasa extends Component {
    state = {
        index: 1,
        nzasa: {
            [DATE]: '',
            [CURR_INJURY_HIST]: '',
            [PATTERN_24_HRS]: '',
            [EASING_FACTORS]: '',
            [AGGRAVATING_FACTORS]: '',
            [MEDICATION]: '',
            [PAIN_FREQUENCY]: '',
            [PREV_TREATMENT]: '',
            [PROGRESSION]: '',
            [NATURE_OF_PAIN]: '',
            [PAIN_SCALE]: 0,
            [PRESENTING_COMPLAINTS]: '',
            [PHYSICAL_FINDINGS]: '',
            [RANGE_OF_MOVEMENT]: '',
            [PALPATION]: '',
            [IMPEDIMENT_OF_FUNC]: '',
            [FUNC_SCALE]: 0,
            [ASSESSMENT]: '',
            [TREATMENT_PRINCIPLES]: '',
            [TREATMENT_METHODS]: 0,
            [TREATMENT_METHOD_NOTES]: '',
            [POINTS_SELECTED]: '',
            [ADVICES]: '',
            [EXPECTED_DURATION]: 60,
            [TREATMENT_GOAL]: '',

            //TCM
            [TCM_DIAGNOSIS]: '',
            [VITALITY]: 0,
            [COMPLEXION]: '',
            [BODY_PALPATION]: '',
            [MERIDIAN_PALPATION]: '',
            [CHILLS_FEVER]: '',
            [PERSPIRATION]: '',
            [PAIN]: '',
            [SENSATION]: '',
            [BOWEL_MOTION]: '',
            [BLADDER]: '',
            [APPETITE]: '',
            [THRIST]: '',
            [HEARING]: '',
            [VISION]: '',
            [SLEEP]: '',
            [MOOD]: '',
            [HEADACHE]: '',
            [COUGH]: '',
            [FATIGUE]: '',
            [GYNAECOLOGY]: '',
            [TCM_ADDITIONAL]: '',
            [PULSE_QUALITY]: '',

            [PULSE_LEFT]: '',
            [PULSE_RIGHT]: '',
            [TONGUE_BODY]: '',
            [TONGUE_COATING]: '',
            [SUBLINGUAL_VEINS]: '',

            [SIX_EVILS]: 0,
            [SEVEN_EMOTIONS]: 0,
            [BIAO_LI]: 0,
            [HAN_RE]: 0,
            [XU_SHI]: 0,
            [YIN_YANG]: 0,
            [WU_XING]: '',

            // CLAIM
            [CLAIM_STATUS]: 0,
            [CLAIM_DATETIME]: '',
            [FEE]: 0,

            [CLIENT_CONSENTED]: true,
        },
        nzasaTreatmentMethods: {
            [ACUPUNCTURE]: false,
            [ELECTROACUPUNCTURE]: false,
            [AURICLUARACUPUNCTURE]: false,
            [GUASHA]: false,
            [BLEEDING]: false,
            [CUPPING]: false,
            [MOXIBUSTION]: false,
            [TUINA]: false,
            [TDP]: false,
            [LINIMENTS]: false,
            [HERBALPLASTERS]: false
        },
        sixEvilsSelection: {
            [WIND]: false,
            [COLD]: false,
            [SUMMER_HEAT]: false,
            [DAMP]: false,
            [DRYNESS]: false,
            [FIRE]: false
        },
        sevenEmotionsSelection: {
            [JOY]: false,
            [ANGER]: false,
            [ANXIETY]: false,
            [WORRY]: false,
            [SADNESS]: false,
            [FEAR]: false,
            [FRIGHT]: false
        },
        biaoLiSelection: {
            [EXTERNAL_BIAO]: false,
            [INTERNAL_LI]: false
        },
        hanReSelection: {
            [COLD_HAN]: false,
            [HOT_RE]: false
        },
        xuShiSelection: {
            [XU]: false,
            [SHI]: false
        },
        templateOptions: {
            [CURR_INJURY_HIST]: [],
            [EASING_FACTORS]: [],
            [AGGRAVATING_FACTORS]: [],
            [MEDICATION]: [],
            [PREV_TREATMENT]: [],
            [PRESENTING_COMPLAINTS]: [],
            [PHYSICAL_FINDINGS]: [],
            [RANGE_OF_MOVEMENT]: [],
            [PALPATION]: [],
            [IMPEDIMENT_OF_FUNC]: [],
            [ASSESSMENT]: [],
            [TREATMENT_PRINCIPLES]: [],
            [TREATMENT_GOAL]: [],
            [POINTS_SELECTED]: [],
            [ADVICES]: [],

            [ACUPUNCTURE]: [],
            [ELECTROACUPUNCTURE]: [],
            [AURICLUARACUPUNCTURE]: [],
            [GUASHA]: [],
            [BLEEDING]: [],
            [CUPPING]: [],
            [MOXIBUSTION]: [],
            [TUINA]: [],
            [TDP]: [],
            [LINIMENTS]: [],
            [HERBALPLASTERS]: [],
        },
        createMode: true,
        showTcmForm: false,
        loading: true,
        showTreatmentCountAlert: false,
        confirmationMsg: '',
        shouldCloseOnSave: false
    }

    async componentDidMount() {
        if (this.props.user) {
            if (this.props.data.treatmentId === 0) {
                await EnoteAxios.get('/api/treatment/GetLatestTreatment', {
                    params: {
                        patientId: this.props.data.patient.patientId,
                        injuryId: this.props.data.injuryId
                    }
                }).then(response => {
                    if (response && response.data) {
                        this.populateTreatmentDetail(response.data, true);
                    } else {
                        this.setState(prevState => ({
                            index: 1,
                            nzasa: {
                                ...prevState.nzasa,
                                [TCM_DIAGNOSIS]: 'Qi Stagnation and blood stasis'   //Default Value
                            }
                        }));
                    }
                }).catch(err => {
                    console.log(err);
                });
            } else {
                await EnoteAxios.get('/api/treatment/GetTreatment', {
                    params: {
                        treatmentId: this.props.data.treatmentId
                    }
                }).then(response => {
                    if (response && response.data) {
                        this.populateTreatmentDetail(response.data, false);
                    } else {
                        console.log('invalid response from GetTreatment. No-op');
                    }
                }).catch(err => {
                    console.log(err);
                })
            }

            this.populateTemplateItems();
        }
        this.setState({
            loading: false
        })
    }

    componentDidUpdate(prevProps) {
        if (this.props.templateItems !== prevProps.templateItems) {
            this.populateTemplateItems();
        }
    }

    populateTemplateItems = () => {
        let options = {};
        if (this.props.templateItems) {
            Object.keys(this.state.templateOptions).forEach(key => {
                let templateID = TREATMENT_TEMPLATE_NAME_TO_ID[key]
                if (templateID) {
                    options[key] = this.props.templateItems[templateID] ? this.props.templateItems[templateID] : [];
                } else {
                    options[key] = [];
                }
            });
        }

        this.setState({
            templateOptions: options
        });
    }

    populateTreatmentDetail = (data, isNew) => {
        let { treatmentId, injuryId, patientId, treatmentIndex, ...treatmentDetail } = data;
        let treatmentToDisplay = {
            ...treatmentDetail,
            [DATE]: FormatDate(treatmentDetail.date),
            [CLAIM_DATETIME]: FormatDate(treatmentDetail.claimDateTime, 'YYYY-MM-DD HH:mm:ss')
        }

        if (isNew) {
            treatmentIndex++;
            treatmentToDisplay = {
                ...treatmentDetail,
                [DATE]: FormatDate(moment(treatmentDetail.date).add(1, 'day')),
                [PRESENTING_COMPLAINTS]: '',
                [CURR_INJURY_HIST]: '',
                [PREV_TREATMENT]: '',
                [MEDICATION]: '',
                [AGGRAVATING_FACTORS]: '',
                [EASING_FACTORS]: '',
                [PATTERN_24_HRS]: '',
                [PAIN_FREQUENCY]: '',
                [PROGRESSION]: '',
                [NATURE_OF_PAIN]: '',
                [ASSESSMENT]: 'No fracture, no deformation, no deep vein injury, no radiation sensation',
                [TCM_DIAGNOSIS]: '',

                [CLAIM_STATUS]: 0,
                [CLAIM_DATETIME]: '',
                [CLIENT_CONSENTED]: true,
            }
        }


        this.setState(prevState => ({
            nzasa: treatmentToDisplay,
            index: treatmentIndex,
            nzasaTreatmentMethods: this.getSelectionsFromInt(prevState.nzasaTreatmentMethods, NZASA_TREATMENT_METHOD_LIST, treatmentDetail.treatmentMethods),
            sixEvilsSelection: this.getSelectionsFromInt(prevState.sixEvilsSelection, SIX_EVILS_LIST, treatmentDetail.sixEvils),
            sevenEmotionsSelection: this.getSelectionsFromInt(prevState.sevenEmotionsSelection, SEVEN_EMOTIONS_LIST, treatmentDetail.sevenEmotions),
            biaoLiSelection: this.getSelectionsFromInt(prevState.biaoLiSelection, BIAO_LI_LIST, treatmentDetail.biaoLi),
            hanReSelection: this.getSelectionsFromInt(prevState.hanReSelection, HAN_RE_LIST, treatmentDetail.hanRe),
            xuShiSelection: this.getSelectionsFromInt(prevState.xuShiSelection, XU_SHI_LIST, treatmentDetail.xuShi),
            createMode: isNew
        }));

        if (isNew && this.props.onTouch)
            this.props.onTouch();
    }

    validateForm = () => {
        let valid = true;
        valid = FormatDate(this.state.nzasa.date) !== '';

        return valid;
    }

    inputChangeHandler = (e, { name, value }) => {
        this.setState(prevState => ({
            nzasa: {
                ...prevState.nzasa,
                [name]: value
            }
        }));

        if (!this.props.touched && this.props.onTouch) {
            this.props.onTouch();
        }
    }

    checkBoxChangeHandler = (e, { name, checked }) => {
        this.setState(prevState => ({
            nzasa: {
                ...prevState.nzasa,
                [name]: checked
            }
        }));

        if (!this.props.touched && this.props.onTouch) {
            this.props.onTouch();
        }
    }

    checkboxGroupChangeHandler = (e, data) => {
        this.setState(prevState => ({
            [data.checkboxgroup]: {
                ...prevState[data.checkboxgroup],
                [data.name]: data.checked
            },
            nzasa: {
                ...prevState.nzasa,
                [data.target]: prevState.nzasa[data.target] + (data.checked ? data.methodvalue : -data.methodvalue)
            }
        }));

        if (!this.props.touched && this.props.onTouch) {
            this.props.onTouch();
        }
    }

    radioChangeHandler = (e, { name, value }) => {
        this.setState(prevState => ({
            nzasa: {
                ...prevState.nzasa,
                [name]: value
            }
        }));

        if (!this.props.touched && this.props.onTouch) {
            this.props.onTouch();
        }
    }

    vitalityInputChangeHandler = (value) => {
        this.setState(prevState => ({
            nzasa: {
                ...prevState.nzasa,
                vitality: value
            }
        }));

        if (!this.props.touched && this.props.onTouch) {
            this.props.onTouch();
        }
    }

    painScaleInputChangeHandler = (value) => {
        this.setState(prevState => ({
            nzasa: {
                ...prevState.nzasa,
                painScale: value
            }
        }));

        if (!this.props.touched && this.props.onTouch) {
            this.props.onTouch();
        }
    }

    funcScaleScaleInputChangeHandler = (value) => {
        this.setState(prevState => ({
            nzasa: {
                ...prevState.nzasa,
                functionalScale: value
            }
        }));

        if (!this.props.touched && this.props.onTouch) {
            this.props.onTouch();
        }
    }

    sliderHandle = (props) => {
        const { value, dragging, index, ...restProps } = props;
        return (
            <Tooltip
                prefixCls="rc-slider-tooltip"
                overlay={value}
                visible={dragging}
                placement="top"
                key={index}
            >
                <Slider.Handle value={value} {...restProps} />
            </Tooltip>
        );
    };

    nzasaTreatmentMethodChangeHandler = (e, data) => {
        let newNotes = null;
        if (data.checked && this.state.templateOptions[data.name].length > 0 && this.state.templateOptions[data.name][0].items.length > 0) {
            let firstItem = this.state.templateOptions[data.name][0].items[0].content;
            if (firstItem) {
                newNotes = this.state.nzasa.treatmentMethodNotes.length > 0 ? this.state.nzasa.treatmentMethodNotes + '\n' + firstItem : firstItem
            }
        }
        this.setState(prevState => ({
            nzasaTreatmentMethods: {
                ...prevState.nzasaTreatmentMethods,
                [data.name]: data.checked
            },
            nzasa: {
                ...prevState.nzasa,
                treatmentMethods: prevState.nzasa.treatmentMethods + (data.checked ? data.methodvalue : -data.methodvalue),
                treatmentMethodNotes: newNotes || prevState.nzasa.treatmentMethodNotes
            }
        }));

        if (!this.props.touched && this.props.onTouch) {
            this.props.onTouch();
        }
    }

    getSelectionsFromInt = (targetList, refValueList, val) => {
        let selections = {};
        Object.keys(targetList).forEach(key => {
            selections[key] = (val & refValueList[key]) === refValueList[key] ? true : false;
        });

        return selections;
    }

    getTreatmentTitle = () => {
        return 'Treatment: ' + GetPatientFullNameShort(this.props.data.patient.firstName, this.props.data.patient.lastName) + ' ' + FormatDate(this.state.nzasa.date);
    }

    getTreatmentUID = (treatment) => {
        return treatment.injuryId + '_' + treatment.treatmentIndex;
    }

    saveTreatmentHandler = async (bypassLimitCheck = false, closeOnSave = false) => {
        if (!this.IsFormValid(this.state.nzasa)) {
            return;
        }
        if (closeOnSave) {
            this.setState({
                shouldCloseOnSave: true
            });
        }

        if (bypassLimitCheck) {
            this.setState({
                showTreatmentCountAlert: false,
                confirmationMsg: ''
            });
        }

        if (this.props.user) {
            this.toggleLoaddingState(true);

            let treatmentDetail = {
                ...this.state.nzasa,
                treatmentId: this.props.data.treatmentId,
                patientId: this.props.data.patient.patientId,
                injuryId: this.props.data.injuryId,
                treatmentIndex: this.state.index,
                userId: this.props.user.userId
            }
            if (this.state.createMode) {
                await EnoteAxios.post('/api/treatment/AddTreatmentNZASA', treatmentDetail, {
                    params: {
                        bypassCheck: bypassLimitCheck
                    }
                }).then(response => {
                    if (response.data && response.data.responseData) {
                        const { treatmentId, injuryId, treatmentIndex } = response.data.responseData;
                        this.toggleLoaddingState(false);
                        this.props.onTabMetadataChange(this.props.tabID, GetTreatmentTabID(this.props.data.patient.patientId, injuryId, treatmentIndex),
                            this.getTreatmentTitle(), 'p_' + this.props.data.patient.patientId + ' i_' + injuryId, {
                            treatmentId: treatmentId,
                            injuryId: injuryId,
                            treatmentIndex: treatmentIndex,
                            patient: this.props.data.patient
                        }
                        );
                        if (closeOnSave && this.props.onTabClose) {
                            this.props.onTabClose();
                            return;
                        }
                    } else {
                        this.toggleLoaddingState(false);
                    }
                }).catch(err => {
                    console.log(err);
                    if (err && err.response && err.response.status === 422 && err.response.data && err.response.data.confirmation) {
                        this.setState({
                            showTreatmentCountAlert: true,
                            confirmationMsg: err.response.data.confirmation
                        });
                    }
                    this.toggleLoaddingState(false);
                });
            } else {
                await EnoteAxios.put('/api/treatment/EditTreatmentNZASA', treatmentDetail, {
                    params: {
                        currentIndex: this.state.index,
                        bypassCheck: bypassLimitCheck
                    }
                }).then(response => {
                    if (response.data && response.data.responseData) {
                        const { treatmentId, injuryId, treatmentIndex } = response.data.responseData;
                        this.toggleLoaddingState(false);
                        this.props.onTabMetadataChange(this.props.tabID, GetTreatmentTabID(this.props.data.patient.patientId, injuryId, treatmentIndex),
                            this.getTreatmentTitle(), 'p_' + this.props.data.patient.patientId + ' i_' + injuryId, {
                            treatmentId: treatmentId,
                            injuryId: injuryId,
                            treatmentIndex: treatmentIndex,
                            patient: this.props.data.patient
                        }
                        );
                        if (closeOnSave && this.props.onTabClose) {
                            this.props.onTabClose();
                            return;
                        }
                    } else {
                        this.toggleLoaddingState(false);
                    }
                }).catch(err => {
                    console.log(err);
                    if (err && err.response && err.response.status === 422 && err.response.data && err.response.data.confirmation) {
                        this.setState({
                            showTreatmentCountAlert: true,
                            confirmationMsg: err.response.data.confirmation
                        });
                    }
                    this.toggleLoaddingState(false);
                });
            }

        }
    }

    IsFormValid(treatment) {
        let errorMessage = '';
        let isValid = true;
        if (!IsValidDate(treatment.date)) {
            errorMessage += 'Treatment date and time are required.';
        }

        if (this.props.data.injuryDate && moment(treatment.date).isBefore(moment(this.props.data.injuryDate))) {
            errorMessage += 'Treatment date cannot be earlier than the injury date';
        }

        if (treatment.expectedDuration <= 0) {
            errorMessage += 'Invalid Expected Duration';
        }

        if (errorMessage.length > 0) {
            NotificationManager.error(errorMessage, 'Failed');
            isValid = false;
        }

        return isValid;
    }

    treatmentAlertLimitCancelHandler = () => {
        this.setState({
            showTreatmentCountAlert: false
        });
    }

    toggleLoaddingState = (isLoading) => {
        this.setState({
            loading: isLoading
        });
    }

    quickFillInputClickHandler = (e, data) => {
        let newData = this.state.nzasa[data.associatedinputname].length > 0 ? this.state.nzasa[data.associatedinputname] + '\n' + data.itemcontent : data.itemcontent;

        this.setState(prevState => ({
            nzasa: {
                ...prevState.nzasa,
                [data.associatedinputname]: newData
            }
        }));

        if (!this.props.touched && this.props.onTouch) {
            this.props.onTouch();
        }
    }

    treatmentNotesQuickFillInputClickHandler = (e, data) => {
        this.quickFillInputClickHandler(e, data);
        let methodVal = NZASA_TREATMENT_METHOD_LIST[data.checkitem];
        this.setState(prevState => ({
            nzasa: {
                ...prevState.nzasa,
                [TREATMENT_METHODS]: prevState.nzasaTreatmentMethods[data.checkitem] ?  prevState.nzasa.treatmentMethods : prevState.nzasa.treatmentMethods + methodVal
            },
            nzasaTreatmentMethods: {
                ...prevState.nzasaTreatmentMethods,
                [data.checkitem]: true
            }
        }));
    }

    render() {
        let treatmentForm = [];
        if (!this.props.user) {
            return <div>Unauthorized</div>
        }

        if (this.state.index === 1) {
            treatmentForm.push(
                <React.Fragment key='nzasa_tcm'>
                    <Accordion>
                        <Accordion.Title as={Header} active={this.state.showTcmForm} onClick={() => this.setState(prevState => ({ showTcmForm: !prevState.showTcmForm }))}>
                            <Icon name='dropdown' /> TCM Examination
                        </Accordion.Title>
                        <Accordion.Content active={this.state.showTcmForm}>
                            <Form.Group widths='16'>
                                <Form.Input width='16' type='text' label='TCM Diagnosis' name={TCM_DIAGNOSIS} value={this.state.nzasa.tcmDiagnosis} onChange={this.inputChangeHandler} placeholder='TCM Diagnosis' />
                            </Form.Group>
                            <Form.Group widths='equal'>
                                <Form.Field>
                                    <label>Vatality</label>
                                    <Slider
                                        min={0}
                                        max={10}
                                        value={this.state.nzasa.vitality}
                                        onChange={this.vitalityInputChangeHandler}
                                        dots
                                        marks={SLIDER_SCALE_MARKS}
                                        handle={this.sliderHandle}
                                    />
                                </Form.Field>
                                <Form.Input type='text' label='Complexion' name={COMPLEXION} value={this.state.nzasa.complexion} onChange={this.inputChangeHandler} placeholder='Complexion' />
                            </Form.Group>

                            <Form.Group widths='equal'>
                                <Form.Input type='text' label='Body Palpation' name={BODY_PALPATION} value={this.state.nzasa.bodyPalpation} onChange={this.inputChangeHandler} placeholder='Body Palpation' />
                                <Form.Input type='text' label='Meridian Palpation' name={MERIDIAN_PALPATION} value={this.state.nzasa.meridianPalpation} onChange={this.inputChangeHandler} placeholder='Meridian Palpation' />
                            </Form.Group>

                            <Form.Group widths='equal'>
                                <Form.Input type='text' label='Chills / Fever' name={CHILLS_FEVER} value={this.state.nzasa.chillsFever} onChange={this.inputChangeHandler} placeholder='Chills / Fever' />
                                <Form.Input type='text' label='Perspiration' name={PERSPIRATION} value={this.state.nzasa.perspiration} onChange={this.inputChangeHandler} placeholder='Perspiration' />
                            </Form.Group>
                            <Form.Group widths='equal'>
                                <Form.Input type='text' label='Pain' name={PAIN} value={this.state.nzasa.pain} onChange={this.inputChangeHandler} placeholder='Pain' />
                                <Form.Input type='text' label='Sensation' name={SENSATION} value={this.state.nzasa.sensation} onChange={this.inputChangeHandler} placeholder='Sensation' />
                            </Form.Group>
                            <Form.Group widths='equal'>
                                <Form.Input type='text' label='Bowel Motion' name={BOWEL_MOTION} value={this.state.nzasa.bowelMotion} onChange={this.inputChangeHandler} placeholder='Bowel Motion' />
                                <Form.Input type='text' label='Bladder' name={BLADDER} value={this.state.nzasa.bladder} onChange={this.inputChangeHandler} placeholder='Bladder' />
                            </Form.Group>
                            <Form.Group widths='equal'>
                                <Form.Input type='text' label='Appetite' name={APPETITE} value={this.state.nzasa.appetite} onChange={this.inputChangeHandler} placeholder='Appetite' />
                                <Form.Input type='text' label='Thrist' name={THRIST} value={this.state.nzasa.thrist} onChange={this.inputChangeHandler} placeholder='Thrist' />
                            </Form.Group>

                            <Form.Group widths='equal'>
                                <Form.Input type='text' label='Hearing' name={HEARING} value={this.state.nzasa.hearing} onChange={this.inputChangeHandler} placeholder='Hearing' />
                                <Form.Input type='text' label='Vision' name={VISION} value={this.state.nzasa.vision} onChange={this.inputChangeHandler} placeholder='Vision' />
                            </Form.Group>
                            <Form.Group widths='equal'>
                                <Form.Input type='text' label='Sleep' name={SLEEP} value={this.state.nzasa.sleep} onChange={this.inputChangeHandler} placeholder='Sleep' />
                                <Form.Input type='text' label='Mood' name={MOOD} value={this.state.nzasa.mood} onChange={this.inputChangeHandler} placeholder='Mood' />
                            </Form.Group>

                            <Form.Group widths='equal'>
                                <Form.Input type='text' label='Headache' name={HEADACHE} value={this.state.nzasa.headache} onChange={this.inputChangeHandler} placeholder='Headache' />
                                <Form.Input type='text' label='Cough' name={COUGH} value={this.state.nzasa.cough} onChange={this.inputChangeHandler} placeholder='Cough' />
                            </Form.Group>
                            <Form.Group widths='equal'>
                                <Form.Input type='text' label='Fatigue' name={FATIGUE} value={this.state.nzasa.fatigue} onChange={this.inputChangeHandler} placeholder='Fatigue' />
                                <Form.Input type='text' label='Gynaecology' name={GYNAECOLOGY} value={this.state.nzasa.gynaecology} onChange={this.inputChangeHandler} placeholder='Gynaecology' />
                            </Form.Group>
                            <Form.Group widths='equal'>
                                <Form.Input type='text' label='Additional' name={TCM_ADDITIONAL} value={this.state.nzasa.tcmAdditional} onChange={this.inputChangeHandler} placeholder='Additional' />
                            </Form.Group>
                            <Header dividing>Pulse</Header>
                            <Form.Group widths='equal'>
                                <Form.Input type='text' label='Overall Quality' name={PULSE_QUALITY} value={this.state.nzasa.pulseQuality} onChange={this.inputChangeHandler} placeholder='Overall Quality' />
                                <Form.Input type='text' label='Left' name={PULSE_LEFT} value={this.state.nzasa.pulseLeft} onChange={this.inputChangeHandler} placeholder='Left Pulse' />
                                <Form.Input type='text' label='Right' name={PULSE_RIGHT} value={this.state.nzasa.pulseRight} onChange={this.inputChangeHandler} placeholder='Right Pulse' />
                            </Form.Group>
                            <Header dividing>Tongue</Header>
                            <Form.Group widths='equal'>
                                <Form.Input type='text' label='Tongue Body' name={TONGUE_BODY} value={this.state.nzasa.tongueBody} onChange={this.inputChangeHandler} placeholder='Tongue Body' />
                                <Form.Input type='text' label='Tongue Coating' name={TONGUE_COATING} value={this.state.nzasa.tongueCoating} onChange={this.inputChangeHandler} placeholder='Tongue Coating' />
                                <Form.Input type='text' label='Sublingual Veins' name={SUBLINGUAL_VEINS} value={this.state.nzasa.sublingualVeins} onChange={this.inputChangeHandler} placeholder='Sublingual Veins' />
                            </Form.Group>
                            <Header dividing>Diagnosis</Header>
                            <FormGroup className='tcm-diagnosis-groups'>
                                <Form.Field width='3'>
                                    <label>Six Evils</label>
                                    <Form.Checkbox label='Wind' name={WIND} checkboxgroup='sixEvilsSelection' target={SIX_EVILS} methodvalue={SIX_EVILS_LIST[WIND]} checked={this.state.sixEvilsSelection[WIND]} onChange={this.checkboxGroupChangeHandler}></Form.Checkbox>
                                    <Form.Checkbox label='Cold' name={COLD} checkboxgroup='sixEvilsSelection' target={SIX_EVILS} methodvalue={SIX_EVILS_LIST[COLD]} checked={this.state.sixEvilsSelection[COLD]} onChange={this.checkboxGroupChangeHandler}></Form.Checkbox>
                                    <Form.Checkbox label='Summer Heat' name={SUMMER_HEAT} checkboxgroup='sixEvilsSelection' target={SIX_EVILS} methodvalue={SIX_EVILS_LIST[SUMMER_HEAT]} checked={this.state.sixEvilsSelection[SUMMER_HEAT]} onChange={this.checkboxGroupChangeHandler}></Form.Checkbox>
                                    <Form.Checkbox label='Damp' name={DAMP} checkboxgroup='sixEvilsSelection' target={SIX_EVILS} methodvalue={SIX_EVILS_LIST[DAMP]} checked={this.state.sixEvilsSelection[DAMP]} onChange={this.checkboxGroupChangeHandler}></Form.Checkbox>
                                    <Form.Checkbox label='Dryness' name={DRYNESS} checkboxgroup='sixEvilsSelection' target={SIX_EVILS} methodvalue={SIX_EVILS_LIST[DRYNESS]} checked={this.state.sixEvilsSelection[DRYNESS]} onChange={this.checkboxGroupChangeHandler}></Form.Checkbox>
                                    <Form.Checkbox label='Fire' name={FIRE} checkboxgroup='sixEvilsSelection' target={SIX_EVILS} methodvalue={SIX_EVILS_LIST[FIRE]} checked={this.state.sixEvilsSelection[FIRE]} onChange={this.checkboxGroupChangeHandler}></Form.Checkbox>

                                </Form.Field>
                                <Form.Field width='3'>
                                    <label>Seven Emotions</label>
                                    <Form.Checkbox label='Joy' name={JOY} checkboxgroup='sevenEmotionsSelection' target={SEVEN_EMOTIONS} methodvalue={SEVEN_EMOTIONS_LIST[JOY]} checked={this.state.sevenEmotionsSelection[JOY]} onChange={this.checkboxGroupChangeHandler}></Form.Checkbox>
                                    <Form.Checkbox label='Anger' name={ANGER} checkboxgroup='sevenEmotionsSelection' target={SEVEN_EMOTIONS} methodvalue={SEVEN_EMOTIONS_LIST[ANGER]} checked={this.state.sevenEmotionsSelection[ANGER]} onChange={this.checkboxGroupChangeHandler}></Form.Checkbox>
                                    <Form.Checkbox label='Anxiety' name={ANXIETY} checkboxgroup='sevenEmotionsSelection' target={SEVEN_EMOTIONS} methodvalue={SEVEN_EMOTIONS_LIST[ANXIETY]} checked={this.state.sevenEmotionsSelection[ANXIETY]} onChange={this.checkboxGroupChangeHandler}></Form.Checkbox>
                                    <Form.Checkbox label='Worry' name={WORRY} checkboxgroup='sevenEmotionsSelection' target={SEVEN_EMOTIONS} methodvalue={SEVEN_EMOTIONS_LIST[WORRY]} checked={this.state.sevenEmotionsSelection[WORRY]} onChange={this.checkboxGroupChangeHandler}></Form.Checkbox>
                                    <Form.Checkbox label='Sadness' name={SADNESS} checkboxgroup='sevenEmotionsSelection' target={SEVEN_EMOTIONS} methodvalue={SEVEN_EMOTIONS_LIST[SADNESS]} checked={this.state.sixEvilsSelection[SADNESS]} onChange={this.checkboxGroupChangeHandler}></Form.Checkbox>
                                    <Form.Checkbox label='Fear' name={FEAR} checkboxgroup='sevenEmotionsSelection' target={SEVEN_EMOTIONS} methodvalue={SEVEN_EMOTIONS_LIST[FEAR]} checked={this.state.sevenEmotionsSelection[FEAR]} onChange={this.checkboxGroupChangeHandler}></Form.Checkbox>
                                    <Form.Checkbox label='Fright' name={FRIGHT} checkboxgroup='sevenEmotionsSelection' target={SEVEN_EMOTIONS} methodvalue={SEVEN_EMOTIONS_LIST[FRIGHT]} checked={this.state.sevenEmotionsSelection[FRIGHT]} onChange={this.checkboxGroupChangeHandler}></Form.Checkbox>
                                </Form.Field>
                                <Form.Field width='3'>
                                    <label>Biao Li</label>
                                    <Form.Checkbox label='External Biao' name={EXTERNAL_BIAO} checkboxgroup='biaoLiSelection' target={BIAO_LI} methodvalue={BIAO_LI_LIST[EXTERNAL_BIAO]} checked={this.state.biaoLiSelection[EXTERNAL_BIAO]} onChange={this.checkboxGroupChangeHandler}></Form.Checkbox>
                                    <Form.Checkbox label='Internal Li' name={INTERNAL_LI} checkboxgroup='biaoLiSelection' target={BIAO_LI} methodvalue={BIAO_LI_LIST[INTERNAL_LI]} checked={this.state.biaoLiSelection[INTERNAL_LI]} onChange={this.checkboxGroupChangeHandler}></Form.Checkbox>
                                </Form.Field>
                                <Form.Field width='3'>
                                    <label>Han Re</label>
                                    <Form.Checkbox label='Cold Han' name={COLD_HAN} checkboxgroup='hanReSelection' target={HAN_RE} methodvalue={HAN_RE_LIST[COLD_HAN]} checked={this.state.hanReSelection[COLD_HAN]} onChange={this.checkboxGroupChangeHandler}></Form.Checkbox>
                                    <Form.Checkbox label='Hot Re' name={HOT_RE} checkboxgroup='hanReSelection' target={HAN_RE} methodvalue={HAN_RE_LIST[HOT_RE]} checked={this.state.hanReSelection[HOT_RE]} onChange={this.checkboxGroupChangeHandler}></Form.Checkbox>
                                </Form.Field>
                                <Form.Field width='3'>
                                    <label>Xu Shi</label>
                                    <Form.Checkbox label='Deficiency Xu' name={XU} checkboxgroup='xuShiSelection' target={XU_SHI} methodvalue={XU_SHI_LIST[XU]} checked={this.state.xuShiSelection[XU]} onChange={this.checkboxGroupChangeHandler}></Form.Checkbox>
                                    <Form.Checkbox label='Excess Shi' name={SHI} checkboxgroup='xuShiSelection' target={XU_SHI} methodvalue={XU_SHI_LIST[SHI]} checked={this.state.xuShiSelection[SHI]} onChange={this.checkboxGroupChangeHandler}></Form.Checkbox>
                                </Form.Field>
                                <Form.Field width='3'>
                                    <label>Yin Yang</label>
                                    <Form.Radio label='Yin' name={YIN_YANG} checked={this.state.nzasa.yinYang === YIN_YANG_LIST[YIN]} value={YIN_YANG_LIST[YIN]} onChange={this.radioChangeHandler}></Form.Radio>
                                    <Form.Radio label='Yang' name={YIN_YANG} checked={this.state.nzasa.yinYang === YIN_YANG_LIST[YANG]} value={YIN_YANG_LIST[YANG]} onChange={this.radioChangeHandler}></Form.Radio>
                                </Form.Field>
                            </FormGroup>
                        </Accordion.Content>
                    </Accordion>
                </React.Fragment>
            )
        }

        treatmentForm.push(
            <React.Fragment key='nzasa_1'>
                <Header dividing>Treatment Information</Header>
                <Form.Field>
                    <Form.Checkbox label='Informed consent confirmed by client' name={CLIENT_CONSENTED} checked={this.state.nzasa.clientConsented} onChange={this.checkBoxChangeHandler} />
                </Form.Field>
                <Form.Group widths='16'>
                    <Form.Field width='4'>
                        <Form.Input label='Index' type='text' name='treatmentIndex' value={this.state.index} readOnly />
                    </Form.Field>
                    <Form.Field width='4' required>
                        <DateInput
                            required
                            label='Treatment Date'
                            dateFormat='YYYY-MM-DD'
                            name={DATE}
                            placeholder='YYYY-MM-DD'
                            value={this.state.nzasa.date}
                            iconPosition="left"
                            closable
                            onChange={this.inputChangeHandler}
                            minDate={this.props.data.injuryDate || ''}
                            maxDate={moment()}
                            title='Select the treatment date. The treatment date cannot be earlier than the injury date or in the future.'
                            hideMobileKeyboard
                            animation='none'
                            error={!IsValidDate(this.state.nzasa.date, true)}
                            popupPosition={'bottom left'}
                        />
                    </Form.Field>
                </Form.Group>
            </React.Fragment>
        );

        if (this.state.index === 1) {
            treatmentForm.push(
                <React.Fragment key='nzasa_2a'>
                    <Header dividing>Subjective Findings</Header>

                    <Form.Group widths='equal'>
                        <Form.Field>
                            <QuickFillDropdown label='History of The Presenting Injury' onItemClick={this.quickFillInputClickHandler} associatedinputname={CURR_INJURY_HIST} options={this.state.templateOptions[CURR_INJURY_HIST]} />
                            <CustomTextArea name={CURR_INJURY_HIST} rows={6} value={this.state.nzasa.currInjuryHistory} onChange={this.inputChangeHandler} placeholder='Injury History' />
                        </Form.Field>
                        <Form.Field>
                            <Form.Field className='StackedField'>
                                <QuickFillDropdown label='Previous Treatment' onItemClick={this.quickFillInputClickHandler} associatedinputname={PREV_TREATMENT} options={this.state.templateOptions[PREV_TREATMENT]} />
                                <Form.Input type='text' name={PREV_TREATMENT} value={this.state.nzasa.prevTreatment} onChange={this.inputChangeHandler} placeholder='N/A' />
                            </Form.Field>
                            <Form.Field>
                                <QuickFillDropdown label='Medication' onItemClick={this.quickFillInputClickHandler} associatedinputname={MEDICATION} options={this.state.templateOptions[MEDICATION]} />
                                <Form.Input type='text' name={MEDICATION} value={this.state.nzasa.medication} onChange={this.inputChangeHandler} placeholder='N/A' />
                            </Form.Field>
                        </Form.Field>
                    </Form.Group>
                    <Form.Group widths='equal'>
                        <Form.Field>
                            <QuickFillDropdown label='Aggravating Factors' onItemClick={this.quickFillInputClickHandler} associatedinputname={AGGRAVATING_FACTORS} options={this.state.templateOptions[AGGRAVATING_FACTORS]} />
                            <Form.Input type='text' name={AGGRAVATING_FACTORS} value={this.state.nzasa.aggravatingFactors} onChange={this.inputChangeHandler} placeholder='Aggravating Factors' />
                        </Form.Field>
                        <Form.Field>
                            <QuickFillDropdown label='Easing Factors' onItemClick={this.quickFillInputClickHandler} associatedinputname={EASING_FACTORS} options={this.state.templateOptions[EASING_FACTORS]} />
                            <Form.Input type='text' name={EASING_FACTORS} value={this.state.nzasa.easingFactors} onChange={this.inputChangeHandler} placeholder='Easing Factors' />
                        </Form.Field>
                    </Form.Group>
                    <Form.Group widths='equal'>
                        <Form.Field>
                            <Form.Dropdown label='24 Hours Pattern' name={PATTERN_24_HRS} options={PATTERN_24_HRS_LIST} value={this.state.nzasa.pattern24hours} onChange={this.inputChangeHandler} selection clearable />
                        </Form.Field>
                        <Form.Field>
                            <Form.Dropdown label='Pain Frequency' name={PAIN_FREQUENCY} options={PAIN_FREQUENCY_LIST} value={this.state.nzasa.painFrequency} onChange={this.inputChangeHandler} selection clearable />
                        </Form.Field>
                    </Form.Group>
                    <Form.Group widths='equal'>
                        <Form.Field>
                            <Form.Dropdown label='Progression' name={PROGRESSION} options={PROGRESSION_LIST} value={this.state.nzasa.progression} onChange={this.inputChangeHandler} selection clearable />
                        </Form.Field>
                        <Form.Field>
                            <Form.Dropdown label='Nature of Pain' name={NATURE_OF_PAIN} options={NATURE_OF_PAIN_LIST} value={this.state.nzasa.natureOfPain} onChange={this.inputChangeHandler} selection clearable />
                        </Form.Field>
                        <Form.Field>
                            <label>Pain Scale</label>
                            <Slider
                                min={0}
                                max={10}
                                value={this.state.nzasa.painScale}
                                onChange={this.painScaleInputChangeHandler}
                                dots
                                marks={SLIDER_SCALE_MARKS}
                                handle={this.sliderHandle}
                            />
                        </Form.Field>
                    </Form.Group>
                </React.Fragment>
            );
        } else {
            treatmentForm.push(
                <React.Fragment key='nzasa_2b'>
                    <Header dividing>Subjective Findings</Header>
                    <Form.Group widths='equal'>
                        <Form.Field>
                            <QuickFillDropdown label='Presenting Complaints' onItemClick={this.quickFillInputClickHandler} associatedinputname={PRESENTING_COMPLAINTS} options={this.state.templateOptions[PRESENTING_COMPLAINTS]} />
                            <CustomTextArea name={PRESENTING_COMPLAINTS} rows={4} value={this.state.nzasa.presentingComplaints} onChange={this.inputChangeHandler} placeholder='Presenting complaints' />
                        </Form.Field>
                        <Form.Field>
                            <label>Pain Scale</label>
                            <Slider
                                min={0}
                                max={10}
                                value={this.state.nzasa.painScale}
                                onChange={this.painScaleInputChangeHandler}
                                dots
                                marks={SLIDER_SCALE_MARKS}
                                handle={this.sliderHandle}
                            />
                        </Form.Field>
                    </Form.Group>
                </React.Fragment>
            )
        }

        treatmentForm.push(
            <React.Fragment key='nzasa_3'>
                <Header dividing>Objective Findings</Header>
                <Form.Group widths='equal'>
                    <Form.Field>
                        <QuickFillDropdown label='Physical Findings' onItemClick={this.quickFillInputClickHandler} associatedinputname={PHYSICAL_FINDINGS} options={this.state.templateOptions[PHYSICAL_FINDINGS]} />
                        <CustomTextArea name={PHYSICAL_FINDINGS} rows={8} value={this.state.nzasa.physicalFindings} onChange={this.inputChangeHandler} placeholder='Detail' />
                    </Form.Field>
                    <Form.Field>
                        <QuickFillDropdown label='Range of Movement' onItemClick={this.quickFillInputClickHandler} associatedinputname={RANGE_OF_MOVEMENT} options={this.state.templateOptions[RANGE_OF_MOVEMENT]} />
                        <CustomTextArea name={RANGE_OF_MOVEMENT} rows={8} value={this.state.nzasa.rangeOfMovement} onChange={this.inputChangeHandler} placeholder='Range of movement' />
                    </Form.Field>

                </Form.Group>
                <Form.Group widths='equal'>
                    <Form.Field>
                        <QuickFillDropdown label='Impediment of Function(s)' onItemClick={this.quickFillInputClickHandler} associatedinputname={IMPEDIMENT_OF_FUNC} options={this.state.templateOptions[IMPEDIMENT_OF_FUNC]} />
                        <CustomTextArea name={IMPEDIMENT_OF_FUNC} rows={5} value={this.state.nzasa.impedimentOfFunc} onChange={this.inputChangeHandler} placeholder='Impediment of Functions' />
                    </Form.Field>
                    <Form.Field>
                        <QuickFillDropdown label='Palpation' onItemClick={this.quickFillInputClickHandler} associatedinputname={PALPATION} options={this.state.templateOptions[PALPATION]} />
                        <CustomTextArea name={PALPATION} rows={5} value={this.state.nzasa.palpation} onChange={this.inputChangeHandler} placeholder='Palpation detail' />
                    </Form.Field>
                </Form.Group>
                <Form.Group widths='equal'>
                    <Form.Field className='Slider'>
                        <label>Functional Scale</label>
                        <Slider
                            min={0}
                            max={10}
                            value={this.state.nzasa.functionalScale}
                            onChange={this.funcScaleScaleInputChangeHandler}
                            dots
                            marks={SLIDER_SCALE_MARKS}
                            handle={this.sliderHandle}
                        />
                    </Form.Field>
                    <Form.Field>
                    </Form.Field>
                </Form.Group>

                <Header dividing>Treamtment Plan</Header>
                <Form.Group widths='equal'>
                    <Form.Field>
                        <QuickFillDropdown label='Treatment Principles' onItemClick={this.quickFillInputClickHandler} associatedinputname={TREATMENT_PRINCIPLES} options={this.state.templateOptions[TREATMENT_PRINCIPLES]} />
                        <CustomTextArea name={TREATMENT_PRINCIPLES} rows={3} value={this.state.nzasa.treatmentPrinciples} onChange={this.inputChangeHandler} placeholder='Treatment principles' />
                    </Form.Field>
                    <Form.Field>
                        <Form.Dropdown required selection name={EXPECTED_DURATION} options={TREATMENT_DURATION} label='Expected Treatment Duration (Minutes)' value={this.state.nzasa.expectedDuration} onChange={this.inputChangeHandler} placeholder='Select duration' />
                    </Form.Field>
                </Form.Group>
                <FormHeader dividing as='h4'>Treatment Methods</FormHeader>
                <Form.Group widths='16'>
                    <Form.Field width='4' className='TreatmentMainCol'>
                        <Form.Field className='flex-form-field'>
                            <Form.Checkbox name={ACUPUNCTURE} methodvalue={NZASA_TREATMENT_METHOD_LIST[ACUPUNCTURE]} checked={this.state.nzasaTreatmentMethods.acupuncture} onChange={this.nzasaTreatmentMethodChangeHandler} className='TreatmentMethodCheckBox' />
                            <span className='TreatmentMethodSpan'>
                                <QuickFillDropdown label='Acupuncture' checkitem={ACUPUNCTURE} onItemClick={this.treatmentNotesQuickFillInputClickHandler} associatedinputname={TREATMENT_METHOD_NOTES} options={this.state.templateOptions[ACUPUNCTURE]} />
                            </span>
                        </Form.Field>
                        <Form.Field className='flex-form-field'>
                            <Form.Checkbox name={ELECTROACUPUNCTURE} methodvalue={NZASA_TREATMENT_METHOD_LIST[ELECTROACUPUNCTURE]} checked={this.state.nzasaTreatmentMethods.electroAcupuncture} onChange={this.nzasaTreatmentMethodChangeHandler} className='TreatmentMethodCheckBox' />
                            <span className='TreatmentMethodSpan'>
                                <QuickFillDropdown label='Electro-Acupuncture' checkitem={ELECTROACUPUNCTURE} onItemClick={this.treatmentNotesQuickFillInputClickHandler} associatedinputname={TREATMENT_METHOD_NOTES} options={this.state.templateOptions[ELECTROACUPUNCTURE]} />
                            </span>
                        </Form.Field>
                        <Form.Field className='flex-form-field'>
                            <Form.Checkbox name={AURICLUARACUPUNCTURE} methodvalue={NZASA_TREATMENT_METHOD_LIST.auricluarAcupuncture} checked={this.state.nzasaTreatmentMethods.auricluarAcupuncture} onChange={this.nzasaTreatmentMethodChangeHandler} className='TreatmentMethodCheckBox' />
                            <span className='TreatmentMethodSpan'>
                                <QuickFillDropdown label='Auricluar-Acupuncture' checkitem={AURICLUARACUPUNCTURE} onItemClick={this.treatmentNotesQuickFillInputClickHandler} associatedinputname={TREATMENT_METHOD_NOTES} options={this.state.templateOptions[AURICLUARACUPUNCTURE]} />
                            </span>
                        </Form.Field>
                        <Form.Field className='flex-form-field'>
                            <Form.Checkbox name={GUASHA} methodvalue={NZASA_TREATMENT_METHOD_LIST.guaSha} checked={this.state.nzasaTreatmentMethods.guaSha} onChange={this.nzasaTreatmentMethodChangeHandler} className='TreatmentMethodCheckBox' />
                            <span className='TreatmentMethodSpan'>
                                <QuickFillDropdown label='Gua Sha' checkitem={GUASHA} onItemClick={this.treatmentNotesQuickFillInputClickHandler} associatedinputname={TREATMENT_METHOD_NOTES} options={this.state.templateOptions[GUASHA]} />
                            </span>
                        </Form.Field>
                        <Form.Field className='flex-form-field'>
                            <Form.Checkbox name={BLEEDING} methodvalue={NZASA_TREATMENT_METHOD_LIST.bleeding} checked={this.state.nzasaTreatmentMethods.bleeding} onChange={this.nzasaTreatmentMethodChangeHandler} className='TreatmentMethodCheckBox' />
                            <span className='TreatmentMethodSpan'>
                                <QuickFillDropdown label='Bleeding' checkitem={BLEEDING} onItemClick={this.treatmentNotesQuickFillInputClickHandler} associatedinputname={TREATMENT_METHOD_NOTES} options={this.state.templateOptions[BLEEDING]} />
                            </span>
                        </Form.Field>
                        <Form.Field className='flex-form-field'>
                            <Form.Checkbox name={CUPPING} methodvalue={NZASA_TREATMENT_METHOD_LIST.cupping} checked={this.state.nzasaTreatmentMethods.cupping} onChange={this.nzasaTreatmentMethodChangeHandler} className='TreatmentMethodCheckBox' />
                            <span className='TreatmentMethodSpan'>
                                <QuickFillDropdown label='Cupping' checkitem={CUPPING} onItemClick={this.treatmentNotesQuickFillInputClickHandler} associatedinputname={TREATMENT_METHOD_NOTES} options={this.state.templateOptions[CUPPING]} />
                            </span>
                        </Form.Field>
                        <Form.Field className='flex-form-field'>
                            <Form.Checkbox name={MOXIBUSTION} methodvalue={NZASA_TREATMENT_METHOD_LIST.moxibustion} checked={this.state.nzasaTreatmentMethods.moxibustion} onChange={this.nzasaTreatmentMethodChangeHandler} className='TreatmentMethodCheckBox' />
                            <span className='TreatmentMethodSpan'>
                                <QuickFillDropdown label='Moxibustion' checkitem={MOXIBUSTION} onItemClick={this.treatmentNotesQuickFillInputClickHandler} associatedinputname={TREATMENT_METHOD_NOTES} options={this.state.templateOptions[MOXIBUSTION]} />
                            </span>
                        </Form.Field>
                        <Form.Field className='flex-form-field'>
                            <Form.Checkbox name={TUINA} methodvalue={NZASA_TREATMENT_METHOD_LIST.tuiNa} checked={this.state.nzasaTreatmentMethods.tuiNa} onChange={this.nzasaTreatmentMethodChangeHandler} className='TreatmentMethodCheckBox' />
                            <span className='TreatmentMethodSpan'>
                                <QuickFillDropdown label='Tui Na' checkitem={TUINA} onItemClick={this.treatmentNotesQuickFillInputClickHandler} associatedinputname={TREATMENT_METHOD_NOTES} options={this.state.templateOptions[TUINA]} />
                            </span>
                        </Form.Field>
                    </Form.Field>
                    <Form.Field width='4' className='TreatmentAdditionalCol'>
                        <Form.Field className='flex-form-field'>
                            <Form.Checkbox name={TDP} methodvalue={NZASA_TREATMENT_METHOD_LIST.tdp} checked={this.state.nzasaTreatmentMethods.tdp} onChange={this.nzasaTreatmentMethodChangeHandler} className='TreatmentMethodCheckBox' />
                            <span className='TreatmentMethodSpan'>
                                <QuickFillDropdown label='TDP' checkitem={TDP} onItemClick={this.treatmentNotesQuickFillInputClickHandler} associatedinputname={TREATMENT_METHOD_NOTES} options={this.state.templateOptions[TDP]} />
                            </span>
                        </Form.Field>
                        <Form.Field className='flex-form-field'>
                            <Form.Checkbox name={LINIMENTS} methodvalue={NZASA_TREATMENT_METHOD_LIST.liniments} checked={this.state.nzasaTreatmentMethods.liniments} onChange={this.nzasaTreatmentMethodChangeHandler} className='TreatmentMethodCheckBox' />
                            <span className='TreatmentMethodSpan'>
                                <QuickFillDropdown label='Liniments' checkitem={LINIMENTS} onItemClick={this.treatmentNotesQuickFillInputClickHandler} associatedinputname={TREATMENT_METHOD_NOTES} options={this.state.templateOptions[LINIMENTS]} />
                            </span>
                        </Form.Field>
                        <Form.Field className='flex-form-field'>
                            <Form.Checkbox name={HERBALPLASTERS} methodvalue={NZASA_TREATMENT_METHOD_LIST.herbalPlasters} checked={this.state.nzasaTreatmentMethods.herbalPlasters} onChange={this.nzasaTreatmentMethodChangeHandler} className='TreatmentMethodCheckBox' />
                            <span className='TreatmentMethodSpan'>
                                <QuickFillDropdown label='Herbal Plasters' checkitem={HERBALPLASTERS} onItemClick={this.treatmentNotesQuickFillInputClickHandler} associatedinputname={TREATMENT_METHOD_NOTES} options={this.state.templateOptions[HERBALPLASTERS]} />
                            </span>
                        </Form.Field>
                    </Form.Field>
                    <Form.Field width='8'>
                        <CustomTextArea name={TREATMENT_METHOD_NOTES} rows={8} value={this.state.nzasa.treatmentMethodNotes} onChange={this.inputChangeHandler} placeholder='Treatment notes' />
                    </Form.Field>
                </Form.Group>
                <Form.Group widths='equal'>
                    <Form.Field>
                        <QuickFillDropdown label='Point(s) Selected' onItemClick={this.quickFillInputClickHandler} associatedinputname={POINTS_SELECTED} options={this.state.templateOptions[POINTS_SELECTED]} />
                        <CustomTextArea name={POINTS_SELECTED} rows={4} value={this.state.nzasa.pointsSelected} onChange={this.inputChangeHandler} placeholder='Point(s) selected' />
                    </Form.Field>
                    <Form.Field>
                        <Form.Field>
                            <QuickFillDropdown label='Advice' onItemClick={this.quickFillInputClickHandler} associatedinputname={ADVICES} options={this.state.templateOptions[ADVICES]} />
                            <CustomTextArea name={ADVICES} rows={4} value={this.state.nzasa.advices} onChange={this.inputChangeHandler} placeholder='Advice for the patient' />
                        </Form.Field>
                    </Form.Field>
                </Form.Group>
                <Form.Group widths='equal'>
                    <Form.Field>
                        <QuickFillDropdown label='Treatment Goals (Expected Outcome)' onItemClick={this.quickFillInputClickHandler} associatedinputname={TREATMENT_GOAL} options={this.state.templateOptions[TREATMENT_GOAL]} />
                        <CustomTextArea name={TREATMENT_GOAL} rows={4} value={this.state.nzasa.treatmentGoal} onChange={this.inputChangeHandler} placeholder='Treatment goal / expected outcome' />
                    </Form.Field>
                    <Form.Field>
                        <QuickFillDropdown label={this.state.index > 1 ? 'Assessment' : 'Reassessment'} onItemClick={this.quickFillInputClickHandler} associatedinputname={ASSESSMENT} options={this.state.templateOptions[ASSESSMENT]} />
                        <CustomTextArea name={ASSESSMENT} rows={4} value={this.state.nzasa.assessment} onChange={this.inputChangeHandler} placeholder='Assessment detail' />
                    </Form.Field>
                </Form.Group>
            </React.Fragment>
        );

        return (
            <div className='TreatmentNzasa'>
                {
                    this.props.inModal ?
                        <CustomLoader active={this.state.loading} size='massive' cssLoader /> :
                        <CustomLoader active={this.state.loading} size='massive' />
                }
                <ConditionalArea show={!this.state.loading}>
                    <ControlPane>
                        {this.props.onCloseInControlPane ? <Button name='Close' onClick={this.props.onCloseInControlPane} content='Close' /> : null}
                        <Button primary name='saveTreatment' onClick={() => this.saveTreatmentHandler()} disabled={!this.props.touched}>Save</Button>
                        <Button primary name='saveTreatmentAndClose' onClick={() => this.saveTreatmentHandler(false, true)} disabled={!this.props.touched}>Save & Close</Button>
                    </ControlPane>
                    <Form autoComplete="off">
                        {treatmentForm}
                    </Form>
                    <ControlPane>
                        {this.props.onCloseInControlPane ? <Button name='Close' onClick={this.props.onCloseInControlPane} content='Close' /> : null}
                        <Button primary name='saveTreatment' onClick={() => this.saveTreatmentHandler()} disabled={!this.props.touched}>Save</Button>
                        <Button primary name='saveTreatmentAndClose' onClick={() => this.saveTreatmentHandler(false, true)} disabled={!this.props.touched}>Save & Close</Button>
                    </ControlPane>
                    <ConfrimDialog content={this.state.confirmationMsg} open={this.state.showTreatmentCountAlert} onConfirm={() => this.saveTreatmentHandler(true, this.state.shouldCloseOnSave)} onCancel={this.treatmentAlertLimitCancelHandler} />
                </ConditionalArea>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        user: state.user,
        token: state.token,
        templateItems: state.templateItems
    }
}

export default connect(mapStateToProps)(TreatmentNzasa);