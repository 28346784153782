import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actionTypes from '../../../store/actions';
import { Form, Header, Button, Modal, Message, List, Grid, Accordion, Icon, Divider } from 'semantic-ui-react';
import { TITLE_OPTIONS } from '../../../assets/constants';
import ControlPane from '../../../UI/Controls/Control/ControlPane';
import './SettingPane.css';
import EnoteAxios from '../../../axios';
import CustomTextArea from '../../../UI/CustomTextArea/CustomTextArea';
import { NotificationManager } from 'react-notifications';
import { Prompt } from 'react-router-dom';
import FocusLock from 'react-focus-lock';
import { fetchUserDetail } from '../../../store/thunks';
import _ from 'lodash';
import { createColumnHelper } from '@tanstack/react-table';
import CustomReactTable, { XOverflowMode } from '../../../UI/CustomReactTable/CustomReactTable';
import dayjs from 'dayjs';

const FIRST_NAME = 'firstName';
const LAST_NAME = 'lastName';
const TITLE = 'title';
const GST_NUMBER = 'gstNumber';
const COMPANY_NAME = 'companyName';
const VENDOR_ID = 'vendorID';
const PROVIDER_ID = 'providerID';
const EMAIL = 'email';
const PHYSICAL_ADDRESS = 'physicalAddress';
const HOME_PHONE = 'homePhone';
const WORK_PHONE = 'workPhone';
const MOBILE_PHONE = 'mobilePhone';
const WECHAT = 'wechat';
const ACC_RATE = 'accRate';
const APPOINTMENT_ALERT_LIMIT = 'appointmentAlertLimit';
const TREATMENT_ALERT_LIMIT = 'treatmentAlertLimit';
const APPOINTMENT_OVERLAP_ALERT = 'appointmentOverlapAlert';
const TREATMENT_DURATION_ALERT_LIMIT = 'treatmentDurationAlertLimit';
const SHOW_HOUR_COUNT_ON_APPOINTMENT = 'showHourCountOnAppointment';
const STUDENT_FIRST_NAME = 'studentFirstName';
const STUDENT_LAST_NAME = 'studentLastName';
const STUDENT_ID = 'studentId';
const APPOINTMENT_REMINDER_ENABLED = 'appointmentReminderEnabled';
const NOTIFICATION_CONTACT_NAME = 'notificationContactName';
const NOTIFICATION_CONTACT_PHONE = 'notificationContactPhone';
const NOTIFICATION_CONTACT_EMAIL = 'notificationContactEmail';
const NOTIFICATION_BUSINESS_NAME = 'notificationBusinessName';
const NOTIFICATION_BUSINESS_ADDRESS = 'notificationBusinessAddress';

const APPOINTMENT_REMINDER_RULES = 'appointmentReminderRules';

const CURRENT_PW_ENTERED = 'currPasswordEntered';
const NEW_PW_ENTERED = 'newPasswordEntered';
const NEW_PW_CONFIRM_ENTERED = 'newPasswordConfirmEntered';

const DEFAULT_ACC_RATE = 67.77;

const columnHelper = createColumnHelper();

class SettingPane extends Component {
    state = {
        account: {
            [FIRST_NAME]: '',
            [LAST_NAME]: '',
            [TITLE]: '',
            [GST_NUMBER]: '',
            [COMPANY_NAME]: '',
            [VENDOR_ID]: '',
            [PROVIDER_ID]: '',
            [EMAIL]: '',
            [PHYSICAL_ADDRESS]: '',
            [HOME_PHONE]: '',
            [WORK_PHONE]: '',
            [MOBILE_PHONE]: '',
            [STUDENT_FIRST_NAME]: '',
            [STUDENT_LAST_NAME]: '',
            [STUDENT_ID]: '',
            [WECHAT]: '',
            [ACC_RATE]: DEFAULT_ACC_RATE,
            [APPOINTMENT_ALERT_LIMIT]: 8,
            [TREATMENT_ALERT_LIMIT]: 8,
            [APPOINTMENT_OVERLAP_ALERT]: false,
            [TREATMENT_DURATION_ALERT_LIMIT]: 9,
            [SHOW_HOUR_COUNT_ON_APPOINTMENT]: false,
            [APPOINTMENT_REMINDER_ENABLED]: false,
            [NOTIFICATION_CONTACT_NAME]: '',
            [NOTIFICATION_CONTACT_PHONE]: '',
            [NOTIFICATION_CONTACT_EMAIL]: '',
            [NOTIFICATION_BUSINESS_NAME]: '',
            [NOTIFICATION_BUSINESS_ADDRESS]: '',
            [APPOINTMENT_REMINDER_RULES]: [],
        },
        [CURRENT_PW_ENTERED]: '',
        [NEW_PW_ENTERED]: '',
        [NEW_PW_CONFIRM_ENTERED]: '',
        saveError: '',
        isSavingUserInfo: false,
        isChangingPassword: false,
        sensitiveInfoChanged: false,
        unsavedChange: false,
        loading: false,
        showUserInfoMissingDialog: false,
        expandStudentInfo: false,
        appointmentReminderSample: {
            show: false,
            previewDetail: {},
            error: '',
        },
    }

    componentDidUpdate(prevProps) {
        if (prevProps.user !== this.props.user) {
            if (!prevProps.user.firstName && this.props.user.firstName) {
                this.props.history.push('/');
            }

            this.setState({
                account: this.populateAccountInfoFromProps(this.props),
                currPasswordEntered: '',
                newPasswordEntered: '',
                newPasswordConfirmEntered: '',
                isSavingUserInfo: false,
                sensitiveInfoChanged: false,
                unsavedChange: false,
                loading: false,
                expandStudentInfo: !_.isEmpty(this.props.user.studentFirstName) || !_.isEmpty(this.props.user.studentLastName) || !_.isEmpty(this.props.user.studentId),
            });
        }
    }

    componentDidMount() {
        this.setState({
            loading: true
        })
        this.props.fetchUserDetail();
        if (!this.props.user.firstName) {
            this.setState({
                showUserInfoMissingDialog: true
            });
        }
    }

    GetUserPropOrEmptyString(p) {
        return p ? p : ''
    }

    accountInfoInputChangeHandler = (e, { name, value }) => {
        this.setState(prevState => ({
            account: {
                ...prevState.account,
                [name]: value
            },
            unsavedChange: true
        }));
    }

    sensetiveInputChangeHandler = (e, { name, value }) => {
        this.setState(prevState => ({
            account: {
                ...prevState.account,
                [name]: value
            },
            unsavedChange: true,
            sensitiveInfoChanged: true
        }));
    }

    inputChangeHandler = (e, { name, value }) => {
        this.setState({
            [name]: value
        });
    }

    checkBoxChangeHandler = (e, { name, checked }) => {
        this.setState(prevState => ({
            account: {
                ...prevState.account,
                [name]: checked
            },
            unsavedChange: true
        }));
    }

    saveAccountDetailClickHandler = () => {
        if (!this.validateForm()) {
            return;
        }

        if (!this.props.user.firstName || !this.state.sensitiveInfoChanged) {
            this.saveAccountDetailConfirmedClickHandler();
        } else {
            this.setState({
                isSavingUserInfo: true
            });
        }
    }

    saveAccountDetailConfirmedClickHandler = async () => {
        // UPDATE INFO
        this.setState({
            loading: true
        });
        const userDetailWithPassword = {
            userDetail: {
                ...this.state.account,
                userId: this.props.user.userId
            },
            password: this.state.currPasswordEntered
        }

        await EnoteAxios.post('/api/user/UpdateUserDetail', userDetailWithPassword).then(response => {
            if (response.data && response.data.responseData) {
                this.setState({
                    unsavedChange: false
                }, () => {
                    this.props.updateUser(response.data.responseData);
                })
            }
        }).catch(() => {
            this.setState({
                loading: false
            });
        });
    }

    saveAccountDetailCancelClickHandler = () => {
        this.setState({
            currPasswordEntered: '',
            isSavingUserInfo: false
        });
    }

    changePasswordClickHandler = () => {
        this.setState({
            isChangingPassword: true,
            currPasswordEntered: '',
            newPasswordEntered: '',
            newPasswordConfirmEntered: '',
            saveError: ''
        });
    }

    changePasswordConfirmedHandler = async () => {
        this.setState({
            loading: true
        });

        const changePasswordForm = {
            currentPasswordEntered: this.state.currPasswordEntered,
            newPasswordEntered: this.state.newPasswordEntered,
            newPasswordConfirmEntered: this.state.newPasswordConfirmEntered
        }

        await EnoteAxios.post('/api/user/ChangePassword', changePasswordForm).then(reponse => {
            this.setState({
                isChangingPassword: false,
                currPasswordEntered: '',
                newPasswordEntered: '',
                newPasswordConfirmEntered: '',
                saveError: '',
                loading: false
            });
        }).catch(err => {
            this.setState({
                saveError: err.response ? err.response.data : '',
                loading: false
            });
        });
    }

    changePasswordCancelHandler = () => {
        this.setState({
            isChangingPassword: false,
            currPasswordEntered: '',
            newPasswordEntered: '',
            newPasswordConfirmEntered: '',
            saveError: ''
        });
    }

    isChangePasswordFormValid = () => {
        return this.state.currPasswordEntered &&
            this.state.newPasswordEntered &&
            this.state.newPasswordEntered.length >= 8 &&
            (this.state.newPasswordEntered === this.state.newPasswordConfirmEntered);
    }

    populateAccountInfoFromProps = (reduxProps) => {
        return {
            [FIRST_NAME]: reduxProps.user.firstName || '',
            [LAST_NAME]: reduxProps.user.lastName || '',
            [TITLE]: reduxProps.user.title || '',
            [GST_NUMBER]: reduxProps.user.gstNumber || '',
            [COMPANY_NAME]: reduxProps.user.companyName || '',
            [VENDOR_ID]: reduxProps.user.vendorId || '',
            [PROVIDER_ID]: reduxProps.user.providerId || '',
            [EMAIL]: reduxProps.user.email || '',
            [PHYSICAL_ADDRESS]: reduxProps.user.physicalAddress || '',
            [HOME_PHONE]: reduxProps.user.homePhone || '',
            [WORK_PHONE]: reduxProps.user.workPhone || '',
            [MOBILE_PHONE]: reduxProps.user.mobilePhone || '',
            [STUDENT_FIRST_NAME]: reduxProps.user.studentFirstName || '',
            [STUDENT_LAST_NAME]: reduxProps.user.studentLastName || '',
            [STUDENT_ID]: reduxProps.user.studentId || '',
            [WECHAT]: reduxProps.user.wechat || '',
            [ACC_RATE]: reduxProps.user.accRate || DEFAULT_ACC_RATE,
            [TREATMENT_ALERT_LIMIT]: reduxProps.user.treatmentAlertLimit,
            [APPOINTMENT_ALERT_LIMIT]: reduxProps.user.appointmentAlertLimit,
            [APPOINTMENT_OVERLAP_ALERT]: reduxProps.user.appointmentOverlapAlert,
            [TREATMENT_DURATION_ALERT_LIMIT]: reduxProps.user.treatmentDurationAlertLimit,
            [SHOW_HOUR_COUNT_ON_APPOINTMENT]: reduxProps.user.showHourCountOnAppointment,
            [APPOINTMENT_REMINDER_ENABLED]: reduxProps.user.appointmentReminderEnabled,
            [NOTIFICATION_CONTACT_NAME]: reduxProps.user.notificationContactName,
            [NOTIFICATION_CONTACT_PHONE]: reduxProps.user.notificationContactPhone,
            [NOTIFICATION_CONTACT_EMAIL]: reduxProps.user.notificationContactEmail,
            [NOTIFICATION_BUSINESS_NAME]: reduxProps.user.notificationBusinessName,
            [NOTIFICATION_BUSINESS_ADDRESS]: reduxProps.user.notificationBusinessAddress,
            [APPOINTMENT_REMINDER_RULES]: reduxProps.user.appointmentReminderRules,
        }
    }

    hideUserInfoMissingDialog = () => {
        this.setState({
            showUserInfoMissingDialog: false
        });
    }

    validateForm = () => {
        if (!this.state.account.firstName) {
            NotificationManager.error('Name, Acc Rate cannot be empty', "Failed");
            return false;
        }
        if (!this.state.account.accRate || this.state.account.accRate <= 0) {
            NotificationManager.error('Name, Acc Rate cannot be empty', "Failed");
            return false;
        }
        if (this.state.account.gstNumber && !(/^\d+$/.test(this.state.account.gstNumber))) {
            NotificationManager.error('GST Nubmer accepts numbers only, please remove invalid characters', "Failed");
            return false;
        }
        if (this.state.account.appointmentReminderEnabled
            && (!this.state.account.notificationBusinessName)
            && this.state.account.appointmentReminderRules.filter(x => x.enabled).length > 0) {
            NotificationManager.error('Please provide contact detail in order to enable patient appointment reminder');
            return false;
        }
        return true;
    }

    isAppointmentReminderRuleEnabled = (ruleId) => {
        const rule = this.state.account.appointmentReminderRules.find(x => x.ruleId === ruleId);
        if (rule) {
            return rule.enabled;
        }
    }

    onAppointmentReminderRuleStateChange = (ruleId) => {
        const rules = this.state.account.appointmentReminderRules.slice();
        const ruleIndex = rules.findIndex(x => x.ruleId === ruleId);
        if (ruleIndex >= 0) {
            const currRule = rules[ruleIndex];
            const newRule = {
                ...currRule,
                enabled: !currRule.enabled
            }
            rules[ruleIndex] = newRule;
        }
        this.setState(prevState => ({
            account: {
                ...prevState.account,
                appointmentReminderRules: rules
            },
            unsavedChange: true
        }))
    }

    appointmentReminderRuleColumns = [
        columnHelper.accessor(r => r.enabled, {
            id: '_select',
            header: 'Enabled',
            cell: ({ row }) => (
                <Form.Checkbox
                    key={row.original.ruleId}
                    checked={this.isAppointmentReminderRuleEnabled(row.original.ruleId)}
                    onChange={() => {
                        this.onAppointmentReminderRuleStateChange(row.original.ruleId)
                    }}
                />
            ),
        }),
        columnHelper.accessor(r => r, {
            id: 'rule',
            header: 'Rule',
            cell: ({ row }) => {
                const scheduleHours = row.original.schedule / 60;
                return (
                    <div>Send an {row.original.channel} reminder to patient {scheduleHours > 1 ? scheduleHours + ' hours' : scheduleHours + ' hour'} prior to the appointment</div>
                )
            }
        }),
    ]

    previewAppointmentReminderEmail = async () => {
        if (!this.state.account.notificationBusinessName) {
            this.setState({
                appointmentReminderSample: {
                    show: true,
                    previewDetail: {},
                    error: 'Please complete all the required fields for appointment reminder',
                }
            });
            return;
        }
        const now = dayjs();
        const tomorrow = now.add(1, 'day');
        const appointmentTime = tomorrow.hour(9).minute(0).second(0).millisecond(0);
        this.setState({
            appointmentReminderSample: {
                show: true,
                previewDetail: {},
                error: '',
            }
        });
        await EnoteAxios.post('/api/notification/appointment-reminder/preview/email', {
            patientEmail: 'client@gmail.com',
            patientName: 'client (to be replaced by the actual client name)',
            appointmentTime: appointmentTime.format('YYYY-MM-DD HH:mm'),
            contactName: this.state.account.notificationContactName,
            contactPhone: this.state.account.notificationContactPhone,
            contactEmail: this.state.account.notificationContactEmail,
            businessName: this.state.account.notificationBusinessName,
            businessAddress: this.state.account.notificationBusinessAddress,
        }).then(response => {
            this.setState({
                appointmentReminderSample: {
                    show: true,
                    previewDetail: response.data,
                    error: '',
                }
            });
        }).catch(err => {
            this.setState({
                appointmentReminderSample: {
                    show: true,
                    previewDetail: {},
                    error: 'An unexpected error occurred while previewing email content',
                }
            });
        });
    }

    closeAppointmentReminderEmailPreview = () => {
        this.setState({
            appointmentReminderSample: {
                show: false,
                previewDetail: {},
                error: '',
            }
        })
    }

    render() {
        let saveErr = null;
        if (this.state.saveError) {
            saveErr = (
                <Message negative>
                    <Message.Header>Error</Message.Header>
                    <p>{this.state.saveError}</p>
                </Message>
            );
        }

        let appointmentReminderRulesSection = null;
        if (this.state.account.appointmentReminderEnabled) {
            appointmentReminderRulesSection = (
                <>
                    <Form.Group>
                        <Form.Field width={4}>
                            <Form.Input label='Clinic Name' required name={NOTIFICATION_BUSINESS_NAME} value={this.state.account.notificationBusinessName} onChange={this.accountInfoInputChangeHandler} placeholder='Business name displayed in email reminders' />
                        </Form.Field>
                        <Form.Field width={8}>
                            <Form.Input label='Clinic Address' name={NOTIFICATION_BUSINESS_ADDRESS} value={this.state.account.notificationBusinessAddress} onChange={this.accountInfoInputChangeHandler} placeholder='Business address displayed in email reminders' />
                        </Form.Field>
                    </Form.Group>
                    <Form.Group>
                        <Form.Field width={4}>
                            <Form.Input label='Contact Name' name={NOTIFICATION_CONTACT_NAME} value={this.state.account.notificationContactName} onChange={this.accountInfoInputChangeHandler} placeholder='Practitioner name displayed in email reminders' />
                        </Form.Field>
                        <Form.Field width={4}>
                            <Form.Input label='Contact Phone' name={NOTIFICATION_CONTACT_PHONE} value={this.state.account.notificationContactPhone} onChange={this.accountInfoInputChangeHandler} placeholder='Phone number displayed in email reminders' />
                        </Form.Field>
                        <Form.Field width={4}>
                            <Form.Input label='Contact Email' name={NOTIFICATION_CONTACT_EMAIL} value={this.state.account.notificationContactEmail} onChange={this.accountInfoInputChangeHandler} placeholder='Clinic email displayed in email reminders' />
                        </Form.Field>
                    </Form.Group>
                    <Form.Button onClick={this.previewAppointmentReminderEmail}>View sample</Form.Button>
                    <Message warning visible={true}>
                        <p>Email reminders may be marked as spam. Please inform clients to check spam emails if no reminder is received.</p>
                    </Message>
                    <CustomReactTable
                        columns={this.appointmentReminderRuleColumns}
                        data={this.state.account.appointmentReminderRules}
                        noDataText='No rule found'
                        fixedStyle={false}
                        xOverflowMode={XOverflowMode.Scroll}
                    />
                </>

            );
        }

        return (
            <div className='SettingPane ContentMainPane'>
                <ControlPane align='right'>
                    <Button primary name='saveAccountDetail' onClick={this.saveAccountDetailClickHandler} disabled={!this.state.unsavedChange}>Save</Button>
                    <Button primary name='changePasswordClick' onClick={this.changePasswordClickHandler}>Change Password</Button>
                </ControlPane>

                <Modal open={this.state.showUserInfoMissingDialog} size='tiny'>
                    <Modal.Header>Welcome</Modal.Header>
                    <Modal.Content>
                        <p>Please complete user information before using the app.</p>
                    </Modal.Content>
                    <Modal.Actions>
                        <FocusLock>
                            <Button primary content='OK' onClick={this.hideUserInfoMissingDialog} />
                        </FocusLock>
                    </Modal.Actions>
                </Modal>

                <Modal
                    size='tiny'
                    open={this.state.isSavingUserInfo}>
                    <Modal.Header>Authorization</Modal.Header>
                    <Modal.Content>
                        <p>Please enter your current password in order to update invoicing information.</p>
                        <Form autoComplete="off" onSubmit={this.saveAccountDetailConfirmedClickHandler}>
                            <Form.Field>
                                <Form.Input autoFocus label='Password' type='password' name={[CURRENT_PW_ENTERED]} value={this.state.currPasswordEntered} onChange={this.inputChangeHandler} placeholder='Current Password' />
                            </Form.Field>
                            <div className='ModalControlPane'>
                                <ControlPane>
                                    <Button type='button' onClick={this.saveAccountDetailCancelClickHandler}>Cancel</Button>
                                    <Button primary
                                        type='submit'
                                        loading={this.state.loading}
                                        disabled={!this.state.currPasswordEntered || this.state.loading}>Continue</Button>
                                </ControlPane>
                            </div>
                        </Form>
                    </Modal.Content>
                </Modal>

                <Modal
                    size='tiny'
                    open={this.state.isChangingPassword}>
                    <Modal.Header>Change Password</Modal.Header>
                    <Modal.Content>
                        <p>Please enter your current password and the new password. A password must meet the following criteria: </p>
                        <List bulleted>
                            <List.Item>Contains 8 characters or more</List.Item>
                            <List.Item>Contains at least 1 digit</List.Item>
                            <List.Item>Contains at least 1 letter</List.Item>
                        </List>
                        <Form autoComplete="off" onSubmit={this.changePasswordConfirmedHandler}>
                            <Form.Field>
                                <Form.Input autoFocus label='Current Password' type='password' name={[CURRENT_PW_ENTERED]} value={this.state.currPasswordEntered} onChange={this.inputChangeHandler} placeholder='Current Password' />
                            </Form.Field>
                            <Form.Field>
                                <Form.Input label='New Password' type='password' name={[NEW_PW_ENTERED]} value={this.state.newPasswordEntered} onChange={this.inputChangeHandler} placeholder='New Password' />
                            </Form.Field>
                            <Form.Field>
                                <Form.Input label='Confirm New Password' type='password' name={[NEW_PW_CONFIRM_ENTERED]} value={this.state.newPasswordConfirmEntered} onChange={this.inputChangeHandler} placeholder='Confirm New Password' />
                            </Form.Field>
                            {saveErr}
                            <div className='ModalControlPane'>
                                <ControlPane>
                                    <Button type='button' onClick={this.changePasswordCancelHandler}>Cancel</Button>
                                    <Button primary
                                        type='submit'
                                        disabled={!(this.state.currPasswordEntered &&
                                            this.state.newPasswordEntered &&
                                            this.state.newPasswordEntered.length >= 8 &&
                                            (this.state.newPasswordEntered === this.state.newPasswordConfirmEntered)) || this.state.loading}
                                        loading={this.state.loading}>
                                        Continue
                                    </Button>
                                </ControlPane>
                            </div>
                        </Form>
                    </Modal.Content>
                </Modal>

                <Modal onClose={this.closeAppointmentReminderEmailPreview}
                    open={this.state.appointmentReminderSample.show}
                    size='tiny'>
                    <Modal.Header>Sample reminder preview</Modal.Header>
                    <Modal.Content>
                        {
                            this.state.appointmentReminderSample.previewDetail.from && (
                                <>
                                    <p><strong>From:</strong> {this.state.appointmentReminderSample.previewDetail.from}</p>
                                    <p><strong>Reply-To:</strong> {this.state.appointmentReminderSample.previewDetail.replyTo}</p>
                                    <p><strong>Subject:</strong> {this.state.appointmentReminderSample.previewDetail.subject}</p>
                                    <Divider />
                                    <div>
                                        {this.state.appointmentReminderSample.previewDetail.content.split(/(?:\r\n|\r|\n)/).map((line, index) => (
                                            <>
                                                {index > 0 ? <br /> : null}
                                                <span key={index}>{line}</span>
                                            </>
                                        ))}
                                    </div>
                                </>
                            )
                        }
                        {
                            this.state.appointmentReminderSample.error && (
                                <p>{this.state.appointmentReminderSample.error}</p>
                            )
                        }
                    </Modal.Content>
                    <Modal.Actions>
                        <FocusLock>
                            <Button primary content='Close' onClick={this.closeAppointmentReminderEmailPreview} />
                        </FocusLock>
                    </Modal.Actions>
                </Modal>
                <Form className='SettingsForm' loading={this.state.loading}>
                    <Header dividing size='large'>Practitioner Information</Header>
                    <Form.Group widths='equal'>
                        <Form.Field>
                            <Form.Dropdown label='Title' options={TITLE_OPTIONS} selection name={TITLE} value={this.state.account.title} onChange={this.accountInfoInputChangeHandler} placeholder='Select Title' />
                        </Form.Field>
                        <Form.Field>
                            <Form.Input required label='First Name' name={FIRST_NAME} value={this.state.account.firstName} onChange={this.accountInfoInputChangeHandler} placeholder='First Name' />
                        </Form.Field>
                        <Form.Field>
                            <Form.Input required label='Last Name' name={LAST_NAME} value={this.state.account.lastName} onChange={this.accountInfoInputChangeHandler} placeholder='Last Name' />
                        </Form.Field>
                    </Form.Group>
                    <Header dividing>Invoicing Detail</Header>
                    <Form.Group widths='equal'>
                        <Form.Field>
                            <Form.Input label='Company Name' name={COMPANY_NAME} value={this.state.account.companyName} onChange={this.sensetiveInputChangeHandler} placeholder='Company Name' />
                        </Form.Field>
                        <Form.Field>
                            <Form.Input label='Vendor ID' name={VENDOR_ID} value={this.state.account.vendorID} onChange={this.sensetiveInputChangeHandler} placeholder='Vendor ID' />
                        </Form.Field>
                        <Form.Field>
                            <Form.Input label='Provider ID' name={PROVIDER_ID} value={this.state.account.providerID} onChange={this.sensetiveInputChangeHandler} placeholder='Provider ID' />
                        </Form.Field>
                    </Form.Group>
                    <Form.Group widths='equal'>
                        <Form.Field>
                            <Form.Input label='GST Number (without dash)' name={GST_NUMBER} value={this.state.account.gstNumber} onChange={this.sensetiveInputChangeHandler} placeholder='GST Number' />
                        </Form.Field>
                        <Form.Field>
                            <Form.Input required label='ACC Rate (Inc. GST)' type='number' name={ACC_RATE} value={this.state.account.accRate} onChange={this.sensetiveInputChangeHandler} placeholder='ACC Rate' />
                        </Form.Field>
                        <Form.Field>
                        </Form.Field>
                    </Form.Group>

                    <Header dividing size='large'>Contact & Address</Header>
                    <Form.Group widths='equal'>
                        <Form.Field>
                            <Form.Input label='Home Phone' name={HOME_PHONE} value={this.state.account.homePhone} onChange={this.accountInfoInputChangeHandler} placeholder='Home Phone' />
                        </Form.Field>
                        <Form.Field>
                            <Form.Input label='Work Phone' name={WORK_PHONE} value={this.state.account.workPhone} onChange={this.accountInfoInputChangeHandler} placeholder='Work Phone' />
                        </Form.Field>
                        <Form.Field>
                            <Form.Input label='Mobile Phone' name={MOBILE_PHONE} value={this.state.account.mobilePhone} onChange={this.accountInfoInputChangeHandler} placeholder='Mobile Phone' />
                        </Form.Field>
                    </Form.Group>

                    <Grid width='16'>
                        <Grid.Column width='8'>

                            <Form.Field>
                                <Form.Input label='Email' type='email' name={EMAIL} value={this.state.account.email} onChange={this.accountInfoInputChangeHandler} placeholder='Email Address' />
                            </Form.Field>

                            <Form.Field>
                                <Form.Input label='WeChat' name={WECHAT} value={this.state.account.wechat} onChange={this.accountInfoInputChangeHandler} placeholder='WeChat' />
                            </Form.Field>

                        </Grid.Column>
                        <Grid.Column width='8'>
                            <Form.Field>
                                <label>Address</label>
                                <CustomTextArea rows={5} name={PHYSICAL_ADDRESS} value={this.state.account.physicalAddress} onChange={this.accountInfoInputChangeHandler} placeholder='Address' />
                            </Form.Field>
                        </Grid.Column>
                    </Grid>

                    <Accordion>
                        <Accordion.Title as={Header} className='student-info-header' dividing={this.state.expandStudentInfo} active={this.state.expandStudentInfo} onClick={() => this.setState(prevState => ({ expandStudentInfo: !prevState.expandStudentInfo }))}>
                            <Icon name='dropdown' /> Student Information
                        </Accordion.Title>
                        <Accordion.Content active={this.state.expandStudentInfo}>
                            <Form.Group widths='equal'>
                                <Form.Field>
                                    <Form.Input label='Student First Name' name={STUDENT_FIRST_NAME} value={this.state.account.studentFirstName} onChange={this.accountInfoInputChangeHandler} placeholder='Student First Name' />
                                </Form.Field>
                                <Form.Field>
                                    <Form.Input label='Student Last Name' name={STUDENT_LAST_NAME} value={this.state.account.studentLastName} onChange={this.accountInfoInputChangeHandler} placeholder='Student Last Name' />
                                </Form.Field>
                                <Form.Field>
                                    <Form.Input label='Student ID' name={STUDENT_ID} value={this.state.account.studentId} onChange={this.accountInfoInputChangeHandler} placeholder='Student ID' />
                                </Form.Field>
                            </Form.Group>
                        </Accordion.Content>
                    </Accordion>
                    <Header dividing size='large'>Appoiontment reminder</Header>
                    <Form.Group>
                        <Form.Checkbox label='Send appointment reminders to patients' name={APPOINTMENT_REMINDER_ENABLED} checked={this.state.account.appointmentReminderEnabled} onChange={this.checkBoxChangeHandler} />
                    </Form.Group>
                    {appointmentReminderRulesSection}
                    <Header dividing size='large'>Preference</Header>
                    <List className='NotificationList'>
                        <Header size='small'>Display</Header>
                        <List.Item>
                            <Form.Field>
                                <Form.Checkbox label='Show total appointment and invoiced treatment hours on Appointment Week/Day view' name={SHOW_HOUR_COUNT_ON_APPOINTMENT} checked={this.state.account.showHourCountOnAppointment} onChange={this.checkBoxChangeHandler} />
                            </Form.Field>
                        </List.Item>
                        <Header size='small'>Notifications</Header>
                        <List.Item>
                            <Form.Field>
                                <Form.Checkbox label='Notify me when a new appointment overlaps with existing ones.' name={APPOINTMENT_OVERLAP_ALERT} checked={this.state.account.appointmentOverlapAlert} onChange={this.checkBoxChangeHandler} />
                            </Form.Field>
                        </List.Item>
                        <List.Item>
                            <Form.Field className='StackField'>
                                <p className='TreatmentAlertField'>Notify me when I have more than </p>
                                <Form.Input className='TreatmentAlertField TreatmentAlertInput' size='mini' type='number' name={APPOINTMENT_ALERT_LIMIT} value={this.state.account.appointmentAlertLimit} onChange={this.accountInfoInputChangeHandler} />
                                <p className='TreatmentAlertField'> appointments on a day.</p>
                            </Form.Field>
                        </List.Item>
                        <List.Item>
                            <Form.Field className='StackField'>
                                <p className='TreatmentAlertField'>Notify me when I have more than </p>
                                <Form.Input className='TreatmentAlertField TreatmentAlertInput' size='mini' type='number' name={TREATMENT_ALERT_LIMIT} value={this.state.account.treatmentAlertLimit} onChange={this.accountInfoInputChangeHandler} />
                                <p className='TreatmentAlertField'> treatments or invoiced treatments on a day. </p>
                            </Form.Field>
                        </List.Item>
                        <List.Item>
                            <Form.Field>
                                <p className='TreatmentAlertField'>Stop me from invoicing more than </p>
                                <Form.Input className='TreatmentAlertField TreatmentAlertInput' size='mini' type='number' name={TREATMENT_DURATION_ALERT_LIMIT} value={this.state.account.treatmentDurationAlertLimit} onChange={this.accountInfoInputChangeHandler} />
                                <p className='TreatmentAlertField'> hours of treatments on a day. {+this.state.account.treatmentDurationAlertLimit > 0 ? ' You will be notified when you are about to invoice more than ' + (this.state.account.treatmentDurationAlertLimit - 1) + ' hours of treatments on a day.' : null}</p>
                            </Form.Field>
                        </List.Item>
                    </List>
                </Form>
                <Prompt
                    when={this.state.unsavedChange}
                    message='Are you sure you want to close the page? Any unsaved change will be lost.' />
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        user: state.user,
        token: state.token
    };
}

const mapDispatchToProps = dispatch => {
    return {
        updateUser: (userInfo) => dispatch({
            type: actionTypes.UPDATE_USER,
            payload: {
                user: userInfo
            }
        }),
        fetchUserDetail: () => dispatch(fetchUserDetail())
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(SettingPane);