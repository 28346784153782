import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import 'semantic-ui-css/semantic.min.css';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { NotificationContainer } from 'react-notifications';
import ErrorHandler from './error-handler';
import store from './store/store';

const app = (
    <ErrorHandler>
        <Provider store={store}>
            <BrowserRouter>
                <App />
            </BrowserRouter>
        </Provider>
    </ErrorHandler>
);

const appContainer = document.getElementById('root');
const appRoot = createRoot(appContainer);
appRoot.render(app);

const notificationContainer = document.getElementById('notification');
const notificationRoot = createRoot(notificationContainer);
notificationRoot.render(<NotificationContainer />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
