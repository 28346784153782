import EnoteAxios from "../axios"
import { updateReadCodeListSuccess, updateReadCodeListFailed, updateTemplateListSuccess, updateTemplateListFailed, updateTemplateItemListSuccess, updateTemplateItemListFailed, updateUser } from "./actions";

export function fetchUserDetail() {
    return dispatch => {
        EnoteAxios.get('/api/user/GetUserDetail').then(response => {
            dispatch(updateUser(response.data));
        }).catch(err => {
            console.log(err);
        })
    }
}

export function fetchReadCodes() {
    return dispatch => {
        EnoteAxios.get('/api/user/GetReadCode').then(response => {
            dispatch(updateReadCodeListSuccess(response.data));
        }).catch(() => {
            dispatch(updateReadCodeListFailed());
        });
    }
}

export function fetchTemplates() {
    return dispatch => {
        EnoteAxios.get('/api/user/GetTemplate').then(response => {
            dispatch(updateTemplateListSuccess(response.data))
        }).catch(() => {
            dispatch(updateTemplateListFailed())
        });
    }
}

export function fetchTemplateItems() {
    return dispatch => {
        EnoteAxios.get('/api/user/GetUserTemplateItemList').then(response => {
            dispatch(updateTemplateItemListSuccess(response.data))
        }).catch(() => {
            dispatch(updateTemplateItemListFailed())
        });
    }
}