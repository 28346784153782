import React from 'react';

const ConditionalArea = (props) => {
    let content = null;
    if (props.show) {
        content = (
            <React.Fragment>
                {props.children}
            </React.Fragment>
        );
    }

    return (
        <React.Fragment>
            {content}
        </React.Fragment>
    );
}

export default ConditionalArea;