import React, { Component } from 'react';
import { Menu, Sidebar, Segment } from 'semantic-ui-react';
import './Board.css';
import { connect } from 'react-redux';
import { Route, Link, Redirect } from 'react-router-dom';
import AppointmentPane from './AppointmentPane/AppointmentPane';
import PatientPane from './PatientPane/PatientPane';
import TemplatePane from './TemplatePane/TemplatePane';
import SettingPane from './SettingPane/SettingPane';
import ReportingPane from './ReportingPane/ReportingPane';
import TopBar from './TopBar/TopBar';
import 'react-notifications/lib/notifications.css';
import CustomLoader from '../../UI/CustomLoader/CustomLoader';
import ConditionalArea from '../../UI/ConditionalArea/ConditionalArea';
import { isMobileOnly } from 'react-device-detect';
import { fetchTemplates, fetchTemplateItems } from '../../store/thunks';

class Board extends Component {
    state = {
        loading: true,
        userInfoRequired: false,
        showSideBar: !isMobileOnly,
        appointmentPaneKey: 1
    }

    async componentDidMount() {
        if (this.props.user) {
            this.props.dispatch(fetchTemplates());
            this.props.dispatch(fetchTemplateItems());

            if (!this.props.user.firstName) {
                this.setState({
                    userInfoRequired: true,
                    loading: false
                });
            }
        }

        this.setState({
            loading: false
        });
    }

    componentDidUpdate(prevProps, prevState, sn) {
        if (prevProps !== this.props) {
            if (this.props.user && this.props.user.firstName) {
                this.setState({
                    userInfoRequired: false
                });
            }

            if (this.props.user.showHourCountOnAppointment !== prevProps.user.showHourCountOnAppointment) {
                this.setState(prevState => ({
                    appointmentPaneKey: prevState.appointmentPaneKey + 1
                }));
            }
        }
    }

    onToggleSideBar = () => {
        this.setState(prevState => ({
            showSideBar: !prevState.showSideBar
        }));
    }

    render() {
        let menuItems = (
            <React.Fragment>
                <Menu.Item as={Link} to={this.props.match.url + '/appointments'} name='Appointments' active={this.props.location.pathname.toLowerCase().indexOf('/board/appointment') > -1} disabled={this.state.userInfoRequired} />
                <Menu.Item as={Link} to={this.props.match.url + '/patients'} name='Patients' active={this.props.location.pathname.toLowerCase().indexOf('/board/patients') > -1} disabled={this.state.userInfoRequired} />
                <Menu.Item as={Link} to={this.props.match.url + '/reporting'} name='Reporting' active={this.props.location.pathname.toLowerCase().indexOf('/board/reporting') > -1} disabled={this.state.userInfoRequired} />
                <Menu.Item as={Link} to={this.props.match.url + '/templates'} name='Templates' active={this.props.location.pathname.toLowerCase().indexOf('/board/templates') > -1} disabled={this.state.userInfoRequired} />
                <Menu.Item as={Link} to={this.props.match.url + '/settings'} name='Settings' active={this.props.location.pathname.toLowerCase().indexOf('/board/settings') > -1} />
            </React.Fragment>
        );

        if (this.state.userInfoRequired) {
            menuItems = (
                <React.Fragment>
                    <Menu.Item as={Link} to={this.props.match.url + '/settings'} name='Settings' active={this.props.location.pathname.toLowerCase().indexOf('/board/settings') > -1} onClick={this.handleMenuItemClicked} />
                </React.Fragment>
            )
        }

        let sideBarSection = null;

        if (isMobileOnly) {
            sideBarSection = (
                <Sidebar
                    className='SideBar Mobile'
                    as={Menu}
                    animation='push'
                    direction='top'
                    icon='labeled'
                    inverted
                    vertical
                    visible={this.state.showSideBar}
                    width='thin'
                    onClick={this.onToggleSideBar}>
                    {menuItems}
                </Sidebar>
            );
        } else {
            sideBarSection = (
                <Sidebar
                    className='SideBar'
                    as={Menu}
                    animation='uncover'
                    direction='left'
                    icon='labeled'
                    inverted
                    vertical
                    visible={this.state.showSideBar}
                    width='thin'>
                    {menuItems}
                </Sidebar>
            );
        }

        let contentSection = (
            <React.Fragment>
                <Route path={this.props.match.url + '/appointments'} component={AppointmentPane} />
                <Route path={this.props.match.url + '/patients'} children={() => <PatientPane />} />
                <Route path={this.props.match.url + '/reporting'} children={() => <ReportingPane />} />
                <Route path={this.props.match.url + '/templates'} component={TemplatePane} />
                <Route path={this.props.match.url + '/settings'} component={SettingPane} />
                {
                    this.state.userInfoRequired ? <Redirect from={this.props.match.url} to={this.props.match.url + '/settings'} /> : <Redirect from={this.props.match.url} to={this.props.match.url + '/appointments'} />
                }
            </React.Fragment>
        )

        let redirect = null;

        if (!this.props.user) {
            redirect = (<Redirect to='/' />);
        }

        return (
            <React.Fragment>
                {redirect}
                <CustomLoader active={this.state.loading} />
                <ConditionalArea show={!this.state.loading}>
                    <TopBar onToggleMenu={this.onToggleSideBar} />
                    <Sidebar.Pushable className='MainBoard' as={Segment}>
                        {sideBarSection}
                        <Sidebar.Pusher
                            className={'MainContent ' + (this.state.showSideBar && !isMobileOnly ? 'SharedWidth' : 'FullWidth')}
                            onClick={isMobileOnly ? () => this.setState({ showSideBar: false }) : null}
                        >
                            <div className='MainContentDiv'>
                                {contentSection}
                            </div>
                        </Sidebar.Pusher>
                    </Sidebar.Pushable>
                </ConditionalArea>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        user: state.user
    };
}

export default connect(mapStateToProps)(Board);