import React, { Component } from 'react';
import Login from './Login/Login';
import PasswordReset from './PasswordReset/PasswordReset';
import './LoginPage.css';
import { Image, Header } from 'semantic-ui-react';

class LoginPage extends Component {
    state = {
        showLogin: true
    }

    switchViewHandler = () => {
        this.setState(prevState => ({
            showLogin: !prevState.showLogin
        }));
    }

    render() {
        let content = null;
        if (this.state.showLogin) {
            content = <Login onSwitchView={this.switchViewHandler} />
        } else {
            content = <PasswordReset onSwitchView={this.switchViewHandler} />
        }

        return (
            <div className='LoginPage'>
                <div className='Banner'>
                    <Image src={process.env.PUBLIC_URL + '/images/logo_128.png'} className='LogoImg' alt='eNote Pro Logo' title='eNote Pro' />
                    <Header as='h1'>eNote Pro</Header>
                </div>
                {content}
            </div>
        )
    }
}

export default LoginPage;