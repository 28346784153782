import React, { Component } from 'react';
import { Form, Message, Button } from 'semantic-ui-react';
import './Login.scss';
import * as actionTypes from '../../../store/actions';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import EnoteAxios from '../../../axios';
import packageJson from '../../../../package.json';
import moment from 'moment';
import { FormatDate } from '../../../assets/helper';
import { NotificationManager } from 'react-notifications';

const USERNAME = 'username';
const PASSWORD = 'password';
const REMEMBER_ME = 'rememberMe';

class Login extends Component {
    state = {
        [USERNAME]: '',
        [PASSWORD]: '',
        [REMEMBER_ME]: false,
        loading: true,
        showError: false,
        showResendEmail: false,
        resendEmailLabel: "Resend Verification Email",
        resendEmailClicked: false,
        errorMsg: ''
    }

    async componentDidMount() {
        if (!this.props.token || !this.props.savedUser) {
            let storageToken = sessionStorage.getItem('enote-token');
            let foundInSession = true;
            if (!storageToken) {
                storageToken = localStorage.getItem('enote-token');
                foundInSession = false;
            }
            if (storageToken) {
                if (!foundInSession) {
                    sessionStorage.setItem('enote-token', storageToken);
                }
                // authenticate and then get user detail
                await EnoteAxios.get('/api/account/Login')
                    .then(response => {
                        if (response.data) {
                            this.props.onUpdateUserAndToken(response.data.userDetail, response.data.token, true);
                            if (moment().add(7, "days").isAfter(moment(response.data.expiryDate))) {
                                NotificationManager.warning("Your license will expire on " + FormatDate(response.data.expiryDate), "License Expiring", 10000);
                            }

                            document.title = 'eNote - ' + response.data.userDetail.username;
                            return;
                        }
                    })
                    .catch(err => {
                        console.log(err);
                        let errorResponseData = '';
                        if (err && err.response) {
                            errorResponseData = err.response.data;
                            if (err.response.data.indexOf("Account Not Activated") > -1) {
                                this.setState({
                                    showResendEmail: true,
                                    showResendEmailCoolDown: false
                                });
                            }
                        }

                        this.setState({
                            loading: false,
                            showError: true,
                            errorMsg: errorResponseData
                        });
                        sessionStorage.removeItem('enote-token');
                        localStorage.removeItem('enote-token');
                    });
            } else {
                this.setState({
                    loading: false
                });
            }
        }
    }

    handleSubmit = async (e) => {
        e.preventDefault();
        this.setState({
            loading: true
        });

        // call login here
        await EnoteAxios.post('/api/account/login', {
            username: this.state.username,
            password: this.state.password
        }).then(response => {
            if (response.data && response.data.token) {
                sessionStorage.setItem('enote-token', response.data.token);
                if (this.state.rememberMe) {
                    localStorage.setItem('enote-token', response.data.token);
                }
                this.props.onUpdateUserAndToken(response.data.userDetail, response.data.token);
                if (moment().add(7, "days").isAfter(moment(response.data.expiryDate))) {
                    NotificationManager.warning("Your license will expire on " + FormatDate(response.data.expiryDate), "License Expiring", 10000);
                }

                document.title = 'eNote - ' + response.data.userDetail.username;
            }
        }).catch(err => {
            console.log(err);
            let errorResponseData = '';
            if (err && err.response) {
                errorResponseData = err.response.data;
                if (err.response.data.indexOf("Account Not Activated") > -1) {
                    this.setState({
                        showResendEmail: true,
                        showResendEmailCoolDown: false
                    });
                }
            }

            this.setState({
                loading: false,
                showError: true,
                errorMsg: errorResponseData
            });
        });
    }

    handleChange = (e, { name, value }) => {
        this.setState({
            [name]: value
        });
    }

    checkBoxChangeHandler = (e, { name, checked }) => {
        this.setState({
            [name]: checked
        });
    }

    resendEmailHandler = async () => {
        await EnoteAxios.post('/api/account/ResendVerificationEmail', { username: this.state.username }).then(response => {
            this.setState({
                resendEmailLabel: "Email Sent.",
                resendEmailClicked: true
            });
        }).catch(err => {
            this.setState({
                showResendEmail: false,
                showError: true,
                errorMsg: err.response.data ? err.response.data : ''
            });
        })
    }

    render() {
        let error = null;
        if (this.state.showError && !this.state.loading) {
            error = (
                <Message negative>
                    <Message.Header>Login Failed</Message.Header>
                    <p>{this.state.errorMsg}</p>
                </Message>
            );
        }

        let resendEmail = null;
        if (this.state.showResendEmail) {
            resendEmail = <Button className='Resend_Email' content={this.state.resendEmailLabel} onClick={this.resendEmailHandler} disabled={this.state.resendEmailClicked} />
        }

        let content = null;
        if (this.props.savedUser && this.props.token) {
            content = <Redirect to='/board' />
        }
        else {
            content = (
                <Form onSubmit={this.handleSubmit} className='LoginPane'>
                    <Form.Field>
                        <Form.Input type='email' className='Login_Input' label='Username' name={USERNAME} value={this.state.username} onChange={this.handleChange} readOnly={this.state.loading} />
                    </Form.Field>
                    <Form.Field>
                        <Form.Input type='password' className='Login_Input' label='Password' name={PASSWORD} value={this.state.password} onChange={this.handleChange} readOnly={this.state.loading} />
                    </Form.Field>
                    <Form.Field>
                        <Form.Checkbox label='Remember Me' name={REMEMBER_ME} checked={this.state.rememberMe} onChange={this.checkBoxChangeHandler} readOnly={this.state.loading} />
                    </Form.Field>
                    <Form.Field>
                        <Button className='Login_Input LoginPaneButtons' content='Login' disabled={this.state.loading} primary loading={this.state.loading} />
                    </Form.Field>
                    <Form.Field>
                        <Button type='button' onClick={() => window.location.replace(packageJson.enote.homePage + '#signup')} className='Login_Input LoginPaneButtons' content='Sign up' disabled={this.state.loading} />
                    </Form.Field>
                    <div className='AdditionalOptions'>
                        <div>
                            <a href={packageJson.enote.homePage} target='_blank' rel='noopener noreferrer'>Homepage</a>
                        </div>
                        <div>
                            <span onClick={this.props.onSwitchView}>Forget Password?</span>
                        </div>
                    </div>
                    <div>
                        {error}
                    </div>
                    <div>
                        {resendEmail}
                    </div>
                </Form>
            );
        }

        return (
            <div className='Login'>
                {content}
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        savedUser: state.user,
        token: state.token
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onUpdateUserAndToken: (user, token) => dispatch({
            type: actionTypes.UPDATE_USER_AND_TOKEN,
            payload: {
                user: user,
                token: token
            }
        })
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);