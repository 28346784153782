export const UPDATE_USER = 'UPDATE_USER';
export const UPDATE_USER_AND_TOKEN = 'UPDATE_USER_AND_TOKEN';
export const UPDATE_READ_CODE_LIST = 'UPDATE_READ_CODE_LIST';
export const UPDATE_TEMPLATE_LIST = 'UPDATE_TEMPLATE_LIST';
export const UPDATE_TEMPLATE_ITEM_LIST = 'UPDATE_TEMPLATE_ITEM_LIST';
export const UPDATE_APPOINTMENT_VIEW_CONFIG = 'UPDATE_APPOINTMENT_VIEW_CONFIG';

export function updateUser(user) {
    return {
        type: UPDATE_USER,
        payload: {
            user: user
        }
    }
}

export function updateUserAndToken(user, token) {
    return {
        type: UPDATE_USER,
        payload: {
            user: user,
            token: token
        }
    }
}

export function updateReadCodeListSuccess(readCodes) {
    return {
        type: UPDATE_READ_CODE_LIST,
        payload: {
            readCodes: readCodes,
            readCodesLoaded: true
        }
    }
}

export function updateReadCodeListFailed() {
    return {
        type: UPDATE_READ_CODE_LIST,
        payload: {
            readCodes: [],
            readCodesLoaded: false
        }
    }
}

export function updateTemplateListSuccess(templates) {
    return {
        type: UPDATE_TEMPLATE_LIST,
        payload: {
            templates: templates,
            templatesLoaded: true
        }
    }
}

export function updateTemplateListFailed() {
    return {
        type: UPDATE_TEMPLATE_LIST,
        payload: {
            templates: [],
            templatesLoaded: false
        }
    }
}

export function updateTemplateItemListSuccess(templateItems) {
    return {
        type: UPDATE_TEMPLATE_ITEM_LIST,
        payload: {
            templateItems: templateItems,
            templateItemsLoaded: true
        }
    }
}

export function updateTemplateItemListFailed() {
    return {
        type: UPDATE_TEMPLATE_ITEM_LIST,
        payload: {
            templateItems: [],
            templateItemsLoaded: false
        }
    }
}

export function updateAppointmentViewConfig(refDate, view) {
    return {
        type: UPDATE_APPOINTMENT_VIEW_CONFIG,
        payload: {
            currentRefDate: refDate,
            view: view
        }
    }
}
