import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form, Grid, Button, Dropdown, Header, Icon, Modal } from 'semantic-ui-react';
import CustomTextArea from '../../../UI/CustomTextArea/CustomTextArea';
import CustomReactTable from '../../../UI/CustomReactTable/CustomReactTable';
import './TemplatePane.css';
import ControlPane from '../../../UI/Controls/Control/ControlPane';
import EnoteAxios from '../../../axios';
import * as actionTypes from '../../../store/actions';
import { NotificationManager } from 'react-notifications';
import TemplateHelp from './TemplateHelp/TemplateHelp';
import { isMobileOnly } from 'react-device-detect';
import { Prompt } from 'react-router-dom';
import ConfrimDialog from '../../../UI/ConfrimDialog/ConfirmDialog';
import { createColumnHelper } from '@tanstack/react-table';

const INDEX = 'index';
const DESCRIPTION = 'description';
const CONTENT = 'content';
const CATEGORY_ID = 'categoryID';
const NAME = 'name'

const categoriesColumnHelper = createColumnHelper();
const templateItemColumnHelper = createColumnHelper();

class TemplatePane extends Component {
    state = {
        templateList: [],
        selectedTemplateID: '',
        selectedTemplateCategoryList: [],
        selectedTemplateCategoryItemList: [],
        selectedTemplateItemID: '',
        isCreatingNew: true,
        templateItemEditing: {
            [INDEX]: 1,
            [DESCRIPTION]: '',
            [CONTENT]: '',
            [CATEGORY_ID]: 0
        },
        selectedCategory: {},
        categoryEditing: {
            [CATEGORY_ID]: 0,
            [INDEX]: 1,
            [NAME]: ''
        },
        isEditingCategory: false,
        showDeleteCategoryWarning: false,
        showDeleteItemWarning: false,
        itemToDelete: {},
        showHelp: false,
        loading: false,
        touched: false
    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps) {
        if (prevProps.templates !== this.props.templates || prevProps.templateItems !== this.props.templateItems) {
            if (this.state.selectedTemplateID) {
                if (this.props.templateItems && this.props.templateItems[this.state.selectedTemplateID]) {
                    let categories = this.props.templateItems[this.state.selectedTemplateID];

                    if (this.state.selectedCategory) {
                        let selectedCategory = categories.find(x => x.categoryId === this.state.selectedCategory.categoryId);
                        if (selectedCategory) {
                            this.setState({
                                selectedTemplateCategoryList: categories,
                                selectedTemplateCategoryItemList: selectedCategory.items,
                                isCreatingNew: true,
                                selectedTemplateItemID: '',
                                templateItemEditing: {
                                    [INDEX]: selectedCategory.items.length + 1,
                                    [DESCRIPTION]: '',
                                    [CONTENT]: '',
                                    [CATEGORY_ID]: selectedCategory.categoryId
                                },
                                touched: false
                            });
                        } else {
                            this.setState({
                                selectedTemplateCategoryList: categories,
                                selectedTemplateCategoryItemList: [],
                                isCreatingNew: true,
                                selectedTemplateItemID: '',
                                selectedCategory: {},
                                templateItemEditing: {
                                    [INDEX]: 1,
                                    [DESCRIPTION]: '',
                                    [CONTENT]: '',
                                    [CATEGORY_ID]: 0
                                },
                                touched: false
                            });
                        }
                    } else {
                        this.setState({
                            selectedTemplateCategoryList: categories,
                            selectedTemplateCategoryItemList: [],
                            isCreatingNew: true,
                            selectedTemplateItemID: '',
                            templateItemEditing: {
                                [INDEX]: 1,
                                [DESCRIPTION]: '',
                                [CONTENT]: '',
                                [CATEGORY_ID]: 0
                            },
                            touched: false
                        });
                    }
                }
            }
        }
    }

    inputChangeHandler = (e, { name, value }) => {
        this.setState(prevState => ({
            templateItemEditing: {
                ...prevState.templateItemEditing,
                [name]: value
            },
            touched: true
        }));
    }

    categoryInputChangeHandler = (e, { name, value }) => {
        this.setState(prevState => ({
            categoryEditing: {
                ...prevState.categoryEditing,
                [name]: value
            }
        }));
    }

    onCreateNewChangeHandler = (e, { checked }) => {
        if (!checked) {
            this.setState({
                isCreatingNew: false,
            });
        } else {
            if (this.state.selectedTemplateID) {
                if (this.state.selectedCategory) {
                    this.setState(prevState => ({
                        isCreatingNew: true,
                        selectedTemplateItemID: '',
                        templateItemEditing: {
                            [INDEX]: prevState.selectedTemplateCategoryItemList.length + 1,
                            [DESCRIPTION]: '',
                            [CONTENT]: '',
                            [CATEGORY_ID]: prevState.selectedCategory.categoryId
                        }
                    }));
                }
            }
            this.setState({
                isCreatingNew: true,
            });
        }
    }

    selectedTemplateIDChangeHandler = (e, { value }) => {
        const categories = this.props.templateItems ? this.props.templateItems[value] : [];

        this.setState({
            selectedTemplateID: value,
            selectedTemplateCategoryList: categories,
            selectedTemplateCategoryItemList: [],
            selectedCategory: {},
            selectedTemplateItemID: '',
            templateItemEditing: {
                [INDEX]: 1,
                [DESCRIPTION]: '',
                [CONTENT]: '',
                [CATEGORY_ID]: 0
            },
            touched: false
        });
    }

    onTemplateCategoryRowClick = (e, rowInfo,) => {
        const category = rowInfo.original;
        this.setState({
            selectedCategory: category,
            selectedTemplateCategoryItemList: category.items,
            templateItemEditing: {
                [INDEX]: category.items.length + 1,
                [DESCRIPTION]: '',
                [CONTENT]: '',
                [CATEGORY_ID]: category.categoryId
            },
            isCreatingNew: true,
            touched: false
        });
    }

    onTemplateItemRowClick = (e, rowInfo) => {
        const templateItem = rowInfo.original;
        this.setState({
            selectedTemplateItemID: templateItem.templateItemId,
            templateItemEditing: {
                [INDEX]: templateItem.index,
                [DESCRIPTION]: templateItem.description,
                [CONTENT]: templateItem.content,
                [CATEGORY_ID]: templateItem.categoryId
            },
            isCreatingNew: false,
            touched: false
        });
    }

    saveTemplateItemClickHandler = async () => {
        if (this.state.templateItemEditing.categoryId === 0) {
            NotificationManager.error('Please select a category', 'Failed');
            return;
        }
        this.toggleLoading(true);

        const templateDetail = {
            userId: this.props.user.userId,
            templateItemID: this.state.selectedTemplateItemID,
            ...this.state.templateItemEditing
        }

        if (this.state.isCreatingNew) {
            await EnoteAxios.post('/api/user/AddTemplateItem', templateDetail).then(response => {
                this.setState(prevState => ({
                    selectedTemplateItemID: '',
                    templateItemEditing: {
                        [INDEX]: prevState.selectedTemplateCategoryItemList.length + 1,
                        [DESCRIPTION]: '',
                        [CONTENT]: '',
                        [CATEGORY_ID]: prevState.selectedCategory.categoryId
                    },
                    isCreatingNew: true,
                    touched: false
                }));
            }).catch(err => {
                console.log(err);
            });
        } else {
            await EnoteAxios.put('/api/user/EditTemplateItem', templateDetail, {
                params: {
                    currentIndex: this.state.selectedTemplateItemID
                }
            }).then(() => {
                this.setState({
                    touched: false
                })
            }).catch(err => {
                console.log(err);
            });
        }
        await this.reloadTemplateItems();
        this.toggleLoading(false);
    }

    editCategoryClickHandler = () => {
        if (this.state.selectedTemplateID) {
            this.setState({
                isEditingCategory: true,
                categoryEditing: {
                    [CATEGORY_ID]: 0,
                    [INDEX]: this.state.selectedTemplateCategoryList ? this.state.selectedTemplateCategoryList.length + 1 : 1,
                    [NAME]: ''
                }
            })
        }
    }

    saveCategoryClickHandler = async () => {
        this.toggleLoading(true);
        const category = {
            userId: this.props.user.userId,
            templateID: this.state.selectedTemplateID,
            ...this.state.categoryEditing
        }

        await EnoteAxios.post('/api/user/UpdateTemplateCategory', category).then(() => {
            this.setState({
                isEditingCategory: false
            });
        }).catch(err => {
            console.log(err);
        });

        await this.reloadTemplateItems();
        this.toggleLoading(false);
    }

    editCategory = async (e, category) => {
        e.stopPropagation();

        this.setState({
            isEditingCategory: true,
            categoryEditing: {
                [CATEGORY_ID]: category.categoryId,
                [NAME]: category.name,
                [INDEX]: category.index
            }
        });
    }

    deleteCategoryClickHandler = async (e, category) => {
        e.stopPropagation();
        this.setState({
            showDeleteCategoryWarning: true,
            itemToDelete: category
        });
    }

    deleteCategory = async (category) => {
        await EnoteAxios.delete('/api/user/DeleteTemplateCategory', {
            params: {
                categoryID: category.categoryId
            }
        }).catch(err => {
            console.log(err);
        }).then(() => {
            this.setState({
                showDeleteCategoryWarning: false
            });
        });
        await this.reloadTemplateItems();
    }

    deleteItemClickHandler = async (e, item) => {
        e.stopPropagation();
        this.setState({
            showDeleteItemWarning: true,
            itemToDelete: item
        });
    }

    deleteItem = async (item) => {
        await EnoteAxios.delete('/api/user/DeleteTemplateItem', {
            params: {
                templateItemID: item.templateItemId
            }
        }).catch(err => {
            console.log(err);
        }).then(() => {
            this.setState({
                showDeleteItemWarning: false
            });
        });
        await this.reloadTemplateItems();
    }

    reloadTemplateItems = async () => {
        await EnoteAxios.get('/api/user/GetUserTemplateItemList').then(response => {
            this.props.updateTemplateItemList(response.data);
        }).catch(err => {
            console.log(err);
        });
    }

    toggleLoading = (isLoading) => {
        this.setState({
            loading: isLoading
        });
    }

    categoriesColumns = [
        categoriesColumnHelper.accessor(r => r.index, {
            id: 'index',
            header: 'Display Order',
        }),
        categoriesColumnHelper.accessor(r => r.name, {
            id: 'name',
            header: 'Category Name',
        }),
        categoriesColumnHelper.accessor(r => r, {
            id: 'action',
            header: 'Action',
            cell: ({ row }) => (
                <React.Fragment>
                    <span className='EditIcon'>
                        <Icon title='Edit Category' onClick={(e) => this.editCategory(e, row.original)} name='edit' />
                    </span>
                    <span className='DeleteIcon'>
                        <Icon title='Delete category and its templates' onClick={(e) => this.deleteCategoryClickHandler(e, row.original)} name='close' />
                    </span>
                </React.Fragment>
            )
        }),
    ];

    templateItemColumns = [
        templateItemColumnHelper.accessor(r => r.index, {
            id: 'index',
            header: 'Display Order',
        }),
        templateItemColumnHelper.accessor(r => r.description, {
            id: 'description',
            header: 'Name',
        }),
        templateItemColumnHelper.accessor(r => r.content, {
            id: 'content',
            header: 'Content',
        }),
        templateItemColumnHelper.accessor(r => r, {
            id: 'action',
            header: 'Action',
            cell: ({ row }) => (
                <span className='DeleteIcon'>
                    <Icon title='Delete' onClick={(e) => this.deleteItemClickHandler(e, row.original)} name='close' />
                </span>
            )
        }),
    ];
    
    render() {

        const templateOptions = this.props.templates.map(template => {
            return {
                text: template.name,
                value: template.templateId
            }
        });



        let categoryOptions = [];
        if (this.state.selectedTemplateID && this.state.selectedCategory && this.state.selectedTemplateCategoryList) {
            categoryOptions = this.state.selectedTemplateCategoryList.map(val => {
                return {
                    text: val.name,
                    value: val.categoryId
                }
            });
        }

        return (
            <div className='TemplatePane ContentMainPane'>
                <div className='TemplateDiv'>
                    <Form.Group widths='16'>
                        <Grid.Column width='16'>
                            <Grid.Row>
                                <label><strong>Select a template from the list below</strong></label>
                            </Grid.Row>
                            <Grid.Row>
                                <Dropdown width='16' fluid search selection options={templateOptions} name='selectedTemplateID' value={this.state.selectedTemplateID} onChange={this.selectedTemplateIDChangeHandler} placeholder='Type to search for templates' />
                            </Grid.Row>
                        </Grid.Column>
                    </Form.Group>
                    <Grid>
                        <Grid.Column mobile={16} tablet={8} computer={8}>
                            <Header dividing className='TableHeader'>Categories</Header>
                            <Form.Field width='8'>
                                <CustomReactTable
                                    columns={this.categoriesColumns}
                                    data={this.state.selectedTemplateCategoryList}
                                    onRowClick={this.onTemplateCategoryRowClick}
                                    clickable
                                    pagination
                                    stickyHeader
                                    noDataText={this.state.selectedTemplateID ? 'No Category found. Please add a new one.' : 'Choose a template from the Template table'}
                                    highlightedRow={{
                                        fieldName: [INDEX],
                                        highlightedValue: this.state.selectedCategory.index
                                    }}
                                />
                            </Form.Field>
                        </Grid.Column>
                        <Grid.Column mobile={16} tablet={8} computer={8}>
                            <Header dividing className='TableHeader'>Category Items</Header>
                            <Form.Field width='8'>
                                <CustomReactTable
                                    columns={this.templateItemColumns}
                                    data={this.state.selectedTemplateCategoryItemList}
                                    onRowClick={this.onTemplateItemRowClick}
                                    clickable
                                    pagination
                                    stickyHeader
                                    noDataText={this.state.selectedTemplateID ? 'No Category found. Please add a new one.' : 'Choose a template from the Template table'}
                                    highlightedRow={{
                                        fieldName: 'templateItemId',
                                        highlightedValue: this.state.selectedTemplateItemID,
                                    }}
                                />
                            </Form.Field>
                        </Grid.Column>

                    </Grid>
                </div>
                <div className='TemplateEditingDiv'>
                    <Form>
                        <Header as='h3' dividing>Template Item Detail</Header>
                        <Form.Group widths='equal'>
                            <Form.Field className='CreateNewGrid'>
                                <Form.Checkbox label='Create New Item' toggle checked={this.state.isCreatingNew} onChange={this.onCreateNewChangeHandler} />
                            </Form.Field>
                            <Form.Field>
                                <Form.Input label='Item Display Order' type='number' name={INDEX} value={this.state.templateItemEditing.index} onChange={this.inputChangeHandler} />
                            </Form.Field>
                            <Form.Field>
                                <Form.Dropdown label='Category' selection name={CATEGORY_ID} options={categoryOptions} value={this.state.templateItemEditing.categoryID} onChange={this.inputChangeHandler} />
                            </Form.Field>
                            <Form.Field>
                                <Form.Input label='Item Name' type='text' name={DESCRIPTION} value={this.state.templateItemEditing.description} onChange={this.inputChangeHandler} />
                            </Form.Field>

                        </Form.Group>
                        <Form.Group widths='16'>
                            <Form.Field width='16'>
                                <label>Item Content</label>
                                <CustomTextArea name={CONTENT} value={this.state.templateItemEditing.content} onChange={this.inputChangeHandler} rows={4} />
                            </Form.Field>
                        </Form.Group>

                        <div className='TemplateEditingControlPane'>
                            <ControlPane>
                                <Button primary onClick={this.editCategoryClickHandler} className='NewCategoryBtn' disabled={!this.state.selectedTemplateID}>New Category</Button>
                                {
                                    isMobileOnly ?
                                        <Icon className='HelpIcon' name='help circle' size='large' onClick={() => this.setState({ showHelp: true })} /> :
                                        <p className='HelpText' onClick={() => this.setState({ showHelp: true })}>How to use templates?</p>
                                }

                                <Button primary onClick={this.saveTemplateItemClickHandler} disabled={!this.state.selectedTemplateID || !this.state.templateItemEditing.description || (this.state.loading && !this.state.isEditingCategory)} loading={this.state.loading && !this.state.isEditingCategory}>Save</Button>
                            </ControlPane>
                        </div>
                    </Form>
                </div>
                <Modal open={this.state.isEditingCategory} size='tiny'>
                    <Modal.Header>Template Category</Modal.Header>
                    <Modal.Content>
                        <Form autoComplete='off' onSubmit={this.saveCategoryClickHandler}>
                            <Form.Group>
                                <Form.Field>
                                    <Form.Input type='text' name='name' label='Category Name' value={this.state.categoryEditing.name} onChange={this.categoryInputChangeHandler} />
                                </Form.Field>
                                <Form.Field>
                                    <Form.Input type='number' name={INDEX} label='Display Order' value={this.state.categoryEditing.index} onChange={this.categoryInputChangeHandler} />
                                </Form.Field>
                            </Form.Group>
                            <ControlPane>
                                <Button type='button' onClick={() => this.setState({ isEditingCategory: false })}>Close</Button>
                                <Button type='submit' primary disabled={!this.state.categoryEditing.name || !this.state.categoryEditing.index || (this.state.loading && this.state.isEditingCategory)} loading={this.state.loading && this.state.isEditingCategory}>Save</Button>
                            </ControlPane>
                        </Form>

                    </Modal.Content>
                </Modal>
                <ConfrimDialog header='Delete Category' content={'Are you sure you want to delete category ' + this.state.itemToDelete.name + ' and its templates?'} open={this.state.showDeleteCategoryWarning} onConfirm={() => this.deleteCategory(this.state.itemToDelete)} onCancel={() => this.setState({ showDeleteCategoryWarning: false })} />
                <ConfrimDialog header='Delete Category Item' content={'Are you sure you want to delete ' + this.state.itemToDelete.description + '?'} open={this.state.showDeleteItemWarning} onConfirm={() => this.deleteItem(this.state.itemToDelete)} onCancel={() => this.setState({ showDeleteItemWarning: false })} />
                <TemplateHelp open={this.state.showHelp} onClose={() => this.setState({ showHelp: false })} />
                <Prompt
                    when={this.state.touched}
                    message='Are you sure you want to close the page? Any unsaved change will be lost.' />
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        user: state.user,
        token: state.token,
        templates: state.templates,
        templateItems: state.templateItems
    }
}

const mapDispatchToProps = dispatch => {
    return {
        updateTemplateItemList: (templateItems) => dispatch({
            type: actionTypes.UPDATE_TEMPLATE_ITEM_LIST,
            payload: {
                templateItems: templateItems
            }
        })
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TemplatePane);