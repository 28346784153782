import React, { Component } from 'react';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import './AppointmentPane.css';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import { views } from 'react-big-calendar/lib/utils/constants';
import moment from 'moment';
import ControlPane from '../../../UI/Controls/Control/ControlPane';
import { Button, Modal, Icon } from 'semantic-ui-react';
import { connect } from 'react-redux';
import EnoteAxios from '../../../axios';
import { FormatDate, FormatDateTime, GetPatientTabID, GetPatientFullNameShort } from '../../../assets/helper';
import ScheduleAppointment from './ScheduleAppointment/ScheduleAppointment';
import CustomLoader from '../../../UI/CustomLoader/CustomLoader';
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop';
import 'react-big-calendar/lib/addons/dragAndDrop/styles.css';
import { NotificationManager } from 'react-notifications';
import { isMobile, isMobileOnly } from 'react-device-detect';
import HoursHeader from './CustomComponents/HoursHeader';
import AppointmentViewer from './ViewAppointment/AppointmentViewer';
import * as actionTypes from '../../../store/actions';
import ConfrimDialog from '../../../UI/ConfrimDialog/ConfirmDialog';

const localizer = momentLocalizer(moment);
const DraggableCalendar = withDragAndDrop(Calendar);

class AppointmentPane extends Component {
    state = {
        isScheduling: false,
        isViewing: false,
        loadedEvents: [],
        dailyWorkTimes: {},
        selectedAppointmentID: 0,
        eventLoadingStartDate: '',
        eventLoadingEndDate: '',
        currentView: 'month',
        loading: true,
        showConfirmationMsg: false,
        confirmationMsg: '',
        appointmentToMove: {
            appointmentId: 0,
            newStartDateTime: '',
            newStopDateTime: ''
        },
        newAppointmentTemplate: {
            //This is used to save copied events so that it can be passed to Schedule class.
            refAppointmentId: 0,        // AppointmentID of the copied appointment
            refStartDateTime: '',       // Start/Stop date time of the copied appointment
            refStopDateTime: '',        // 
            startDateTime: '',          // Used to store the start time of the calendar slot clicked (click a slot to add a new appointment). Empty when create throught <New Appointment> Button
            stopDateTime: ''            //
        },
        showAddEventConfirmationMsg: false,
        addEventConfirmationMsg: ''
    }

    async componentDidMount() {
        await this.RefreshAppointmentList();

        this.setState({
            loading: false
        });
    }

    async componentDidUpdate(prevProps) {
        if (this.props.appointmentView !== prevProps.appointmentView) {
            await this.RefreshAppointmentList();
        }
    }

    addAppointmentClickHandler = async (e, createWithDates) => {
        if (this.state.loading) {
            return;
        }
        if (!createWithDates) {
            this.setState(prevState => ({
                isScheduling: true,
                newAppointmentTemplate: {
                    ...prevState.newAppointmentTemplate,
                    startDateTime: '',
                    stopDateTime: '',
                }
            }));
        }
        else if (this.state.newAppointmentTemplate.refAppointmentId !== 0) {
            let newDateTimeStr = FormatDateTime(moment(e.start));
            this.setState(prevState => ({
                newAppointmentTemplate: {
                    ...prevState.newAppointmentTemplate,
                    startDateTime: newDateTimeStr
                }
            }));
            await this.duplicateAppointment(newDateTimeStr, false, false);
        } else if (createWithDates) {
            let newStartTime = moment(e.start);
            let newstopTime = moment(e.start).add(1, 'hour');

            this.setState(prevState => ({
                isScheduling: true,
                newAppointmentTemplate: {
                    ...prevState.newAppointmentTemplate,
                    startDateTime: FormatDateTime(newStartTime),
                    stopDateTime: FormatDateTime(newstopTime),
                }
            }));
        }
    }

    duplicateAppointment = async (newStartDateTime, bypassLimitCheck = false, bypassOverlapCheck = false) => {
        await EnoteAxios.post('/api/appointment/DuplicateAppointment', {}, {
            params: {
                refAppointmentID: this.state.newAppointmentTemplate.refAppointmentId,
                newStartTime: newStartDateTime,
                bypassLimitCheck: bypassLimitCheck,
                bypassOverlapCheck: bypassOverlapCheck
            }
        }).then(async () => {
            this.setState({
                showAddEventConfirmationMsg: false,
                addEventConfirmationMsg: ''
            });
            await this.RefreshAppointmentList();
        }).catch(err => {
            if (err && err.response && err.response.status === 422 && err.response && err.response.data && err.response.data.confirmation) {
                this.setState({
                    showAddEventConfirmationMsg: true,
                    addEventConfirmationMsg: err.response.data.confirmation
                });
            }
        });
    }

    saveAppointmentClickHandler = async () => {
        this.setState({
            isScheduling: false,
            selectedAppointmentID: 0
        });
        await this.RefreshAppointmentList();
    }

    closeAppointmentClickHandler = () => {
        this.setState({
            isScheduling: false,
            isViewing: false,
            //selectedAppointmentID: 0
        });
    }

    calendarNaviagteEventHandler = async (date) => {
        this.props.updateAppointmentViewConfig(moment(date).format('YYYY-MM-DD HH:mm:ss'), this.props.appointmentView.view);
    }

    calendarViewModeChangeHandler = async (view) => {
        this.props.updateAppointmentViewConfig(this.props.appointmentView.currentRefDate, view);
    }

    calendarEventDropHandler = async ({ event, start, end, allDay }) => {
        await this.RescheduleAppointment(event, start, end, false, false);
    };

    viewEventClickHandler = (appointment, e) => {
        //e.stopPropagation();
        this.setState({
            isViewing: true,
            selectedAppointmentID: appointment.appointmentId
        });
    }

    copyEventClickHandler = (e, appointmentID, startTime, stopTime) => {
        e.preventDefault();
        this.setState({
            newAppointmentTemplate: {
                refAppointmentId: appointmentID,
                refStartDateTime: FormatDateTime(startTime),
                refStopDateTime: FormatDateTime(stopTime),
                startDateTime: '',
                stopDateTime: ''
            },
            isScheduling: false,
            isViewing: false
        });
        NotificationManager.success('Click on a new time slot to create a new appointment', "Copied");
    }

    deleteEventClickHandler = async (e, appointmentId) => {
        e.preventDefault();

        if (appointmentId > 0) {
            this.setState({
                isScheduling: false,
                isViewing: false,
                selectedAppointmentID: 0
            });

            if (this.state.newAppointmentTemplate.refAppointmentId === appointmentId) {
                this.setState({
                    newAppointmentTemplate: {
                        refAppointmentId: 0,
                        refStartDateTime: '',
                        refStopDateTime: '',
                        stopDateTime: ''
                    }
                });
            }
            await EnoteAxios.delete('/api/appointment/DeleteAppointment', {
                params: {
                    appointmentID: appointmentId
                }
            }).then(async () => {
                if (this.props.onDelete)
                    this.props.onDelete(appointmentId)
            }).catch(err => {
                console.log(err)
            }).then(async () => {
                await this.RefreshAppointmentList();
            })
        }
    }

    openPatientPageClickHandler = (e, patientDetail) => {
        e.preventDefault();
        this.setState({
            isViewing: false,
            isScheduling: false
        });

        this.props.history.push({
            pathname: '/board/patients',
            state: {
                tabs: [{
                    tabID: GetPatientTabID(patientDetail.patientId),
                    tabTitle: GetPatientFullNameShort(patientDetail.patientFirstName, patientDetail.patientLastName),
                    componentClass: 'PatientDetail',
                    data: {
                        patientId: patientDetail.patientId
                    }
                }]
            }
        })
    }

    editAppointmentClickHandler = async (e, appointmentId) => {
        e.preventDefault();
        this.setState({
            isViewing: false,
            isScheduling: true,
            selectedAppointmentID: appointmentId
        });
    }

    RescheduleAppointment = async (event, start, end, bypassLimitCheck, bypassOverlapCheck) => {
        if (!event) {
            event = this.state.appointmentToMove;
            start = this.state.appointmentToMove.newStartDateTime;
            end = this.state.appointmentToMove.newStopDateTime;
        }
        await EnoteAxios.put('/api/appointment/RescheduleAppointment', null, {
            params: {
                appointmentID: event.appointmentId,
                newStartDateTime: FormatDateTime(start),
                newStopDateTime: FormatDateTime(end),
                bypassLimitCheck: bypassLimitCheck,
                bypassOverlapCheck: bypassOverlapCheck
            }
        }).then(async () => {
            this.setState({
                loading: false,
                showConfirmationMsg: false,
                confirmationMsg: ''
            });
            await this.RefreshAppointmentList();
        }).catch(err => {
            if (err && err.response.status === 422 && err.response && err.response.data && err.response.data.confirmation) {
                this.setState({
                    loading: false,
                    showConfirmationMsg: true,
                    confirmationMsg: err.response.data.confirmation,
                    appointmentToMove: {
                        appointmentId: event.appointmentId,
                        newStartDateTime: start,
                        newStopDateTime: end
                    }
                });
            } else {
                this.setState({
                    loading: false
                });
            }
        })
    }

    RescheduleAppointmentCancelHandler = () => {
        this.setState({
            loading: false,
            showConfirmationMsg: false,
            confirmationMsg: ''
        });
    }

    RefreshAppointmentList = async () => {
        let start = '';
        let stop = '';

        let date = '';
        let view = 'month';
        if (this.props.appointmentView && this.props.appointmentView.currentRefDate) {
            date = this.props.appointmentView.currentRefDate;
        } else {
            date = new Date();
        }

        if (this.props.appointmentView && this.props.appointmentView.view) {
            view = this.props.appointmentView.view;
        }

        switch (view) {
            case views.MONTH:
                start = moment(date).startOf('month').startOf('week');
                stop = moment(date).endOf('month').endOf('week');
                break;
            case views.WEEK:
                start = moment(date).startOf('week');
                stop = moment(date).endOf('week');
                break;
            case views.DAY:
                start = moment(date).startOf('day');
                stop = moment(date).endOf('day');
                break;
            case views.AGENDA:
                start = moment(date).startOf('day');
                stop = moment(date).endOf('day').add(1, 'month');
                break;
            default:
                start = moment().add(-30, 'days');
                stop = moment().add(30, 'days');
        }

        await this.LoadAppointments(start.format('YYYY-MM-DD HH:mm:ss'), stop.format('YYYY-MM-DD HH:mm:ss'));
    }

    LoadAppointments = async (startDateTime, stopDateTime) => {
        this.setState({ loading: true });
        await EnoteAxios.get('/api/appointment/GetAppointments', {
            params: {
                startTime: startDateTime,
                stopTime: stopDateTime
            }
        }).then(async (response) => {
            if (this.props.user.showHourCountOnAppointment && (this.props.appointmentView.view === 'week' || this.props.appointmentView.view === 'day')) {
                await this.LoadDailyWorkTime(startDateTime, stopDateTime);
            }

            this.setState({
                loadedEvents: response.data,
                eventLoadingStartDate: startDateTime,
                eventLoadingEndDate: stopDateTime
            });
        }).catch(err => {
            console.log(err);
        }).then(() => {
            this.setState({ loading: false });
        });
    }

    LoadDailyWorkTime = async (startDateTime, stopDateTime) => {
        await EnoteAxios.get('/api/report/GetDailyWorkTimeSummary', {
            params: {
                startDate: startDateTime,
                stopDate: stopDateTime
            }
        }).then(response => {
            this.setState({
                dailyWorkTimes: response.data
            })
        }).catch(err => {
            console.log(err);
        })
    }

    getEventTitle(event) {
        return (FormatDate(event.startDateTime, "HH:mm")) + ' ' + (event.patientFirstName ? event.patientFirstName : '') + ' ' + (event.patientLastName ? event.patientLastName : '') + (event.comment ? ' (' + event.comment + ')' : '');
    }

    customEventPropGetter = (event, start, end, isSelected) => {
        if (this.props.appointmentView.view === 'agenda')
            return {};
        let background = '#3174ad';
        let reminder = event.appointmentId % 10;
        switch (reminder) {
            case 1:
                background = '#008b68';
                break;
            case 2:
                background = '#d2891e';
                break;
            case 3:
                background = '#800080';
                break;
            case 4:
                background = '#2F4F4F';
                break;
            case 5:
                background = '#483D8B';
                break;
            case 6:
                background = '#228B22';
                break;
            case 7:
                background = '#A0522D';
                break;
            case 8:
                background = '#696969';
                break;
            case 9:
                background = '#808000';
                break;
            default:
                background = '#3174ad';
                break;
        }

        return {
            style: {
                backgroundColor: background
            }
        }
    }

    clearCopiedAppointment = () => {
        this.setState({
            newAppointmentTemplate: {
                refAppointmentId: 0,
                refStartDateTime: '',
                refStopDateTime: '',
                startDateTime: '',
                stopDateTime: ''
            },
            selectedAppointmentID: 0
        });
    }

    getWorkHourCountForDate = (date) => {
        return this.state.dailyWorkTimes[date];
    }

    render() {
        let customComponents = {};
        if (this.props.user.showHourCountOnAppointment) {
            customComponents = {
                week: {
                    header: HoursHeader({ getWorkTimeCount: this.getWorkHourCountForDate, view: 'week' })
                },
                day: {
                    header: HoursHeader({ getWorkTimeCount: this.getWorkHourCountForDate, view: 'day' })
                }
            }
        }

        return (
            <div className='AppointmentPane'>
                <ControlPane flex align='left'>
                    <Modal
                        size='small'
                        trigger={<Button primary onClick={(e) => this.addAppointmentClickHandler(e, false)}>New Appointment</Button>}
                        open={this.state.isScheduling}
                        closeIcon
                        onClose={() => this.setState({ isScheduling: false, selectedAppointmentID: 0 })}>
                        <Modal.Header>{this.state.selectedAppointmentID === 0 ? 'New Appointment' : 'Edit Appointment'}</Modal.Header>
                        <Modal.Content>
                            <ScheduleAppointment
                                selectedAppointmentID={this.state.selectedAppointmentID}
                                appointmentTemplate={this.state.newAppointmentTemplate}
                                onSave={this.saveAppointmentClickHandler}
                                onClose={this.closeAppointmentClickHandler}
                                onDelete={this.deleteEventClickHandler}
                                onCopy={this.copyEventClickHandler}
                                onViewPatient={this.openPatientPageClickHandler}
                            />
                        </Modal.Content>
                    </Modal>
                    <Modal
                        size='tiny'
                        open={this.state.isViewing}
                        closeIcon
                        onClose={() => this.setState({ isViewing: false, selectedAppointmentID: 0 })}>
                        <Modal.Header>Appointment</Modal.Header>
                        <Modal.Content>
                            <AppointmentViewer
                                appointmentId={this.state.selectedAppointmentID}
                                onClose={this.closeAppointmentClickHandler}
                                onDelete={this.deleteEventClickHandler}
                                onCopy={this.copyEventClickHandler}
                                onViewPatient={this.openPatientPageClickHandler}
                                onEdit={this.editAppointmentClickHandler}
                            />
                        </Modal.Content>
                    </Modal>
                    <Button primary onClick={() => this.LoadAppointments(this.state.eventLoadingStartDate, this.state.eventLoadingEndDate)} disabled={this.state.loading}>Refresh</Button>
                    {
                        this.state.newAppointmentTemplate.refAppointmentId !== 0 ?
                            <Button primary onClick={this.clearCopiedAppointment} disabled={this.state.newAppointmentTemplate.refAppointmentId === 0}>Stop Copying</Button> : null
                    }

                    {
                        !isMobileOnly && this.props.user.showHourCountOnAppointment && (this.props.appointmentView.view === 'week' || this.props.appointmentView.view === 'day') ? (
                            <div className='flex-right'>
                                <span className='HourTips'>
                                    <Icon name='calendar outline' size='large' />
                                    <span>Appointment Hours</span>
                                </span>
                                <span className='HourTips'>
                                    <Icon name='checked calendar' size='large' />
                                    <span>Invoiced Hours</span>
                                </span>
                            </div>
                        ) : null
                    }

                </ControlPane>
                <CustomLoader active={this.state.loading} cssLoader fullScreen />
                <div className={this.state.loading ? 'BigCalendarDiv loading' : 'BigCalendarDiv'}>
                    {
                        isMobile ?
                            <Calendar
                                className={Object.entries(customComponents).length > 0 ? 'ShowHoursCount' : ''}
                                localizer={localizer}
                                events={this.state.loadedEvents}
                                startAccessor={(event) => { return moment(event.startDateTime).toDate() }}
                                endAccessor={(event) => { return moment(event.stopDateTime).toDate() }}
                                titleAccessor={(event) => { return this.getEventTitle(event) }}
                                onNavigate={this.calendarNaviagteEventHandler}
                                onSelectEvent={this.viewEventClickHandler}
                                onView={this.calendarViewModeChangeHandler}
                                defaultDate={moment(this.props.appointmentView.currentRefDate).toDate()}
                                view={this.props.appointmentView.view}
                                eventPropGetter={this.customEventPropGetter}
                                draggableAccessor={(event) => true}
                                resizableAccessor={(event) => false}
                                selectable
                                longPressThreshold={500}
                                popup
                                step={5}
                                timeslots={6}
                                onSelectSlot={(e) => this.addAppointmentClickHandler(e, true)}
                                components={customComponents}
                                scrollToTime={new Date(1970, 1, 1, 5, 0, 0)}
                            /> :
                            <DraggableCalendar
                                className={Object.entries(customComponents).length > 0 ? 'ShowHoursCount' : ''}
                                localizer={localizer}
                                events={this.state.loadedEvents}
                                startAccessor={(event) => { return moment(event.startDateTime).toDate() }}
                                endAccessor={(event) => { return moment(event.stopDateTime).toDate() }}
                                titleAccessor={(event) => { return this.getEventTitle(event) }}
                                onNavigate={this.calendarNaviagteEventHandler}
                                onEventDrop={this.calendarEventDropHandler}
                                onSelectEvent={this.viewEventClickHandler}
                                onView={this.calendarViewModeChangeHandler}
                                defaultDate={moment(this.props.appointmentView.currentRefDate).toDate()}
                                view={this.props.appointmentView.view}
                                eventPropGetter={this.customEventPropGetter}
                                draggableAccessor={(event) => true}
                                resizableAccessor={(event) => false}
                                selectable
                                resizable
                                popup
                                step={5}
                                timeslots={6}
                                onSelectSlot={(e) => this.addAppointmentClickHandler(e, true)}
                                components={customComponents}
                                scrollToTime={new Date(1970, 1, 1, 5, 0, 0)}
                            />
                    }

                </div>
                <ConfrimDialog content={this.state.confirmationMsg} open={this.state.showConfirmationMsg} onConfirm={() => this.RescheduleAppointment(null, null, null, true, true)} onCancel={this.RescheduleAppointmentCancelHandler} />
                <ConfrimDialog content={this.state.addEventConfirmationMsg} open={this.state.showAddEventConfirmationMsg} onConfirm={() => this.duplicateAppointment(this.state.newAppointmentTemplate.startDateTime, true, true)} onCancel={() => this.setState({ showAddEventConfirmationMsg: false, addEventConfirmationMsg: '' })} />
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        user: state.user,
        token: state.token,
        appointmentView: state.appointmentView
    }
}

const mapDispatchToProps = dispatch => {
    return {
        updateAppointmentViewConfig: (refDate, view) => dispatch({
            type: actionTypes.UPDATE_APPOINTMENT_VIEW_CONFIG,
            payload: {
                currentRefDate: refDate,
                view: view
            }
        })
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AppointmentPane);