import axios from 'axios';
import packageJson from '../package.json';

//let baseURL = 'https://provider.api-compliance.acc.co.nz/api/v2/';
let baseURL = 'https://provider.api.acc.co.nz/api/v2/';

const AccAxios = axios.create({
    baseURL: baseURL,
    headers: {
        apikey: packageJson.enote.accKey
    }
});

AccAxios.interceptors.request.use(request => {
    return request;
}, error => {
    console.log(error);
});

AccAxios.interceptors.response.use((response) => {
    return response;
}, error => {
    console.log(error);
    return Promise.reject(error);
});

export default AccAxios;
