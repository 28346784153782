import React, { useState } from 'react';
import { Modal, Header, Image, Button, ButtonProps } from 'semantic-ui-react';
import usageOne from '../../../../assets/images/help-one-level-template.gif';
import usageTwo from '../../../../assets/images/help-two-level-template.gif';
import demoOne from '../../../../assets/videos/template-one-lv-help.mp4';
import demoTwo from '../../../../assets/videos/template-two-lv-help.mp4';

type TemplateHelpProps = {
    open: boolean,
    onClose: () => void
}

const TemplateHelp = React.memo((props: TemplateHelpProps) => {

    const [showUsageOne, setShowUsageOne] = useState(false);
    const [showUsageTwo, setShowUsageTwo] = useState(false);
    const [showDemoOne, setShowDemoOne] = useState(false);
    const [showDemoTwo, setShowDemoTwo] = useState(false);

    const toggleHelp = (e: React.MouseEvent, { name }: ButtonProps) => {
        switch (name) {
            case 'usage-one':
                setShowUsageOne(!showUsageOne);
                setShowDemoOne(false);
                setShowUsageTwo(false);
                setShowDemoTwo(false);
                break;
            case 'usage-two':
                setShowUsageOne(false);
                setShowDemoOne(false);
                setShowUsageTwo(!showUsageTwo);
                setShowDemoTwo(false);
                break;
            case 'demo-one':
                setShowUsageOne(false);
                setShowDemoOne(!showDemoOne);
                setShowUsageTwo(false);
                setShowDemoTwo(false);
                break;
            case 'demo-two':
                setShowUsageOne(false);
                setShowDemoOne(false);
                setShowUsageTwo(false);
                setShowDemoTwo(!showDemoTwo);
                break;
        }
    }

    return (
        <Modal
            className='TemplateHelpModal'
            open={props.open}
            closeIcon
            onClose={props.onClose}
        >
            <Modal.Header>Template Guide</Modal.Header>
            <Modal.Content scrolling>
                <p>
                    Templates help users fill treatment forms more efficiently by allowing users to save frequently used phrases as template items.
                    There are predefined template items but you can also make changes as you like.
                </p>
                <Header>I want to make all template items appear with one click (One-level template)</Header>
                <p>
                    Simply add all template items you want into the default template category.
                </p>
                <Button name='usage-one' onClick={toggleHelp}>How it looks like?</Button>
                <Button name='demo-one' onClick={toggleHelp}>How to set up?</Button>
                {
                    showUsageOne ? <Image className='TemplateHelpImg' src={usageOne} size='large' /> : null
                }
                {
                    showDemoOne ?
                        <video controls width='100%'>
                            <source src={demoOne} type='video/mp4' />
                        </video>
                        : null
                }

                <Header>I want to categorize template items (Two-level template)</Header>
                <p>
                    Create template categories first, and then add template items to the desired template category.
                </p>
                <Button name='usage-two' onClick={toggleHelp}>How it looks like?</Button>
                <Button name='demo-two' onClick={toggleHelp}>How to set up?</Button>
                {
                    showUsageTwo ? <Image className='TemplateHelpImg' src={usageTwo} size='large' /> : null
                }
                {
                    showDemoTwo ?
                        <video controls width='100%'>
                            <source src={demoTwo} type='video/mp4' />
                        </video>
                        : null
                }

            </Modal.Content>
        </Modal>
    )
});

export default TemplateHelp;