export const ORGANISATION = {
    NZRA: 1,
    NZASA: 2
}

export const TITLE_OPTIONS = [
    { text: 'Mr.', value: 'Mr.' },
    { text: 'Mrs.', value: 'Mrs.' },
    { text: 'Miss.', value: 'Miss.' },
    { text: 'Ms.', value: 'Ms.' }
];

export const CLAIM_STATUS = {
    0: 'Not claimed',
    1: 'Claimed'
};

export const TREATMENT_DURATION = [
    { text: '60', value: 60 },
    { text: '55', value: 55 },
    { text: '50', value: 50 },
    { text: '45', value: 45 },
    { text: '40', value: 40 },
    { text: '35', value: 35 },
    { text: '30', value: 30 },
    { text: '25', value: 25 },
    { text: '20', value: 20 },
    { text: '15', value: 15 },
    { text: '10', value: 10 },
    { text: '5', value: 5 },
];